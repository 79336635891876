import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useCreateCheckoutSessionMutation, useHandlePaymentCompleteMutation } from '../slices/stripeApiSlice';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Loader from './ToucanLoader';
import Message from './Message';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearCartItems } from '../slices/cartSlice';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Separate component for the checkout form to avoid re-rendering issues
const CheckoutForm = ({ clientSecret, orderId, orderType }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [handlePaymentComplete] = useHandlePaymentCompleteMutation();
  
  // Get user info to check if this is a guest checkout
  const { userInfo } = useSelector((state) => state.auth);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      if (error) {
        setMessage(error.message);
        toast.error(error.message);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        // Handle successful payment through our backend
        const result = await handlePaymentComplete({
          orderId,
          orderType,
          paymentIntentId: paymentIntent.id,
          id: paymentIntent.id,
          status: paymentIntent.status,
          update_time: new Date().toISOString(),
          email_address: paymentIntent.receipt_email,
          payment_intent: paymentIntent.id,
          payer: {
            email_address: paymentIntent.receipt_email
          }
        });

        if (result.data?.success) {
          // Clear the cart
          dispatch(clearCartItems());
          toast.success('Payment successful! An email confirmation will be sent shortly. Please check spam if you do not see an email in your inbox!');
          
          // For guest users, redirect to the shop screen instead of order summary
          if (!userInfo) {
            navigate('/shop');
          } else {
            // For logged-in users, redirect to the appropriate order summary page
            const summaryPath = orderType === 'commercial' 
              ? `/commercialOrder/${orderId}`
              : `/order/${orderId}`;
            navigate(summaryPath);
          }
        }
      }
    } catch (err) {
      setMessage(err?.message || 'An unexpected error occurred');
      toast.error(err?.message || 'An unexpected error occurred');
    }

    setIsProcessing(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  };

  return (
    <form onSubmit={handleSubmit} className="w-full space-y-6">
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      
      <button
        type="submit"
        disabled={isProcessing || !stripe || !elements}
        className="w-full py-3 px-4 bg-amber-600 text-white rounded-md hover:bg-amber-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
      >
        <span className="flex items-center justify-center">
          {isProcessing ? (
            <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
          ) : (
            'Pay now'
          )}
        </span>
      </button>

      {message && (
        <div className="mt-4 text-sm text-red-600 bg-red-50 p-3 rounded-md">
          {message}
        </div>
      )}
    </form>
  );
};

const StripeCheckoutForm = ({ orderId, orderType }) => {
  const [createCheckoutSession, { isLoading, error }] = useCreateCheckoutSessionMutation();
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const initializeCheckout = async () => {
      try {
        const { data } = await createCheckoutSession({ orderId, orderType });
        if (data?.clientSecret) {
          setClientSecret(data.clientSecret);
        } else {
          toast.error('Could not initialize checkout');
        }
      } catch (err) {
        toast.error('Error initializing checkout');
      }
    };

    if (orderId) {
      initializeCheckout();
    }
  }, [orderId, orderType, createCheckoutSession]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Message variant='danger'>{error?.data?.message || 'Something went wrong'}</Message>;
  }

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#d97706', // amber-600
        colorBackground: '#ffffff',
        colorText: '#1f2937',
      },
    },
  };

  return (
    <div className="w-full max-w-md mx-auto">
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm 
            clientSecret={clientSecret}
            orderId={orderId}
            orderType={orderType}
          />
        </Elements>
      )}
    </div>
  );
};

export default StripeCheckoutForm; 