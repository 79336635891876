import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useResetPasswordMutation } from '../../slices/usersApiSlice'
import { toast } from 'react-toastify'
import Loader from '../../components/ToucanLoader'

const ResetPassword = () => {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [resetPassword, { isLoading }] = useResetPasswordMutation()
  const { token } = useParams()
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Frontend validation
    if (!password) {
      toast.error('Password is required')
      return
    }

    if (password.length < 8) {
      toast.error('Password must be at least 8 characters long')
      return
    }

    if (password !== passwordConfirm) {
      toast.error('Passwords do not match')
      return
    }

    try {
      await resetPassword({ 
        token,
        data: { password, passwordConfirm }
      }).unwrap()
      toast.success('Password reset successful!')
      navigate('/login')
    } catch (err) {
      toast.error('Unable to reset password. Please make sure your reset link hasn\'t expired and try again.')
    }
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-cover bg-center"
      style={{ backgroundImage: 'url("/images/beans.png")' }}>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-40 w-auto"
          src="/images/terrys-full-logo.png"
          alt="Terry's Coffee"
        />
        <h2 className="mt-6 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
          Reset Password
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Please enter your new password.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white/90 backdrop-blur-sm px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                New Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fe940c] sm:text-sm/6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="passwordConfirm" className="block text-sm/6 font-medium text-gray-900">
                Confirm New Password
              </label>
              <div className="mt-2">
                <input
                  id="passwordConfirm"
                  name="passwordConfirm"
                  type="password"
                  required
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fe940c] sm:text-sm/6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="flex w-full justify-center rounded-md bg-[#fe940c] px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-[#e07d00] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#fe940c]"
              >
                Reset Password
              </button>
              {isLoading && <Loader />}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword