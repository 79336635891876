import { apiSlice } from './apiSlice';

export const commercialPricingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPricingRules: builder.query({
      query: ({pageNumber, keyword} = {}) => ({
        url: '/api/commercial-pricing',
        params: { pageNumber, keyword },
        method: 'GET',
      }),
      providesTags: ['CommercialPricing'],
      keepUnusedDataFor: 5,
    }),
    getPricingRulesByCustomer: builder.query({
      query: (customerId) => ({
        url: `/api/commercial-pricing/customer/${customerId}`,
      }),
      providesTags: ['CommercialPricing'],
    }),
    createPricingRule: builder.mutation({
      query: (data) => ({
        url: '/api/commercial-pricing',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['CommercialPricing'],
    }),
    updatePricingRule: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/api/commercial-pricing/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['CommercialPricing'],
    }),
    deletePricingRule: builder.mutation({
      query: (id) => ({
        url: `/api/commercial-pricing/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CommercialPricing'],
    }),
  }),
});

export const {
  useGetPricingRulesQuery,
  useGetPricingRulesByCustomerQuery,
  useCreatePricingRuleMutation,
  useUpdatePricingRuleMutation,
  useDeletePricingRuleMutation,
} = commercialPricingApiSlice; 