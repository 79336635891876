import React, { useState } from 'react'
import { useGetProductsQuery } from '../../slices/productsApiSlice'
import ToucanLoader from '../../components/ToucanLoader'
import Message from '../../components/Message'
import Product from '../../components/Product'
import { useParams, useSearchParams } from 'react-router-dom'
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import SearchBox from '../../components/SearchBox'

const ShopScreen = () => {
  const { pageNumber } = useParams()
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword') || '';
  const [selectedTag, setSelectedTag] = useState('')

  const { data: { data: { products } = {}, pages } = {}, isLoading, error } = useGetProductsQuery({ 
    pageNumber: pageNumber || 1,
    keyword,
    tag: selectedTag
  })

  const tags = [
    'Featured',
    'Coffee',
    'Equipment',
    'Chai-Latte',
    'Chocolate',
    'Merch',
    'Other'
  ]

  const handleTagSelect = (tag) => {
    setSelectedTag(tag === selectedTag ? '' : tag)
  }

  return (
    <>
      <div className="fixed right-0 lg:left-72 left-0 z-40 flex flex-col h-auto border-b border-gray-200 bg-white shadow-sm ring-1 ring-gray-300 lg:top-0 top-[3.5rem]">
        <div className="flex flex-1">
          <SearchBox />
        </div>

        {/* Tag Filter Section */}
        <div className="px-4 py-3 overflow-x-auto">
          <div className="flex gap-2 flex-nowrap">
            {tags.map((tag) => (
              <button
                key={tag}
                onClick={() => handleTagSelect(tag)}
                className={`px-3 py-1 text-sm rounded-full whitespace-nowrap ${
                  selectedTag === tag
                    ? 'bg-amber-500 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {tag.replace(/-/g, ' ')}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="pt-[9.5rem] lg:pt-28">
        {isLoading ? (
          <ToucanLoader />
        ) : error ? (
          <Message variant='danger'>
            Unable to load products. Please try again later.
          </Message>
        ) : (
          <div className="bg-gray-100">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              <div className="grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                {products && products.map((product) => (
                  <Product key={product._id} product={product} />
                ))}
              </div>

              {/* Pagination Controls */}
              {pages > 1 && (
                <nav className="flex items-center justify-between px-4 sm:px-0 mt-12 mb-12">
                  <div className="flex w-0 flex-1">
                    <Link
                      to={`/shop/page/${Number(pageNumber) > 1 ? Number(pageNumber) - 1 : 1}${keyword ? `?keyword=${keyword}` : ''}`}
                      className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-amber-300 hover:text-amber-700"
                    >
                      <ArrowLongLeftIcon className="mr-3 size-5 text-gray-400" aria-hidden="true" />
                      Previous
                    </Link>
                  </div>
                  
                  <div className="hidden md:flex">
                    {[...Array(pages).keys()].map((x) => (
                      <Link
                        key={x + 1}
                        to={`/shop/page/${x + 1}${keyword ? `?keyword=${keyword}` : ''}`}
                        aria-current={Number(pageNumber) === x + 1 ? 'page' : undefined}
                        className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                          Number(pageNumber) === x + 1
                            ? 'border-amber-500 text-amber-600'
                            : 'border-transparent text-gray-500 hover:border-amber-300 hover:text-amber-700'
                        }`}
                      >
                        {x + 1}
                      </Link>
                    ))}
                  </div>
                  
                  <div className="flex w-0 flex-1 justify-end">
                    <Link
                      to={`/shop/page/${Number(pageNumber) < pages ? Number(pageNumber) + 1 : pages}${keyword ? `?keyword=${keyword}` : ''}`}
                      className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-amber-300 hover:text-amber-700"
                    >
                      Next
                      <ArrowLongRightIcon className="ml-3 size-5 text-gray-400" aria-hidden="true" />
                    </Link>
                  </div>
                </nav>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ShopScreen