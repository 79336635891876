// Use environment variables based on NODE_ENV
export const BASE_URL = '';  // Empty string for all environments since uploads are served directly

export const PRODUCTS_URL = '/api/products';
export const USERS_URL = '/api/users';
export const ORDERS_URL = '/api/orders';
export const UPLOAD_URL = '/api/upload';
export const COMMERCIAL_UPLOAD_URL = '/api/upload/commercial';
export const AUTH_URL = '/api/auth';
export const COMMERCIAL_PRODUCTS_URL = '/api/commercialProducts';
export const COMMERCIAL_ORDERS_URL = '/api/commercialOrders';
export const COMMERCIAL_ADDRESSES_URL = '/api/commercial-addresses';
export const COMMERCIAL_REQUESTS_URL = '/api/commercial-requests';
export const COMMERCIAL_PRICING_URL = '/api/commercial-pricing';
export const STRIPE_URL = '/api/stripe';
export const QUICKBOOKS_URL = '/api/quickbooks';
export const CONTACT_URL = '/api/contact';
export const LOCATIONS_URL = '/api/locations';
