import React, { useEffect, useState } from 'react'
import { logout } from '../../slices/authSlice'
import { useUpdateMeMutation, useDeleteMeMutation } from '../../slices/usersApiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { setCredentials } from '../../slices/authSlice'
import { useUpdateMyPasswordMutation } from '../../slices/usersApiSlice'
import { useGetMyOrdersQuery, useHideMyOrderMutation } from '../../slices/ordersApiSlice'
import { Link, useNavigate } from 'react-router-dom'
import ToucanLoader from '../../components/ToucanLoader'
import { TrashIcon } from '@heroicons/react/24/solid'
import { useUploadUserImageMutation, useGetMeQuery, useRemoveUserPhotoMutation } from '../../slices/usersApiSlice'
import { useGetMyCommercialOrdersQuery, useHideMyCommercialOrderMutation } from '../../slices/commercialOrdersApiSlice'
import { BASE_URL } from '../../constants'
import ConfirmationModal from '../../components/ConfirmationModal'

const ProfileScreen = () => {
  const [name, setName] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [email, setEmail] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [passwordCurrent, setPasswordCurrent] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [isChangingPassword, setIsChangingPassword] = useState(false)
  const [showDeactivateConfirm, setShowDeactivateConfirm] = useState(false)
  const [showOrderDeleteModal, setShowOrderDeleteModal] = useState(false);
  const [showCommercialOrderDeleteModal, setShowCommercialOrderDeleteModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userInfo } = useSelector((state) => state.auth)
  const { refetch: refetchUser } = useGetMeQuery()

  const [updateMyPassword, { isLoading: isUpdatingPassword }] = useUpdateMyPasswordMutation()
  const [uploadUserImage, { isLoading: isUploading }] = useUploadUserImageMutation()
  const [updateMe, { isLoading: isUpdating }] = useUpdateMeMutation()
  const [deleteMe, { isLoading: isDeleting }] = useDeleteMeMutation()
  const { data: orders, isLoading: loadingOrders, error: errorOrders, refetch } = useGetMyOrdersQuery()
  const [hideMyOrder] = useHideMyOrderMutation()
  const { data: commercialOrders, isLoading: loadingCommercialOrders, error: errorCommercialOrders, refetch: refetchCommercial } = useGetMyCommercialOrdersQuery()
  const [hideMyCommercialOrder] = useHideMyCommercialOrderMutation()
  const [removeUserPhoto, { isLoading: isRemoving }] = useRemoveUserPhotoMutation()

  
  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name || '')
      setBusinessName(userInfo.businessName || '')
      setEmail(userInfo.email || '')
    }
  }, [userInfo])



  const submitHandler = async (e) => {
    e.preventDefault()
    if (name !== userInfo.name || businessName !== userInfo.businessName || email !== userInfo.email) {
      try {
        const res = await updateMe({
          name,
          businessName,
          email,
        }).unwrap()
        dispatch(setCredentials(res.data.user))
        toast.success('Profile updated successfully')
        setIsEditing(false)
      } catch (err) {
        toast.error('Unable to update profile. Please check your information and try again.')
      }
    } else {
      setIsEditing(false)
    }
  }

  const submitPasswordHandler = async (e) => {
    e.preventDefault()
    
    // Frontend validation
    if (!passwordCurrent) {
      toast.error('Current password is required')
      return
    }

    if (!password) {
      toast.error('New password is required')
      return
    }

    if (password.length < 8) {
      toast.error('New password must be at least 8 characters long')
      return
    }

    if (password !== passwordConfirm) {
      toast.error('New passwords do not match')
      return
    }

    try {
      await updateMyPassword({
        passwordCurrent,
        password,
        passwordConfirm,
      }).unwrap()

      toast.success('Password updated successfully')
      setIsChangingPassword(false)
      setPasswordCurrent('')
      setPassword('')
      setPasswordConfirm('')
    } catch (err) {
      toast.error('Current password is incorrect. Please try again.')
    }
  }

  const handleHideOrderClick = (orderId) => {
    setSelectedOrderId(orderId);
    setShowOrderDeleteModal(true);
  };

  const handleHideCommercialOrderClick = (orderId) => {
    setSelectedOrderId(orderId);
    setShowCommercialOrderDeleteModal(true);
  };

  const handleHideOrder = async () => { 
    try {
      await hideMyOrder(selectedOrderId).unwrap();
      refetch();
      setShowOrderDeleteModal(false);
      toast.success('Order removed from history');
    } catch (err) {
      toast.error('Unable to remove order from history. Please try again.');
    }
  };

  const handleHideCommercialOrder = async () => { 
    try {
      await hideMyCommercialOrder(selectedOrderId).unwrap();
      refetchCommercial();
      toast.success('Commercial order removed from history');
      setShowCommercialOrderDeleteModal(false);
    } catch (err) {
      toast.error('Unable to remove commercial order from history. Please try again.');
    }
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData()
    formData.append('image', e.target.files[0])
    
    try {
      const res = await uploadUserImage(formData).unwrap()
      
      // Update the user info with new image
      const updatedUser = { 
        ...userInfo, 
        photo: res.photo 
      }
      
      // Update Redux store
      dispatch(setCredentials(updatedUser))
      
      // Force a refetch of user data
      await refetchUser()
      
      toast.success('Profile picture updated successfully')
    } catch (err) {
      toast.error('Unable to update profile picture. Please try a smaller image or a different file format.');
    }
  }

  const handleDeactivateAccount = async () => {
    try {
      await deleteMe().unwrap()
      dispatch(logout())
      toast.success('Account deactivated successfully')
      navigate('/')
    } catch (err) {
      toast.error('Unable to deactivate account. Please contact support if this issue persists.')
    }
  }

  const handleRemovePhoto = async () => {
    try {
      await removeUserPhoto().unwrap()
      
      // Update the user info with removed image
      const updatedUser = { 
        ...userInfo, 
        photo: '' 
      }
      
      // Update Redux store
      dispatch(setCredentials(updatedUser))
      
      // Force a refetch of user data
      await refetchUser()
      
      toast.success('Profile picture removed successfully')
    } catch (err) {
      toast.error('Unable to remove profile picture. Please try again later.')
    }
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6">Profile</h1>
      <div className="mb-8">
        <div className="flex gap-x-3">
          <div className="h-20 w-20 rounded-full bg-amber-600 flex items-center justify-center overflow-hidden">
            {userInfo?.photo ? (
              <img 
                src={`${BASE_URL}${userInfo.photo}`}
                alt={userInfo.name} 
                className="h-full w-full object-cover"
              />
            ) : (
              <span className="text-3xl font-bold text-white">
                {name ? name.charAt(0).toUpperCase() : '?'}
              </span>
            )}
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-xl font-medium text-gray-900 truncate">
              {name || 'Your Name'}
            </p>
            <p className="text-sm text-gray-500 truncate mb-4">
              {email || 'your.email@example.com'}
            </p>
            <div className="flex gap-3 mt-2">
              <label 
                htmlFor="profile-image" 
                className="text-sm text-amber-600 cursor-pointer hover:text-amber-700 hover:underline"
              >
                Change Photo
              </label>
              {userInfo?.photo && (
                <button
                  type="button"
                  onClick={handleRemovePhoto}
                  className="text-sm text-red-600 hover:text-red-700 hover:underline"
                  disabled={isRemoving}
                >
                  Remove Photo
                </button>
              )}
            </div>
            <input
              type="file"
              id="profile-image"
              onChange={uploadFileHandler}
              className="hidden"
              accept="image/*"
            />
            {(isUploading || isRemoving) && (
              <div className="mt-2">
                <ToucanLoader small />
              </div>
            )}
          </div>
        </div>
      </div>

      <form id="profile-form" onSubmit={submitHandler} className="max-w-md space-y-6">
        <div className="relative">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={!isEditing}
            className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-[#fe940c] focus:ring-[#fe940c] sm:text-sm pr-10 
              ${!isEditing ? 'bg-gray-100' : ''}`}
          />
        </div>
        <div className="relative">
          <label htmlFor="businessName" className="block text-sm font-medium text-gray-700">
            Business Name
          </label>
          <input
            type="text"
            id="businessName"
            value={businessName || ''}
            onChange={(e) => setBusinessName(e.target.value)}
            disabled={!isEditing}
            className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-[#fe940c] focus:ring-[#fe940c] sm:text-sm pr-10 
              ${!isEditing ? 'bg-gray-100' : ''}`}
          />
        </div>
        <div className="relative">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email || ''}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!isEditing}
            className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-[#fe940c] focus:ring-[#fe940c] sm:text-sm pr-10 
              ${!isEditing ? 'bg-gray-100' : ''}`}
          />
        </div>

        <div className="flex space-x-4 mt-4 w-full">
          {isEditing ? (
            <div className="flex space-x-4 w-full">
              <button
                type="submit"
                disabled={isUpdating}
                className="w-1/2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#fe940c] hover:bg-[#e07d00] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fe940c]"
              >
                {isUpdating ? <ToucanLoader small /> : 'Save Changes'}
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsEditing(false);
                  setName(userInfo.name || '');
                  setBusinessName(userInfo.businessName || '');
                  setEmail(userInfo.email || '');
                }}
                className="w-1/2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
              >
                Cancel
              </button>
            </div>
          ) : (
            <div className="flex space-x-4 w-full">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsEditing(true);
                }}
                className="w-1/3 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
              >
                Edit Profile
              </button>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsChangingPassword(true);
                }}
                className="w-1/3 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
              >
                Change Password
              </button>
              <button
                onClick={() => setShowDeactivateConfirm(true)}
                className="w-1/3 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Deactivate
              </button>
            </div>
          )}
        </div>
      </form>

      {isChangingPassword && (
        <form onSubmit={submitPasswordHandler} className="max-w-md space-y-6 mt-8">
          <h2 className="text-xl font-bold">Change Password</h2>
          <div className="relative">
            <label htmlFor="passwordCurrent" className="block text-sm font-medium text-gray-700">
              Current Password
            </label>
            <input
              type="password"
              id="passwordCurrent"
              value={passwordCurrent}
              onChange={(e) => setPasswordCurrent(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            />
          </div>
          <div className="relative">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            />
          </div>
          <div className="relative">
            <label htmlFor="passwordConfirm" className="block text-sm font-medium text-gray-700">
              Confirm New Password
            </label>
            <input
              type="password"
              id="passwordConfirm"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            />
          </div>
          <div className="flex space-x-4">
            <button
              type="submit"
              disabled={isUpdatingPassword}
              className="flex-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              {isUpdatingPassword ? <ToucanLoader small /> : 'Update Password'}
            </button>
            <button
              type="button"
              onClick={() => setIsChangingPassword(false)}
              className="flex-1 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      {showDeactivateConfirm && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <div className="relative bg-white rounded-lg p-8 max-w-md mx-auto">
            <h3 className="text-xl font-bold mb-4">Deactivate Account</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to deactivate your account? This action cannot be undone.
            </p>
            <div className="flex space-x-4">
              <button
                onClick={handleDeactivateAccount}
                className="flex-1 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                {isDeleting ? <ToucanLoader small /> : 'Yes, Deactivate'}
              </button>
              <button
                onClick={() => setShowDeactivateConfirm(false)}
                className="flex-1 px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mt-8">
        <h2 className="text-xl font-bold mb-4">My Orders</h2>
        {loadingOrders ? (
          <div className="flex justify-center">
            <ToucanLoader />
          </div>
        ) : errorOrders ? (
          <p className="text-red-500">{'Error loading orders'}</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">ID</th>
                  <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">Date</th>
                  <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">Total</th>
                  <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">Paid</th>
                  <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">Details</th>
                  <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {orders
                  .filter(order => !order.hidden)
                  .map((order) => (
                    <tr key={order._id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {order._id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {order.createdAt.substring(0, 10)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        ${order.totalPrice}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.isPaid ? (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {order.paidAt.substring(0, 10)}
                          </span>
                        ) : (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            Not Paid
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <Link
                          to={`/order/${order._id}`}
                          className="text-amber-600 hover:text-amber-900"
                        >
                          View Details
                        </Link>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <button
                          onClick={() => handleHideOrderClick(order._id)}
                          className="text-gray-500 hover:text-red-600 transition-colors"
                        >
                          <TrashIcon className="w-6 h-6" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="mt-8">
        <h2 className="text-xl font-bold mb-4">My Commercial Orders</h2>
        {loadingCommercialOrders ? (
          <div className="flex justify-center">
            <ToucanLoader />
          </div>
        ) : errorCommercialOrders ? (
          <p className="text-red-500">{'Error loading commercial orders'}</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">ID</th>
                  <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">Date</th>
                  <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">Total</th>
                  <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">Paid</th>
                  <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">Details</th>
                  <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {commercialOrders && commercialOrders
                  .filter(order => !order.hidden)
                  .map((order) => (
                    <tr key={order._id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {order._id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {order.createdAt.substring(0, 10)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        ${order.totalPrice}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.isPaid ? (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {order.paidAt.substring(0, 10)}
                          </span>
                        ) : (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            Not Paid
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <Link
                          to={`/commercialOrder/${order._id}`}
                          className="text-amber-600 hover:text-amber-900"
                        >
                          View Details
                        </Link>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <button
                          onClick={() => handleHideCommercialOrderClick(order._id)}
                          className="text-gray-500 hover:text-red-600 transition-colors"
                        >
                          <TrashIcon className="w-6 h-6" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <ConfirmationModal
        isOpen={showOrderDeleteModal}
        onClose={() => setShowOrderDeleteModal(false)}
        onConfirm={handleHideOrder}
        title="Remove Order"
        message="Are you sure you want to remove this order from your history? This action cannot be undone."
        confirmText="Remove"
        confirmButtonClass="bg-red-600 hover:bg-red-700"
      />

      <ConfirmationModal
        isOpen={showCommercialOrderDeleteModal}
        onClose={() => setShowCommercialOrderDeleteModal(false)}
        onConfirm={handleHideCommercialOrder}
        title="Remove Commercial Order"
        message="Are you sure you want to remove this commercial order from your history? This action cannot be undone."
        confirmText="Remove"
        confirmButtonClass="bg-red-600 hover:bg-red-700"
      />
    </div>
  )
}

export default ProfileScreen