import { apiSlice } from './apiSlice'
import { STRIPE_URL } from '../constants'

export const stripeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCheckoutSession: builder.mutation({
      query: (data) => ({
        url: `${STRIPE_URL}/create-checkout-session`,
        method: 'POST',
        body: data,
      }),
    }),
    handlePaymentComplete: builder.mutation({
      query: (data) => ({
        url: `${STRIPE_URL}/payment-complete`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { orderId, orderType }) => [
        { type: orderType === 'commercial' ? 'CommercialOrder' : 'Order', id: orderId }
      ],
    }),
  }),
})

export const {
  useCreateCheckoutSessionMutation,
  useHandlePaymentCompleteMutation,
} = stripeApiSlice 