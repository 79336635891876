import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../slices/authSlice';
import { useVerifyGoogleAuthQuery } from '../slices/usersApiSlice';
import { toast } from 'react-toastify';

const AuthSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const urlError = params.get('error');

  const { data, error, isLoading } = useVerifyGoogleAuthQuery(token, {
    skip: !token || !!urlError
  });

  useEffect(() => {
    if (urlError) {
      navigate('/login');
      return;
    }

    if (!token) {
      navigate('/login');
      return;
    }

    if (error) {
      toast.error('Authentication failed');
      navigate('/login');
      return;
    }

    if (data?.data?.user) {
      dispatch(setCredentials({ ...data.data.user, token }));
      toast.success('Successfully logged in with Google!');
      navigate('/');
    }
  }, [token, urlError, data, error, dispatch, navigate]);

  if (isLoading) return null;
  return null;
};

export default AuthSuccess; 