import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useGetFeaturedProductsQuery } from '../../slices/productsApiSlice';
import FAQSection from '../../components/FAQSection';
import ToucanLoader from '../../components/ToucanLoader';
import Message from '../../components/Message';
import FormattedPrice from '../../components/FormattedPrice';

const HomeScreen = () => {
  const faqRef = useRef(null);
  const { data: featuredProducts, isLoading, error } = useGetFeaturedProductsQuery();

  const retailFeatures = [
    'Premium Coffee Beans, Chocolate and Tea',
    'Home Brewing Equipment',
    'Coffee Accessories',
    'Free Shipping'
  ];

  const commercialFeatures = [
    'Wholesale Coffee, Chocolate and Tea Supply',
    'Café Equipment Solutions including Coffee Machine and Grinder Installation and Maintenance',
    'Barista Training & Support',
    'Commercial Grade Products',
  ];

  const contactFeatures = [
    'Expert Coffee Consultation',
    'Terry\'s for your business',
    'Cafe equipment solutions',
    'Barista Training & Support',
  ];

  const scrollToFAQs = (e) => {
    e.preventDefault();
    faqRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center -mt-8">
          <img 
            src="/images/terrys-logo.png" 
            alt="Terry's Coffee" 
            className="mx-auto h-56 w-auto"
          />
        </div>

        {/* Three Cards Section */}
        <div className="mx-auto mt-8 grid max-w-6xl grid-cols-1 gap-8 lg:grid-cols-3">
          {/* Commercial Card - order-1 on mobile, default order on desktop */}
          <div className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-200 xl:p-10 lg:col-span-1 order-1 lg:order-3">
            <div>
              <div className="flex items-center justify-between gap-x-4">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">Commercial Solutions</h2>
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">
                Tailored for cafes and business owners
              </p>
              <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                {commercialFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-amber-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <Link
              to="/commercial/quote-request"
              className="mt-8 block rounded-md bg-amber-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
            >
              Request a Quote
            </Link>
          </div>

          {/* Retail Card - order-2 on mobile, default order on desktop */}
          <div className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-200 xl:p-10 lg:col-span-1 order-2 lg:order-1">
            <div>
              <div className="flex items-center justify-between gap-x-4">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">Retail Coffee</h2>
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">
                Perfect for coffee enthusiasts and home brewers
              </p>
              <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                {retailFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-amber-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <Link
              to="/shop"
              className="mt-8 block rounded-md bg-amber-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
            >
              Visit Retail Shop
            </Link>
          </div>

          {/* Contact Card - order-3 on mobile, default order on desktop */}
          <div className="flex flex-col justify-between rounded-3xl bg-amber-50 p-8 shadow-xl ring-1 ring-amber-200 xl:p-10 transform hover:scale-105 transition-transform duration-300 lg:col-span-1 order-3 lg:order-2">
            <div>
              <div className="flex items-center justify-between gap-x-4">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">Get in Touch</h2>
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">
                We're here to help with all your coffee needs
              </p>
              <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                {contactFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-amber-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
              <div className="mt-6 space-y-3">
                <div className="flex items-center gap-x-3">
                  <EnvelopeIcon className="h-5 w-5 flex-shrink-0 text-amber-600" />
                  <a 
                    href="mailto:terryscoffeeroasting@gmail.com"
                    className="text-sm text-gray-600 hover:text-amber-600 hover:underline truncate"
                  >
                    <span className="text-sm text-gray-600 break-all">terryscoffeeroasting@gmail.com</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-8 space-y-4">
              <Link
                to="/commercial/quote-request"
                className="block rounded-md bg-amber-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
              >
                Request a Quote
              </Link>
              <Link
                to="#faq-section"
                onClick={scrollToFAQs}
                className="flex items-center justify-center gap-x-2 rounded-md bg-white px-3.5 py-2 text-center text-sm font-semibold leading-6 text-amber-600 shadow-sm ring-1 ring-inset ring-amber-200 hover:bg-amber-50"
              >
                <QuestionMarkCircleIcon className="h-5 w-5" />
                View FAQs
              </Link>
            </div>
          </div>
        </div>

        {/* Featured Products Section */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-center mb-12">Featured Products</h2>
          {isLoading ? (
            <ToucanLoader />
          ) : error ? (
            <Message variant="danger">
              {'Error loading featured products'}
            </Message>
          ) : featuredProducts?.length > 0 ? (
            <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {featuredProducts.map((product) => (
                <Link 
                  key={product._id} 
                  to={`/product/${product._id}`}
                  className="group"
                >
                  <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="h-full w-full object-cover object-center group-hover:opacity-75"
                    />
                  </div>
                  <h3 className="mt-4 text-lg font-medium text-gray-900">{product.name}</h3>
                  <p className="mt-1 text-lg font-medium text-amber-600">
                    <FormattedPrice price={product.price} />
                  </p>
                </Link>
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-500">No featured products available</p>
          )}
        </div>

        {/* FAQ Section */}
        <FAQSection ref={faqRef} />
      </div>
    </div>
  );
};

export default HomeScreen;
