import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useGetCommercialOrderByIdForAdminQuery, useUpdateCommercialOrderToCompletedMutation, useUpdateCommercialOrderToManuallyPaidMutation } from '../../slices/commercialOrdersApiSlice';
import { useValidateTokenQuery } from '../../slices/quickBooksApiSlice';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import Loader from '../../components/ToucanLoader';
import Message from '../../components/Message';
import { addDecimals } from '../../utils/cartUtils';
import { toast } from 'react-toastify';
import CreateInvoiceModal from '../../components/CreateInvoiceModal';

const CommercialOrderAdminDetailsScreen = () => {
  const { id: orderId } = useParams();
  const navigate = useNavigate();
  const [localOrder, setLocalOrder] = useState(null);

  const { 
    data: order, 
    isLoading, 
    error,
    refetch 
  } = useGetCommercialOrderByIdForAdminQuery(orderId);

  // Remove pricing rules query since we don't need it anymore
  const { data: tokenStatus } = useValidateTokenQuery();
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);

  const [updateCommercialOrderToCompleted] = useUpdateCommercialOrderToCompletedMutation();
  const [updateCommercialOrderToManuallyPaid] = useUpdateCommercialOrderToManuallyPaidMutation();

  // Update local order state when server data changes
  useEffect(() => {
    if (order) {
      setLocalOrder(order);
    }
  }, [order]);



  const handleComplete = async () => {
    try {
      await updateCommercialOrderToCompleted(orderId).unwrap();
      refetch();
      toast.success('Order marked as completed');
    } catch (err) {
      toast.error('Unable to mark commercial order as completed. Please try again.');
    }
  };

  const handlePaid = async () => {
    try {
      await updateCommercialOrderToManuallyPaid(orderId).unwrap();
      refetch();
      toast.success('Order marked as paid');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleCreateInvoice = () => {
    setShowInvoiceModal(true);
  };

  const handleDownloadInvoice = async () => {
    if (!order.quickBooksInvoice?.quickBooksId) {
      toast.error('No invoice found for this order');
      return;
    }

    try {
      const response = await fetch(`/api/quickbooks/invoices/${order.quickBooksInvoice.quickBooksId}/pdf`, {
        headers: {
          'Accept': 'application/pdf'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to download invoice');
      }

      // Create blob from response
      const blob = await response.blob();
      
      // Create temporary URL for the blob
      const url = window.URL.createObjectURL(blob);
      
      // Create temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.download = `invoice-${order.quickBooksInvoice.quickBooksId}.pdf`;
      
      // Append to document, click, and cleanup
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      toast.error('Failed to download invoice. Please try again.');
    }
  };

  if (isLoading) return <Loader />;
  if (error) return <Message variant='danger'>Unable to load commercial order details. Please refresh the page or try again later.</Message>;
  if (!localOrder) return <Message variant='danger'>Order not found</Message>;

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-8">
          {/* Header */}
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-0">
            <div>
              <h1 className="text-2xl font-bold">Commercial Order Details</h1>
              <p className="text-gray-500">Order #{localOrder._id}</p>
            </div>
            <div className="flex flex-col sm:flex-row gap-2 sm:space-x-4 w-full sm:w-auto">
              <button
                onClick={() => navigate(-1)}
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 w-full sm:w-auto"
              >
                Back to Orders
              </button>
              {!localOrder.isPaid && (
                <button
                  onClick={handlePaid}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 w-full sm:w-auto"
                >
                  Mark as Paid
                </button>
              )}
              {!localOrder.isCompleted && (
                <button
                  onClick={handleComplete}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 w-full sm:w-auto"
                >
                  Mark as Completed
                </button>
              )}
              {!localOrder.quickBooksInvoice ? (
                <button
                  onClick={handleCreateInvoice}
                  disabled={!tokenStatus?.valid}
                  className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 disabled:bg-blue-300"
                >
                  Create Invoice
                </button>
              ) : (
                <button
                  onClick={handleDownloadInvoice}
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 inline-flex items-center gap-2"
                >
                  <ArrowDownTrayIcon className="h-5 w-5" />
                  Download Invoice
                </button>
              )}
              {localOrder.isPaid && !tokenStatus?.valid && (
                <Link
                  to="/admin/quickbooks"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 w-full sm:w-auto"
                >
                  Connect QuickBooks
                </Link>
              )}
            </div>
          </div>

          {/* Order Status */}
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-lg font-medium mb-4">Order Status</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <p className="text-sm text-gray-500">Order Date</p>
                <p className="font-medium">{new Date(localOrder.createdAt).toLocaleDateString()}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Payment Status</p>
                <p className={`font-medium ${localOrder.isPaid ? 'text-green-600' : 'text-red-600'}`}>
                  {localOrder.isPaid ? `Paid on ${new Date(localOrder.paidAt).toLocaleDateString()}` : 'Not Paid'}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Completion Status</p>
                  <p className={`font-medium ${localOrder.isCompleted ? 'text-green-600' : 'text-yellow-600'}`}>
                  {localOrder.isCompleted ? `Completed on ${new Date(localOrder.completedAt).toLocaleDateString()}` : 'Not Completed'}
                </p>
              </div>
            </div>
          </div>

          {/* Business Information */}
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-lg font-medium mb-4">Business Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h3 className="font-medium text-gray-900">Business Details</h3>
                <div className="mt-2">
                  <p className="font-medium">{localOrder.user?.businessName || 'N/A'}</p>
                  <p className="text-gray-600">{localOrder.shippingAddress?.company || 'N/A'}</p>
                  <div className="mt-4">
                    <p className="font-medium">Contact Person</p>
                    <p>{localOrder.user?.name || 'N/A'}</p>
                    <p className="text-gray-500">{localOrder.user?.email || 'N/A'}</p>
                    <p className="text-gray-500">{localOrder.shippingAddress?.phone || 'N/A'}</p>
                  </div>
                </div>
              </div>
              <div>
                <h3 className="font-medium text-gray-900">Shipping Address</h3>
                <address className="mt-2 not-italic">
                  <p>{localOrder.shippingAddress?.address || 'N/A'}</p>
                  {localOrder.shippingAddress?.apartment && (
                    <p>{localOrder.shippingAddress.apartment}</p>
                  )}
                  <p>
                    {localOrder.shippingAddress?.city || 'N/A'}, {localOrder.shippingAddress?.state || 'N/A'} {localOrder.shippingAddress?.postalCode || 'N/A'}
                  </p>
                  <p>{localOrder.shippingAddress?.country || 'N/A'}</p>
                </address>
              </div>
            </div>

            {/* Billing Address (if different from shipping) */}
            {localOrder.billingAddress && !localOrder.sameAsShipping && (
              <div className="mt-6">
                <h3 className="font-medium text-gray-900">Billing Address</h3>
                <address className="mt-2 not-italic">
                  <p>
                    {localOrder.billingAddress.firstName} {localOrder.billingAddress.lastName}
                  </p>
                  {localOrder.billingAddress.company && (
                    <p>{localOrder.billingAddress.company}</p>
                  )}
                  <p>{localOrder.billingAddress.address}</p>
                  {localOrder.billingAddress.apartment && (
                    <p>{localOrder.billingAddress.apartment}</p>
                  )}
                  <p>
                    {localOrder.billingAddress.city}, {localOrder.billingAddress.state} {localOrder.billingAddress.postalCode}
                  </p>
                  <p>{localOrder.billingAddress.country}</p>
                  <p>{localOrder.billingAddress.phone}</p>
                </address>
              </div>
            )}
          </div>

          {/* Order Items */}
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-lg font-medium mb-4">Order Items</h2>
            <ul className="divide-y divide-gray-200">
              {localOrder.orderItems?.map((item) => (
                <li key={item._id} className="py-4 flex">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="h-20 w-20 rounded-md object-cover"
                  />
                  <div className="ml-6 flex-1">
                    <div className="flex items-center justify-between">
                      <div>
                        <h4 className="font-medium">{item.name}</h4>
                        <p className="mt-1 text-sm text-gray-500">Quantity: {item.qty}</p>
                        <div className="mt-1 text-sm">
                          <p className="text-gray-500">Base Price: ${addDecimals(item.price)}</p>
                          {item.customPrice && (
                            <p className="text-amber-600">Custom Price: ${addDecimals(item.customPrice)}</p>
                          )}
                        </div>
                      </div>
                      <p className="font-medium">
                        ${addDecimals((item.customPrice || item.price) * item.qty)}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* Order Summary */}
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-lg font-medium mb-4">Order Summary</h2>
            <dl className="space-y-4">
              <div className="flex justify-between">
                <dt className="text-gray-600">Subtotal</dt>
                <dd className="font-medium">${addDecimals(localOrder.itemsPrice)}</dd>
              </div>
              <div className="flex justify-between">
                <dt className="text-gray-600">Shipping</dt>
                <dd className="font-medium">${addDecimals(localOrder.shippingPrice)}</dd>
              </div>
              <div className="flex justify-between">
                <dt className="text-gray-600">Tax</dt>
                <dd className="font-medium">${addDecimals(localOrder.taxPrice)}</dd>
              </div>
              <div className="flex justify-between border-t pt-4">
                <dt className="font-medium">Total</dt>
                <dd className="font-medium">${addDecimals(localOrder.totalPrice)}</dd>
              </div>
            </dl>
          </div>

          {/* Payment Information */}
          {localOrder.isPaid && localOrder.paymentResult && (
            <div className="bg-white shadow rounded-lg p-6">
              <h2 className="text-lg font-medium mb-4">Payment Information</h2>
              <dl className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <dt className="text-gray-600">Payment Method</dt>
                  <dd className="font-medium">{localOrder.paymentMethod}</dd>
                </div>
                <div>
                  <dt className="text-gray-600">Transaction ID</dt>
                  <dd className="font-medium">{localOrder.paymentResult.id}</dd>
                </div>
                <div>
                  <dt className="text-gray-600">Status</dt>
                  <dd className="font-medium">{localOrder.paymentResult.status}</dd>
                </div>
                {localOrder.paymentResult.payer?.email_address && (
                  <div>
                    <dt className="text-gray-600">Payer Email</dt>
                    <dd className="font-medium">{localOrder.paymentResult.payer.email_address}</dd>
                  </div>
                )}
              </dl>
            </div>
          )}
        </div>
      </div>

      <CreateInvoiceModal
        order={localOrder}
        onClose={() => setShowInvoiceModal(false)}
        show={showInvoiceModal}
        refetch={refetch}
      />
    </>
  );
};

export default CommercialOrderAdminDetailsScreen; 