const addDecimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2)
}

const updateCart = (state) => {
      state.itemsPrice = addDecimals(
        state.cartItems.reduce((acc, item) => acc + (item.customPrice || item.price) * item.qty, 0)
      )
      state.shippingPrice = addDecimals(0)
      // Calculate GST only for products marked as GST applicable
      state.taxPrice = addDecimals(
        state.cartItems.reduce((acc, item) => {
          // Only add GST if the item is explicitly marked as GST applicable (strict equality check)
          // This ensures strings like "true" or other truthy values don't trigger GST application
          if (item.gstApplicable === true) {
            return acc + ((item.customPrice || item.price) * item.qty * 0.10)
          }
          return acc
        }, 0)
      )
      state.totalPrice = addDecimals(
        Number(state.itemsPrice) + 
        Number(state.shippingPrice) + 
        Number(state.taxPrice)
      )
      localStorage.setItem('cart', JSON.stringify(state.cartItems))
}
module.exports = {addDecimals, updateCart }

