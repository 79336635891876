import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useGetCommercialOrderDetailsQuery } from '../../slices/commercialOrdersApiSlice';
import Loader from '../../components/ToucanLoader';
import Message from '../../components/Message';
import { addDecimals } from '../../utils/cartUtils';
import { useSelector } from 'react-redux';

const CommercialOrderSummary = () => {
  const { id: orderId } = useParams();
  const { 
    data: order, 
    isLoading, 
    error,
    refetch
  } = useGetCommercialOrderDetailsQuery(orderId);

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (order?.isPaid) {
      refetch();
    }
  }, [order?.isPaid, refetch]);

  if (isLoading) return <Loader />;
  if (error) return <Message variant='danger'>Unable to load order information. Please refresh the page or try again later.</Message>;

  return (
    <main className="relative lg:min-h-full">
      <div className="h-80 overflow-hidden lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12">
      </div>

      <div>
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">
          <div className="lg:col-start-2">
            <h1 className="text-sm font-medium text-amber-600">Order confirmed</h1>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Thanks for ordering</p>
            <p className="mt-2 text-base text-gray-500">
              Your order has been confirmed and will be shipping soon.
            </p>

            <dl className="mt-16 text-sm font-medium">
              <dt className="text-gray-900">Order number</dt>
              <dd className="mt-2 text-amber-600">{order._id}</dd>
            </dl>

            <ul
              className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500"
            >
              {order.orderItems.map((item) => (
                <li key={item._id} className="flex space-x-6 py-6">
                  <img
                    src={item.image.startsWith('http') 
                      ? item.image 
                      : `${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL_PRODUCTION : process.env.REACT_APP_API_URL}${item.image.startsWith('/') ? '' : '/'}${item.image}`}
                    alt={item.name}
                    className="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover"
                  />
                  <div className="flex-auto space-y-1">
                    <h3 className="text-gray-900">
                      <Link to={`/product/${item.product}`}>{item.name}</Link>
                    </h3>
                    <p>Quantity: {item.qty}</p>
                    <div>
                      {item.customPrice ? (
                        <p className="text-amber-600 font-medium">Custom Price: ${addDecimals(item.customPrice)}</p>
                      ) : (
                        <p className="text-gray-900">Price: ${addDecimals(Number(item.price))}</p>
                      )}
                    </div>
                  </div>
                  <p className="flex-none font-medium text-gray-900">
                    ${addDecimals((item.customPrice || item.price) * item.qty)}
                  </p>
                </li>
              ))}
            </ul>

            <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
              <div className="flex justify-between">
                <dt>Subtotal</dt>
                <dd className="text-gray-900">${addDecimals(Number(order.itemsPrice))}</dd>
              </div>

              <div className="flex justify-between">
                <dt>Shipping</dt>
                <dd className="text-gray-900">${addDecimals(Number(order.shippingPrice))}</dd>
              </div>

              <div className="flex justify-between">
                <dt>Tax (GST)</dt>
                <dd className="text-gray-900">
                  ${addDecimals(Number(order.taxPrice))}
                </dd>
              </div>

              <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                <dt className="text-base">Total</dt>
                <dd className="text-base">
                  ${addDecimals(Number(order.totalPrice))}
                </dd>
              </div>
            </dl>

            <dl className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-4 text-sm font-medium text-gray-500">
              <div>
                <dt className="font-medium text-gray-900">Shipping Address</dt>
                <dd className="mt-2">
                  <address className="not-italic">
                    <span className="block">
                      {order.shippingAddress.firstName} {order.shippingAddress.lastName}
                    </span>
                    {order.shippingAddress.company && (
                      <span className="block">{order.shippingAddress.company}</span>
                    )}
                    <span className="block">{order.shippingAddress.phone}</span>
                    <span className="block">{order.shippingAddress.address}</span>
                    {order.shippingAddress.apartment && (
                      <span className="block">{order.shippingAddress.apartment}</span>
                    )}
                    <span className="block">
                      {order.shippingAddress.city}, {order.shippingAddress.state} {order.shippingAddress.postalCode}
                    </span>
                    <span className="block">{order.shippingAddress.country}</span>
                  </address>
                </dd>
              </div>
              <div>
                <dt className="font-medium text-gray-900">Payment Information</dt>
                <dd className="mt-2 space-y-2">
                  <p className="text-gray-900">Payment Method: {order.paymentMethod}</p>
                  {order.isPaid ? (
                    <div>
                      <p className="text-green-600">
                        Paid on {new Date(order.paidAt).toLocaleDateString()}
                      </p>
                      {order.paymentResult && (
                        <>
                          {order.paymentResult.id && (
                            <p className="text-gray-600">Transaction ID: {order.paymentResult.id}</p>
                          )}
                          {order.paymentResult.status && (
                            <p className="text-gray-600">Status: {order.paymentResult.status}</p>
                          )}
                          {order.paymentResult.payer?.email_address && (
                            <p className="text-gray-600">Payer Email: {order.paymentResult.payer.email_address}</p>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <p className="text-red-600">Not Paid</p>
                  )}
                </dd>
              </div>
            </dl>

            <div className="mt-16 border-t border-gray-200 py-6 flex justify-between items-center">
              {userInfo && (
                <Link 
                  to="/profile" 
                  className="text-sm font-medium text-amber-600 hover:text-amber-500"
                >
                  View My Past Orders
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              )}
              <Link 
                to="/shop/commercial" 
                className="text-sm font-medium text-amber-600 hover:text-amber-500 ml-auto"
              >
                Continue Shopping
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CommercialOrderSummary; 