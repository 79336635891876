import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { ShoppingCartIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'
import {removeFromCart} from '../../slices/cartSlice'
import { addDecimals } from '../../utils/cartUtils'
import Message from '../../components/Message'
import { toast } from 'react-toastify'
import FormattedPrice from '../../components/FormattedPrice'

const CartScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const cart = useSelector(state => state.cart)
  const {cartItems} = cart
  const { userInfo } = useSelector(state => state.auth)

  const handleRemoveItem = (id) => {
    dispatch(removeFromCart(id))
  }

  const handleCheckout = () => {
    try {
      const hasCommercialItems = cartItems.some(item => item.isCommercial);
      const hasRegularItems = cartItems.some(item => !item.isCommercial);

      if (hasCommercialItems && hasRegularItems) {
        toast.error('Regular and commercial products need to be purchased separately. Please check out with one type at a time.');
        return;
      }

      if (hasCommercialItems) {
        if (!userInfo) {
          navigate('/login?redirect=/commercialCheckout');
          return;
        }
        if (!userInfo.isCommercialAccount) {
          toast.error('You need a commercial account to purchase commercial products.');
          return;
        }
        navigate('/commercialCheckout');
      } else {
        if (userInfo) {
          navigate('/checkout');
        } else {
          navigate('/checkout?guest=true');
        }
      }
    } catch (error) {
      toast.error('There was a problem proceeding to checkout. Please try again.');
    }
  }

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Shopping Cart</h1>
        
        {cartItems.length === 0 ? (
          <Message 
            className="mt-4" 
          >
            Your cart is empty
          </Message>
        ) : (
          <>
            <button
              onClick={() => navigate(-1)}
              className="mt-4 inline-flex items-center rounded-md bg-gray-100 px-3 py-3 text-sm font-medium text-gray-600 hover:bg-gray-200"
            >
              <ArrowLeftIcon className="h-5 w-5 mr-1" />
              Back
            </button>
            
            <form className="mt-10 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
              <section aria-labelledby="cart-heading" className="lg:col-span-7">
                <h2 id="cart-heading" className="sr-only">
                  Items in your shopping cart
                </h2>

                <ul className="divide-y divide-gray-200 border-b border-t border-gray-200">
                  {cartItems.map((item) => (
                    <li key={item._id || item.product} className="flex items-start py-6">
                      <img
                        alt={item.name}
                        src={item.image}
                        className="h-24 w-24 flex-none rounded-md object-cover"
                      />
                      <div className="ml-4 flex-auto space-y-2">
                        <h3 className="text-black text-base">
                          {item.isCommercial ? (
                            <span>{item.name}</span>
                          ) : (
                            <Link to={`/product/${item._id}`} className="hover:text-gray-800">
                              {item.name}
                            </Link>
                          )}
                        </h3>
                        {item.isCommercial && (
                          <span className="inline-flex items-center rounded-md bg-amber-100 px-2 py-1 text-xs font-medium text-amber-700">
                            Commercial
                          </span>
                        )}
                        <p>Quantity: {item.qty}</p>
                        {item.grind && !item.isCommercial && (
                          <p className="text-sm text-gray-600">Grind: {item.grind}</p>
                        )}
                      </div>
                      <div className="ml-4 flex items-start space-x-4">
                        <p className="flex-none text-base font-medium text-black">
                          <FormattedPrice price={item.customPrice || item.price} />
                        </p>
                        <button 
                          type="button" 
                          onClick={() => handleRemoveItem(item._id)}
                          className="ml-6 text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">Remove</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </section>

              {/* Order summary */}
              <section
                aria-labelledby="summary-heading"
                className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
              >
                <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
                  Order summary
                </h2>

                <dl className="mt-6 space-y-4">
                  {/* Add itemized product list */}
                  {cartItems.map((item) => (
                    <div key={item._id} className="flex items-center justify-between text-sm">
                      <dt className="text-gray-600">
                        {item.name} (x{item.qty})
                      </dt>
                      <dd className="font-medium text-gray-900">
                        ${addDecimals((item.customPrice || item.price) * item.qty)}
                      </dd>
                    </div>
                  ))}

                  {/* Separator line */}
                  <div className="border-t border-gray-200 pt-4">
                    <div className="flex items-center justify-between">
                      <dt className="text-sm text-gray-600">Subtotal</dt>
                      <dd className="text-sm font-medium text-gray-900">
                        ${addDecimals(cartItems.reduce((acc, item) => acc + item.qty * (item.customPrice || item.price), 0))}
                      </dd>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <dt className="text-sm text-gray-600">Shipping</dt>
                    <dd className="text-sm font-medium text-gray-900">
                      ${addDecimals(0)}
                    </dd>
                  </div>

                  <div className="flex items-center justify-between">
                    <dt className="text-sm text-gray-600">Tax (GST)</dt>
                    <dd className="text-sm font-medium text-gray-900">
                      ${addDecimals(cartItems.reduce((acc, item) => {
                        // Only add GST if the item is explicitly marked as GST applicable
                        if (item.gstApplicable === true) {
                          return acc + ((item.customPrice || item.price) * item.qty * 0.10)
                        }
                        return acc
                      }, 0))}
                    </dd>
                  </div>

                  <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                    <dt className="text-base font-medium text-gray-900">Order total</dt>
                    <dd className="text-base font-medium text-gray-900">
                      ${addDecimals(
                        cartItems.reduce((acc, item) => acc + item.qty * (item.customPrice || item.price), 0) + // Subtotal
                        0 + // Shipping
                        cartItems.reduce((acc, item) => {
                          // Only add GST if the item is explicitly marked as GST applicable
                          if (item.gstApplicable === true) {
                            return acc + ((item.customPrice || item.price) * item.qty * 0.10)
                          }
                          return acc
                        }, 0) // Tax
                      )}
                    </dd>
                  </div>
                </dl>

                <div className="mt-6">
                  <button
                    type="button"
                    onClick={handleCheckout}
                    disabled={cartItems.length === 0}
                    className="w-full rounded-xl border border-transparent bg-amber-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 disabled:opacity-50"
                  >
                    Proceed to Checkout
                  </button>

                  {cartItems.length > 0 && (
                    <>
                      <div className="relative mt-4">
                        <div className="absolute inset-0 flex items-center">
                          <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div className="relative flex justify-center text-sm">
                          <span className="bg-gray-50 px-4 text-gray-500">or</span>
                        </div>
                      </div>
                      <Link
                        to={cartItems.some(item => item.isCommercial) ? "/shop/commercial" : "/shop"}
                        className="mt-4 w-full inline-flex justify-center items-center rounded-xl border border-gray-300 bg-white px-4 py-3 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2"
                      >
                        <ShoppingCartIcon className="w-5 h-5 mr-2" />
                        Keep Shopping
                      </Link>
                    </>
                  )}
                </div>
              </section>
            </form>
          </>
        )}
      </div>
    </div>
  )
}

export default CartScreen