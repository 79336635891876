import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { 
  useGetCommercialProductDetailsQuery, 
  useUpdateCommercialProductMutation, 
  useUploadCommercialProductImageMutation 
} from '../../slices/commercialProductsApiSlice';
import ToucanLoader from '../../components/ToucanLoader';
import Message from '../../components/Message';

const EditCommercialProductScreen = () => {
  const { id: productId } = useParams();
  const navigate = useNavigate();
  
  const { data: product, isLoading, error } = useGetCommercialProductDetailsQuery(productId);
  const [updateCommercialProduct, { isLoading: isUpdating }] = useUpdateCommercialProductMutation();
  const [uploadCommercialProductImage, { isLoading: isUploading }] = useUploadCommercialProductImageMutation();

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    image: '',
    featured: false,
    gstApplicable: false,
    tags: [],
    minOrderQuantity: 1
  });

  useEffect(() => {
    if (product) {
      setFormData({
        name: product.name,
        description: product.description,
        price: product.price,
        image: product.image,
        featured: product.featured,
        gstApplicable: product.gstApplicable || false,
        tags: product.tags || [],
        minOrderQuantity: product.minOrderQuantity || 1
      });
    }
  }, [product]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'tags') {
      const options = e.target.options;
      const selectedTags = [];
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          selectedTags.push(options[i].value);
        }
      }
      setFormData(prev => ({
        ...prev,
        tags: selectedTags
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : type === 'number' ? Number(value) : value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate that at least one tag is selected
    if (formData.tags.length === 0) {
      toast.error('Please select at least one product tag');
      return;
    }
    
    try {
      await updateCommercialProduct({
        _id: productId,
        ...formData,
        price: Number(formData.price)
      }).unwrap();
      
      toast.success('Commercial product updated successfully');
      navigate('/admin/commercialProducts/page/1');
    } catch (err) {
      toast.error('Unable to update product. Please check all fields and try again.');
    }
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    
    if (!file) {
      toast.error('Please select a file');
      return;
    }

    const formData = new FormData();
    formData.append('image', file);
    
    try {
      const res = await uploadCommercialProductImage(formData).unwrap();
      
      if (!res.image) {
        throw new Error('No image path received from server');
      }
      
      setFormData(prev => ({ ...prev, image: res.image }));
      toast.success('Image uploaded successfully');
    } catch (err) {
      toast.error('Unable to upload image. Please try a smaller image or a different file format.');
      e.target.value = '';
    }
  };

  if (isLoading) {
    return <ToucanLoader />;
  }

  if (error) {
    return <Message variant="danger">{error?.data?.message || error.error}</Message>;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6">Edit Commercial Product</h1>

      <form onSubmit={handleSubmit} className="max-w-md space-y-6">
        {/* Basic Product Information */}
        <div className="relative">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            placeholder="Enter product name"
          />
        </div>

        <div className="relative">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            required
            rows={3}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            placeholder="Enter product description"
          />
        </div>

        {/* Commercial-specific fields */}
        <div className="relative">
          <label htmlFor="minOrderQuantity" className="block text-sm font-medium text-gray-700">
            Minimum Order Quantity
          </label>
          <input
            type="number"
            id="minOrderQuantity"
            name="minOrderQuantity"
            value={formData.minOrderQuantity}
            onChange={handleInputChange}
            required
            min="1"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>

        <div className="relative">
          <label htmlFor="gstApplicable" className="block text-sm font-medium text-gray-700">
            GST Applicable
          </label>
          <input 
            type="checkbox" 
            id="gstApplicable" 
            name="gstApplicable" 
            checked={formData.gstApplicable} 
            onChange={handleInputChange} 
          />
          <p className="mt-1 text-sm text-gray-500">Check this box if GST (10%) should be applied to this product</p>
        </div>

        {/* Standard Product Fields */}
        <div className="relative">
          <label htmlFor="price" className="block text-sm font-medium text-gray-700">
            Price
          </label>
          <input
            type="number"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleInputChange}
            required
            min="0"
            step="0.01"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            placeholder="Enter price"
          />
        </div>

        <div className="relative">
          <label htmlFor="image-file" className="block text-sm font-medium text-gray-700">
            Image File
          </label>
          <input
            type="file"
            id="image-file"
            onChange={uploadFileHandler}
            className="block w-full text-sm text-gray-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-md file:border-0
              file:text-sm file:font-semibold
              file:bg-amber-50 file:text-amber-700
              hover:file:bg-amber-100"
            accept="image/*"
          />
          {isUploading && <ToucanLoader small />}
          {formData.image && (
            <div className="mt-2">
              <img 
                src={formData.image} 
                alt="Preview" 
                className="h-32 w-32 object-cover rounded-md"
              />
            </div>
          )}
        </div>

        <div className="relative">
          <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
            Product Tags
          </label>
          <select
            id="tags"
            name="tags"
            multiple
            value={formData.tags}
            onChange={handleInputChange}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          >
            <option value="Coffee">Coffee</option>
            <option value="Syrup">Syrup</option>
            <option value="Chocolate">Chocolate</option>
            <option value="Chai">Chai</option>
            <option value="Tea-bags">Tea-bags</option>
            <option value="Cups">Cups</option>
            <option value="Lids">Lids</option>
            <option value="Sugar">Sugar</option>
            <option value="Cleaning">Cleaning</option>
            <option value="Loose-Leaf-Tea">Loose-Leaf-Tea</option>
            <option value="Stevia">Stevia</option>
            <option value="Other">Other</option>
          </select>
          <p className="mt-1 text-sm text-gray-500">Hold Ctrl (Windows) or Command (Mac) to select multiple tags</p>
        </div>

        <div className="flex space-x-4">
          <button
            type="submit"
            disabled={isUpdating}
            className="flex-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            {isUpdating ? <ToucanLoader small /> : 'Update Commercial Product'}
          </button>
          <button
            type="button"
            onClick={() => navigate('/admin/commercialProducts/page/1')}
            className="flex-1 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            Cancel
          </button>
        </div>
      </form>

      {isUpdating && <ToucanLoader />}
    </div>
  );
};

export default EditCommercialProductScreen; 