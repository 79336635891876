import React from 'react';

const PrivacyScreen = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Statement</h1>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Introduction</h2>
        <div className="space-y-4">
          <p>
            We understand that the privacy of your personal details, and the security of your transactions with us, is of paramount importance to you. TERRY'S COFFEE ROASTING has therefore adopted data protection policies with respect to your privacy and security. TERRY'S COFFEE ROASTING's Privacy Policy sets out the rules we will abide by when dealing with personal information we collect from individuals in the course of our business.
          </p>
          <p>
            We may make alterations or additions to this policy from time to time. Should we do so, we will update the policy on our web site. We require that all our customers agree to abide by our Privacy Policy as part of our terms of trade. This policy also applies to users of our web site who are not customers of TERRY'S COFFEE ROASTING.
          </p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">What Information We Collect</h2>
        <p className="mb-4">TERRY'S COFFEE ROASTING collects different information about you at different times. There are four broad categories of information that we collect from you:</p>
        
        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-medium mb-2">2.1 Information we require to supply our products</h3>
            <p>When you first sign up or contract TERRY'S COFFEE ROASTING for our products or services, or when you make an inquiry about our services, we request information such as your name, address, telephone number, email details of your primary and secondary contacts and possibly credit card details. We may also ask for your age and gender.</p>
          </div>

          <div>
            <h3 className="text-lg font-medium mb-2">2.2 Non personally identifying information</h3>
            <p>In addition to the information you specifically provide above, TERRY'S COFFEE ROASTING operates statistics gathering software on its web site to collect information about the number of visitors coming to the site. No identifying information is collected by this software. The software records the IP address of the visitor only.</p>
          </div>

          <div>
            <h3 className="text-lg font-medium mb-2">2.3 Other demographic information</h3>
            <p>If you respond voluntarily to our surveys or other interactive communications, we collect the responses and use the information to improve the quality and range of TERRY'S COFFEE ROASTING's products and services.</p>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">How We Use Personal Information</h2>
        <div className="space-y-4">
          <p>We collect and use your personal information to operate our web site and deliver our products and services to you. We will use your personal information to provide you with:</p>
          <ul className="list-disc pl-6">
            <li>technical support</li>
            <li>billing and credit control</li>
            <li>sales support</li>
            <li>product upgrades</li>
            <li>information</li>
          </ul>
          <p>
            We will treat all information we collect from you as strictly confidential. TERRY'S COFFEE ROASTING does not rent or lease its customer lists to third parties. We will not reveal, disclose, sell, distribute, rent, licence, share or pass onto any third party any personal information that you may have provided to us unless we have your express consent to do so.
          </p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Security</h2>
        <p>
          TERRY'S COFFEE ROASTING has implemented security features in our database to protect your personal information from unauthorised access. We maintain our servers in a controlled, secured environment. Only staff who need to have access to your personal information in order to perform their job function are authorised to access the database. Constant changes to the internet and technology mean that we cannot guarantee that data transmission will be 100% secure, or safe from attack by unauthorised intruders.
        </p>
      </section>
    </div>
  );
};

export default PrivacyScreen; 