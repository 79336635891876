import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const userData = action.payload.data?.user || action.payload;
      const token = action.payload.token;
      
      const sanitizedUserData = {
        _id: userData._id,
        name: userData.name || '',
        email: userData.email || '',
        businessName: userData.businessName || '',
        role: userData.role || '',
        photo: userData.photo || '',
        isCommercialAccount: userData.isCommercialAccount || false,
        commercialAccountRequestPending: userData.commercialAccountRequestPending || false,
        commercialPaymentPlan: userData.commercialPaymentPlan || 'pay-now',
        token
      };

      state.userInfo = sanitizedUserData;
      localStorage.setItem('userInfo', JSON.stringify(sanitizedUserData));
    },
    logout: (state, action) => {
      state.userInfo = null;
      localStorage.removeItem('userInfo')
    },
  },
})

export const { setCredentials, logout } = authSlice.actions

export default authSlice.reducer