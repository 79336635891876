import React, { useState, useMemo } from 'react';
import { APIProvider, Map, Marker, InfoWindow } from '@vis.gl/react-google-maps';
import ToucanLoader from './ToucanLoader';

const GoogleMap = ({ locations = [], isLoading, error }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [hoveredMarkerId, setHoveredMarkerId] = useState(null);

  const markerIcon = {
    url: '/images/bird.png',
    scaledSize: { width: 40, height: 40 }
  };

  // Calculate the center based on the first location
  const center = useMemo(() => ({
    lat: locations[0]?.position.lat ?? -33.8688, // Sydney fallback
    lng: locations[0]?.position.lng ?? 151.2093
  }), [locations]);

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
    return <div>Google Maps API key not found</div>;
  }

  if (isLoading) {
    return <ToucanLoader />;
  }

  if (error) {
    return <div className="text-red-500">Error loading locations!</div>;
  }

  if (!locations || locations.length === 0) {
    return <div>No locations found</div>;
  }

  const getDirectionsUrl = (location) => {
    const destination = encodeURIComponent(location.name + ' ' + location.address);
    return `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
  };

  return (
    <div className="h-[600px] w-full rounded-lg overflow-hidden shadow-lg">
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <Map
          defaultCenter={center}
          defaultZoom={12}
          gestureHandling={'greedy'}
          mapId={process.env.REACT_APP_GOOGLE_MAPS_MAP_ID}
          onClick={() => setSelectedLocation(null)}
        >
          {locations.map(location => {
            const isHovered = location.id === hoveredMarkerId || location._id === hoveredMarkerId;
            const isSelected = selectedLocation && 
              (selectedLocation.id === location.id || selectedLocation._id === location._id);
            // Don't apply hover effect if the location is selected
            const scale = isSelected ? 1 : (isHovered ? 1.2 : 1);
            
            return (
              <Marker
                key={location.id || location._id}
                position={{
                  lat: parseFloat(location.position.lat),
                  lng: parseFloat(location.position.lng)
                }}
                title={location.name}
                icon={{
                  ...markerIcon,
                  scaledSize: {
                    width: 40 * scale,
                    height: 40 * scale
                  }
                }}
                onClick={() => setSelectedLocation(location)}
                onMouseOver={() => !isSelected && setHoveredMarkerId(location.id || location._id)}
                onMouseOut={() => !isSelected && setHoveredMarkerId(null)}
                options={{
                  animation: window.google?.maps?.Animation?.DROP,
                }}
              />
            );
          })}
          
          {selectedLocation && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedLocation.position.lat),
                lng: parseFloat(selectedLocation.position.lng)
              }}
              onCloseClick={() => setSelectedLocation(null)}
            >
              <div className="p-2 max-w-xs">
                <h3 className="font-semibold text-lg mb-2">{selectedLocation.name}</h3>
                <p className="text-gray-600 mb-1">{selectedLocation.address}</p>
                {selectedLocation.phone && (
                  <p className="text-gray-600 mb-1">
                    <span className="inline-block w-4 h-4 mr-1 align-text-bottom">
                      📞
                    </span>
                    {selectedLocation.phone}
                  </p>
                )}
                {selectedLocation.hours && (
                  <p className="text-gray-600 mb-1">
                    <span className="inline-block w-4 h-4 mr-1 align-text-bottom">
                      🕒
                    </span>
                    {selectedLocation.hours}
                  </p>
                )}
                <a
                  href={getDirectionsUrl(selectedLocation)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block mt-2 px-4 py-2 bg-[#fe940c] text-white rounded-md hover:bg-[#e07d00] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fe940c]"
                >
                  Get Directions
                </a>
              </div>
            </InfoWindow>
          )}
        </Map>
      </APIProvider>
    </div>
  );
};

export default React.memo(GoogleMap);