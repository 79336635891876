import React, { useState } from 'react';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import { useGetCommercialProductsQuery } from '../../slices/commercialProductsApiSlice';
import { useGetCommercialUsersQuery } from '../../slices/usersApiSlice';
import { useGetPricingRulesQuery, useCreatePricingRuleMutation, useUpdatePricingRuleMutation, useDeletePricingRuleMutation } from '../../slices/commercialPricingApiSlice';
import ToucanLoader from '../../components/ToucanLoader';
import Message from '../../components/Message';
import { toast } from 'react-toastify';
import { PencilSquareIcon, TrashIcon, ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/solid';
import FormattedPrice from '../../components/FormattedPrice';
import ConfirmationModal from '../../components/ConfirmationModal';
import Modal from '../../components/Modal';
import SearchBox from '../../components/SearchBox';

const CommercialPricingManagementScreen = () => {
  const { pageNumber } = useParams();
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword') || '';
  
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [customPrice, setCustomPrice] = useState('');
  const [notes, setNotes] = useState('');
  const [editingRule, setEditingRule] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { data: pricingRules, isLoading: rulesLoading, error: rulesError } = useGetPricingRulesQuery({
    keyword,
    pageNumber: pageNumber || 1
  });
  const { data: products, isLoading: productsLoading } = useGetCommercialProductsQuery({
    pageSize: 1000 // Set a large page size to get all products
  });
  const { data: users, isLoading: usersLoading } = useGetCommercialUsersQuery();

  const [createPricingRule] = useCreatePricingRuleMutation();
  const [updatePricingRule] = useUpdatePricingRuleMutation();
  const [deletePricingRule] = useDeletePricingRuleMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createPricingRule({
        user: selectedCustomer,
        product: selectedProduct,
        customPrice: Number(customPrice),
        notes,
      }).unwrap();
      toast.success('Pricing rule created successfully');
      setIsCreateModalOpen(false);
      resetForm();
    } catch (err) {
      // Check for specific error about duplicate pricing rule
      if (err?.data?.message?.includes('already exists')) {
        toast.error('A pricing rule already exists for this customer and product combination.');
      } else {
        toast.error(err?.data?.message || 'An error occurred while creating the pricing rule.');
      }
    }
  };

  const handleEdit = (rule) => {
    setEditingRule(rule);
    setCustomPrice(rule.customPrice);
    setNotes(rule.notes || '');
    setIsEditModalOpen(true);
  };

  const handleUpdateRule = async (e) => {
    e.preventDefault();
    try {
      await updatePricingRule({
        id: editingRule._id,
        customPrice: Number(customPrice),
        notes,
      }).unwrap();
      toast.success('Pricing rule updated successfully');
      setIsEditModalOpen(false);
      resetForm();
    } catch (err) {
      toast.error(err?.data?.message || 'An error occurred while updating the pricing rule.');
    }
  };

  const handleDeleteClick = (rule) => {
    setSelectedRule(rule);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      await deletePricingRule(selectedRule._id).unwrap();
      toast.success('Pricing rule deleted successfully');
      setShowDeleteModal(false);
    } catch (err) {
      toast.error(err?.data?.message || 'An error occurred while deleting the pricing rule.');
    }
  };

  const resetForm = () => {
    setEditingRule(null);
    setSelectedCustomer('');
    setSelectedProduct('');
    setCustomPrice('');
    setNotes('');
  };

  if (rulesLoading || productsLoading || usersLoading) return <ToucanLoader />;
  if (rulesError) return <Message variant='danger'>{rulesError?.data?.message || 'Error loading pricing rules'}</Message>;

  // Update the commercialUsers filtering section
  const commercialUsers = Array.isArray(users?.data?.users) ? users.data.users : [];

  const coffeeProducts = Array.isArray(products?.data?.commercialProducts)
    ? products.data.commercialProducts.filter(product => {
        return product.tags.includes('Coffee');
      })
    : [];
  
  return (
    <div className="mt-8">
      <div className="fixed right-0 lg:left-72 left-0 z-40 flex h-16 items-center border-b border-gray-200 bg-white shadow-sm ring-1 ring-gray-300 lg:top-0 top-[3.5rem]">
        <div className="flex flex-1 justify-between items-center px-4">
          <SearchBox />
        </div>
      </div>

      <div className="pt-[7.5rem] lg:pt-16">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Commercial Pricing Rules</h2>
          <button
            onClick={() => setIsCreateModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            Create Pricing Rule
          </button>
        </div>

        {/* Rules Table */}
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Base Price</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Custom Price</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Notes</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {pricingRules?.data?.map((rule) => (
                <tr key={rule._id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {rule.user ? (rule.user.businessName || rule.user.name || 'Unknown User') : 'Unknown User'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {rule.product ? rule.product.name : 'Unknown Product'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <FormattedPrice price={rule.product ? rule.product.price : 0} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <FormattedPrice price={rule.customPrice} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {rule.notes}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button
                      onClick={() => handleEdit(rule)}
                      className="text-amber-600 hover:text-amber-900 mr-3"
                    >
                      <PencilSquareIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDeleteClick(rule)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        {pricingRules?.pages > 1 && (
          <nav className="flex items-center justify-between px-4 sm:px-0 mt-12 mb-12">
            <div className="flex w-0 flex-1">
              <Link
                to={`/admin/commercial-pricing/page/${Number(pageNumber) > 1 ? Number(pageNumber) - 1 : 1}${keyword ? `?keyword=${keyword}` : ''}`}
                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-amber-300 hover:text-amber-700"
              >
                <ArrowLongLeftIcon className="mr-3 size-5 text-gray-400" aria-hidden="true" />
                Previous
              </Link>
            </div>
            
            <div className="hidden md:flex">
              {[...Array(pricingRules.pages).keys()].map((x) => (
                <Link
                  key={x + 1}
                  to={`/admin/commercial-pricing/page/${x + 1}${keyword ? `?keyword=${keyword}` : ''}`}
                  aria-current={Number(pageNumber) === x + 1 ? 'page' : undefined}
                  className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                    Number(pageNumber) === x + 1
                      ? 'border-[#fe940c] text-[#cc7100]'
                      : 'border-transparent text-gray-500 hover:border-[#ffa53d] hover:text-[#e07d00]'
                  }`}
                >
                  {x + 1}
                </Link>
              ))}
            </div>
            
            <div className="flex w-0 flex-1 justify-end">
              <Link
                to={`/admin/commercial-pricing/page/${Number(pageNumber) < pricingRules.pages ? Number(pageNumber) + 1 : pricingRules.pages}${keyword ? `?keyword=${keyword}` : ''}`}
                className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-amber-300 hover:text-amber-700"
              >
                Next
                <ArrowLongRightIcon className="ml-3 size-5 text-gray-400" aria-hidden="true" />
              </Link>
            </div>
          </nav>
        )}
      </div>

      {/* Create Pricing Rule Modal */}
      <Modal
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
          resetForm();
        }}
        title="Create New Pricing Rule"
      >
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Customer</label>
              <select
                value={selectedCustomer}
                onChange={(e) => setSelectedCustomer(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
                required
              >
                <option value="">Select Customer</option>
                {commercialUsers.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.businessName || user.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Coffee Product</label>
              <select
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
                required
              >
                <option value="">Select Product</option>
                {coffeeProducts.map((product) => (
                  <option key={product._id} value={product._id}>
                    {product.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Custom Price</label>
              <input
                type="number"
                step="0.01"
                value={customPrice}
                onChange={(e) => setCustomPrice(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Notes</label>
              <input
                type="text"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
                placeholder="Optional notes about this pricing rule"
              />
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={() => {
                setIsCreateModalOpen(false);
                resetForm();
              }}
              className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-amber-600 text-white px-4 py-2 rounded-md hover:bg-amber-700"
            >
              Create
            </button>
          </div>
        </form>
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          resetForm();
        }}
        title="Edit Pricing Rule"
      >
        <form onSubmit={handleUpdateRule} className="space-y-4">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Customer</label>
              <input
                type="text"
                value={editingRule?.user ? (editingRule.user.businessName || editingRule.user.name) : ''}
                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm"
                disabled
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Product</label>
              <input
                type="text"
                value={editingRule?.product ? editingRule.product.name : ''}
                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm"
                disabled
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Base Price</label>
              <input
                type="text"
                value={editingRule?.product ? `$${editingRule.product.price.toFixed(2)}` : '$0.00'}
                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm"
                disabled
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Custom Price</label>
              <input
                type="number"
                step="0.01"
                value={customPrice}
                onChange={(e) => setCustomPrice(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Notes</label>
              <input
                type="text"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500"
                placeholder="Optional notes about this pricing rule"
              />
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={() => {
                setIsEditModalOpen(false);
                resetForm();
              }}
              className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-amber-600 text-white px-4 py-2 rounded-md hover:bg-amber-700"
            >
              Update
            </button>
          </div>
        </form>
      </Modal>

      <ConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        title="Delete Pricing Rule"
        message={`Are you sure you want to delete the pricing rule for ${selectedRule?.user ? (selectedRule.user.businessName || selectedRule.user.name || 'Unknown User') : 'Unknown User'} - ${selectedRule?.product ? selectedRule.product.name : 'Unknown Product'}? This action cannot be undone.`}
        confirmText="Delete"
        confirmButtonClass="bg-red-600 hover:bg-red-700"
      />
    </div>
  );
};

export default CommercialPricingManagementScreen; 