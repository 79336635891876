import { apiSlice } from './apiSlice';
import { COMMERCIAL_REQUESTS_URL } from '../constants'

export const commercialAccountRequestApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCommercialRequest: builder.mutation({
      query: (data) => ({
        url: COMMERCIAL_REQUESTS_URL,
        method: 'POST',
        body: data,
        // Skip auth header for public inquiries
        skipToken: data.publicInquiry
      }),
    }),
    getCommercialRequests: builder.query({
      query: () => ({
        url: COMMERCIAL_REQUESTS_URL,
      }),
      providesTags: ['CommercialRequests'],
    }),
    getCommercialRequestById: builder.query({
      query: (id) => ({
        url: `${COMMERCIAL_REQUESTS_URL}/${id}`,
      }),
      providesTags: ['CommercialRequest'],
    }),
    updateCommercialRequestStatus: builder.mutation({
      query: ({ id, status, adminNotes }) => ({
        url: `${COMMERCIAL_REQUESTS_URL}/${id}`,
        method: 'PUT',
        body: { status, adminNotes },
      }),
      invalidatesTags: ['CommercialRequests', 'CommercialRequest'],
    }),
    // Get user's own requests
    getUserCommercialRequests: builder.query({
      query: () => ({
        url: `${COMMERCIAL_REQUESTS_URL}/my-requests`,
      }),
      providesTags: ['UserCommercialRequests'],
    }),
  }),
});

export const {
  useCreateCommercialRequestMutation,
  useGetCommercialRequestsQuery,
  useGetCommercialRequestByIdQuery,
  useUpdateCommercialRequestStatusMutation,
  useGetUserCommercialRequestsQuery,
} = commercialAccountRequestApiSlice; 