import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { useCreateCommercialOrderMutation } from '../../slices/commercialOrdersApiSlice'
import { useGetAddressQuery, useUpsertAddressMutation } from '../../slices/commercialAddressesApiSlice'
import { useCreateCheckoutSessionMutation } from '../../slices/stripeApiSlice'
import { clearCartItems } from '../../slices/cartSlice'
import { toast } from 'react-toastify'
import { addDecimals } from '../../utils/cartUtils'
import Loader from '../../components/ToucanLoader'
import FormattedPrice from '../../components/FormattedPrice'
import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import StripeCheckoutForm from '../../components/StripeCheckoutForm'

const CommercialCheckoutScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  const { cartItems } = useSelector((state) => state.cart)
  const { userInfo } = useSelector((state) => state.auth)

  // Redirect if not logged in
  useEffect(() => {
    if (!userInfo) {
      navigate('/login?redirect=/commercialCheckout')
      toast.error('Please login to place a commercial order')
    }
  }, [userInfo, navigate])

  // Form state
  const [firstName, setFirstName] = useState(userInfo?.name?.split(' ')[0] || '')
  const [lastName, setLastName] = useState(userInfo?.name?.split(' ')[1] || '')
  const [company, setCompany] = useState(userInfo?.businessName || '')
  const [address, setAddress] = useState('')
  const [apartment, setApartment] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('Australia')
  const [phone, setPhone] = useState('')
  const [sameAsShipping, setSameAsShipping] = useState(true)
  const [notes, setNotes] = useState('')
  
  // Billing address state (only used if sameAsShipping is false)
  const [billingFirstName, setBillingFirstName] = useState('')
  const [billingLastName, setBillingLastName] = useState('')
  const [billingCompany, setBillingCompany] = useState('')
  const [billingAddress, setBillingAddress] = useState('')
  const [billingApartment, setBillingApartment] = useState('')
  const [billingCity, setBillingCity] = useState('')
  const [billingState, setBillingState] = useState('')
  const [billingPostalCode, setBillingPostalCode] = useState('')
  const [billingCountry, setBillingCountry] = useState('Australia')
  const [billingPhone, setBillingPhone] = useState('')

  // Order state
  const [isEditing, setIsEditing] = useState(false)
  const [orderPlaced, setOrderPlaced] = useState(false)
  const [orderId, setOrderId] = useState(null)
  const [clientSecret, setClientSecret] = useState('')
  const [isPending] = useState(false)

  // API hooks
  const [createOrder, { isLoading }] = useCreateCommercialOrderMutation()
  const [createCheckoutSession] = useCreateCheckoutSessionMutation()
  const { data: savedAddress } = useGetAddressQuery()
  const [upsertAddress] = useUpsertAddressMutation()

  // Pre-fill form with saved address
  useEffect(() => {
    if (savedAddress) {
      setFirstName(savedAddress.firstName || '')
      setLastName(savedAddress.lastName || '')
      setCompany(savedAddress.company || '')
      setAddress(savedAddress.street || '')
      setApartment(savedAddress.apartment || '')
      setCity(savedAddress.city || '')
      setState(savedAddress.state || '')
      setPostalCode(savedAddress.postalCode || '')
      setPhone(savedAddress.phone || '')
    }
  }, [savedAddress])

  // Calculate prices
  const itemsPrice = addDecimals(cartItems.reduce((acc, item) => acc + (item.customPrice || item.price) * item.qty, 0))
  const shippingPrice = addDecimals(0)
  const taxPrice = addDecimals(
    cartItems.reduce((acc, item) => {
      // Only add GST if the item is explicitly marked as GST applicable
      if (item.gstApplicable === true) {
        return acc + ((item.customPrice || item.price) * item.qty * 0.10)
      }
      return acc
    }, 0)
  )
  const totalPrice = addDecimals(Number(itemsPrice) + Number(shippingPrice) + Number(taxPrice))

  // Add display variables for prices and items
  const displayPrice = addDecimals(totalPrice)
  const displayItemsPrice = addDecimals(itemsPrice)
  const displayShippingPrice = addDecimals(shippingPrice)
  const displayTaxPrice = addDecimals(taxPrice)
  const displayItems = cartItems

  // Form submission
  const submitHandler = async (e) => {
    e.preventDefault()
    
    try {
      // Validate cart is not empty
      if (cartItems.length === 0) {
        toast.error('Your cart is empty')
        return
      }

      // Validate company name
      if (!company) {
        toast.error('Company name is required for commercial orders')
        return
      }

      const shippingAddressData = {
        firstName,
        lastName,
        company,
        address,
        apartment,
        city,
        postalCode,
        state,
        country,
        phone,
      }

      const billingAddressData = sameAsShipping ? shippingAddressData : {
        firstName: billingFirstName,
        lastName: billingLastName,
        company: billingCompany,
        address: billingAddress,
        apartment: billingApartment,
        city: billingCity,
        postalCode: billingPostalCode,
        state: billingState,
        country: billingCountry,
        phone: billingPhone,
      }

      // Create order first
      const res = await createOrder({
        orderItems: cartItems,
        shippingAddress: shippingAddressData,
        billingAddress: billingAddressData,
        paymentMethod: userInfo.commercialPaymentPlan === 'pay-now' ? 'Stripe' : 'PayLater',
        itemsPrice,
        shippingPrice,
        taxPrice,
        totalPrice,
        notes,
      }).unwrap()

      // Save shipping address to user profile after successful order
      try {
        await upsertAddress({
          company,
          firstName,
          lastName,
          street: address,
          apartment,
          city,
          state,
          postalCode,
          country,
          phone,
        }).unwrap()
      } catch (err) {
        // Continue with order even if address save fails
      }

      // For pay-later accounts, create order immediately
      if (userInfo.commercialPaymentPlan === 'pay-later') {
        dispatch(clearCartItems())
        toast.success('Order placed successfully!')
        navigate(`/commercialOrder/${res._id}`)
        return
      }

      // For pay-now accounts, create Stripe session
      const stripeSession = await createCheckoutSession({
        orderId: res._id,
        orderType: 'commercial'
      }).unwrap()

      setOrderId(res._id)
      setClientSecret(stripeSession.clientSecret)
      setOrderPlaced(true)
      
    } catch (err) {
      if (err?.data?.message?.includes('Order validation failed')) {
        toast.error('Please complete all required fields marked with *')
      } else {
        toast.error('Something went wrong.  Please ensure you have entered all marked fields correctly and try again.')
      }
    }
  }

  // Function to handle going back to edit
  const handleEdit = () => {
    setIsEditing(true)
  }

  // Function to handle updating the order
  const handleUpdate = async (e) => {
    if (e) e.preventDefault()
    
    try {
      // Validate cart is not empty
      if (cartItems.length === 0) {
        toast.error('Your cart is empty')
        return
      }

      // Validate company name
      if (!company) {
        toast.error('Company name is required for commercial orders')
        return
      }

      const shippingAddressData = {
        firstName,
        lastName,
        company,
        address,
        apartment,
        city,
        postalCode,
        state,
        country,
        phone,
      }

      const billingAddressData = sameAsShipping ? shippingAddressData : {
        firstName: billingFirstName,
        lastName: billingLastName,
        company: billingCompany,
        address: billingAddress,
        apartment: billingApartment,
        city: billingCity,
        postalCode: billingPostalCode,
        state: billingState,
        country: billingCountry,
        phone: billingPhone,
      }

      // Update order data
      const updatedOrderData = {
        orderItems: cartItems,
        shippingAddress: shippingAddressData,
        billingAddress: billingAddressData,
        paymentMethod: userInfo.commercialPaymentPlan === 'pay-now' ? 'Stripe' : 'PayLater',
        itemsPrice,
        shippingPrice,
        taxPrice,
        totalPrice,
        notes,
      }

      // Create order first
      const res = await createOrder(updatedOrderData).unwrap()

      // Save shipping address to user profile after successful order
      try {
        await upsertAddress({
          company,
          firstName,
          lastName,
          street: address,
          apartment,
          city,
          state,
          postalCode,
          country,
          phone,
        }).unwrap()
      } catch (err) {
        // Continue with order even if address save fails
      }

      // For pay-later accounts, create order immediately
      if (userInfo.commercialPaymentPlan === 'pay-later') {
        dispatch(clearCartItems())
        toast.success('Order placed successfully!')
        navigate(`/commercialOrder/${res._id}`)
        return
      }

      // For pay-now accounts, create Stripe session
      const stripeSession = await createCheckoutSession({
        orderId: res._id,
        orderType: 'commercial'
      }).unwrap()

      setOrderId(res._id)
      setClientSecret(stripeSession.clientSecret)
      setOrderPlaced(true)
      setIsEditing(false)
      toast.success('Order details updated successfully')
      
    } catch (err) {
      if (err?.data?.message?.includes('Order validation failed')) {
        toast.error('Please complete all required fields marked with *')
      } else {
        toast.error('Something went wrong.  Please ensure you have entered all marked fields correctly and try again.')
      }
    }
  }

  // Replace PayPal section with Stripe
  const renderPaymentSection = () => {
    if (orderPlaced && !isEditing && clientSecret) {
      return (
        <StripeCheckoutForm 
          orderId={orderId} 
          orderType="commercial"
        />
      )
    }
    return null
  }

  return (
    <div className="bg-gray-100">
      <div className="relative mx-auto grid max-w-full grid-cols-1 lg:grid-cols-2 gap-x-8 mt-8">
        <h1 className="sr-only">Commercial Checkout</h1>

        {/* Order Summary Section */}
        <section
          aria-labelledby="summary-heading"
          className="order-first bg-gray-100 py-8 text-black md:px-10 lg:order-last lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-md xl:max-w-lg lg:bg-transparent lg:px-8 lg:pb-24 lg:pt-8 rounded-3xl shadow-lg mb-8 lg:rounded-none lg:shadow-none lg:mb-0 mx-4 sm:mx-6"
        >
          <div className="mx-auto max-w-2xl px-4 lg:px-0">
            <h2 id="summary-heading" className="text-lg font-medium text-black mb-6">
              Commercial Order Summary
            </h2>

            <div className="max-w-md space-y-8">
              <dl>
                <dt className="text-sm font-medium">Amount due</dt>
                <dd className="mt-1 text-3xl font-bold tracking-tight text-black">${displayPrice}</dd>
              </dl>

              <ul className="divide-y divide-gray-200/10 text-sm font-medium">
                {displayItems.map((item) => (
                  <li key={item._id} className="flex items-start space-x-4 py-6">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="h-24 w-24 flex-none rounded-md object-cover"
                    />
                    <div className="flex-auto space-y-2">
                      <h3 className="text-black text-base">{item.name}</h3>
                      <p>Quantity: {item.qty}</p>
                    </div>
                    <p className="flex-none text-base font-medium text-black">
                      <FormattedPrice price={item.customPrice || item.price} />
                    </p>
                  </li>
                ))}
              </ul>

              {/* Order Summary */}
              <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium">
                <div className="flex items-center justify-between">
                  <dt>Subtotal</dt>
                  <dd>${displayItemsPrice}</dd>
                </div>

                <div className="flex items-center justify-between">
                  <dt>Shipping</dt>
                  <dd>${displayShippingPrice}</dd>
                </div>

                <div className="flex items-center justify-between">
                  <dt>Tax (GST)</dt>
                  <dd>${displayTaxPrice}</dd>
                </div>

                <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-black">
                  <dt className="text-base">Total</dt>
                  <dd className="text-base">${displayPrice}</dd>
                </div>
              </dl>
            </div>
          </div>
        </section>

        {/* Form Section */}
        <section
          aria-labelledby="payment-and-shipping-heading"
          className="order-last bg-white rounded-3xl shadow-lg mx-4 sm:mx-6 lg:mx-0 lg:shadow-none lg:rounded-none px-4 pt-8 pb-12 sm:px-6 lg:order-first lg:col-start-1 lg:row-start-1 lg:w-full lg:max-w-md xl:max-w-lg lg:pb-24 lg:pt-8 lg:px-8"
        >
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <h2 className="text-2xl font-medium text-gray-900 mb-8">
              Commercial Order Details
            </h2>

            <div className="space-y-10">
              {/* Shipping Information */}
              <div className="border-b border-gray-200 pb-8">
                <h3 className="text-lg font-medium text-gray-900 mb-6">Shipping information</h3>

                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  {/* Company field - Required for commercial orders */}
                  <div className="sm:col-span-2">
                    <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                      Company Name <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        id="company"
                        name="company"
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                        disabled={orderPlaced && !isEditing}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                      First name <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        id="first-name"
                        name="first-name"
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                        disabled={orderPlaced && !isEditing}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                      Last name <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        id="last-name"
                        name="last-name"
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                        disabled={orderPlaced && !isEditing}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                      Address <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        id="address"
                        name="address"
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                        disabled={orderPlaced && !isEditing}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label htmlFor="apartment" className="block text-sm font-medium text-gray-700">
                      Apartment, suite, etc.
                    </label>
                    <div className="mt-1">
                      <input
                        id="apartment"
                        name="apartment"
                        type="text"
                        value={apartment}
                        onChange={(e) => setApartment(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                        disabled={orderPlaced && !isEditing}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                      City <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        id="city"
                        name="city"
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                        disabled={orderPlaced && !isEditing}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                      State <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        id="state"
                        name="state"
                        type="text"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                        disabled={orderPlaced && !isEditing}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                      Postal code <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        id="postal-code"
                        name="postal-code"
                        type="text"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                        disabled={orderPlaced && !isEditing}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                      Country
                    </label>
                    <div className="mt-1">
                      <select
                        id="country"
                        name="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                        disabled={orderPlaced && !isEditing}
                      >
                        <option value="Australia">Australia</option>
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                      Phone <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        id="phone"
                        name="phone"
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                        disabled={orderPlaced && !isEditing}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Billing Information */}
              <div className="border-b border-gray-200 pb-8">
                <h3 className="text-lg font-medium text-gray-900 mb-6">Billing information</h3>

                <div className="mt-6 flex items-center">
                  <input
                    id="same-as-shipping"
                    name="same-as-shipping"
                    type="checkbox"
                    checked={sameAsShipping}
                    onChange={(e) => setSameAsShipping(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-500"
                    disabled={orderPlaced && !isEditing}
                  />
                  <div className="ml-2">
                    <label htmlFor="same-as-shipping" className="text-sm font-medium text-gray-900">
                      Same as shipping information
                    </label>
                  </div>
                </div>

                {!sameAsShipping && (
                  <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                    {/* Company field */}
                    <div className="sm:col-span-2">
                      <label htmlFor="billing-company" className="block text-sm font-medium text-gray-700">
                        Company Name <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          required
                          id="billing-company"
                          name="billing-company"
                          type="text"
                          value={billingCompany}
                          onChange={(e) => setBillingCompany(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    {/* First Name field */}
                    <div>
                      <label htmlFor="billing-first-name" className="block text-sm font-medium text-gray-700">
                        First name <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          required
                          id="billing-first-name"
                          name="billing-first-name"
                          type="text"
                          value={billingFirstName}
                          onChange={(e) => setBillingFirstName(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    {/* Last Name field */}
                    <div>
                      <label htmlFor="billing-last-name" className="block text-sm font-medium text-gray-700">
                        Last name <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          required
                          id="billing-last-name"
                          name="billing-last-name"
                          type="text"
                          value={billingLastName}
                          onChange={(e) => setBillingLastName(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    {/* Address field */}
                    <div className="sm:col-span-2">
                      <label htmlFor="billing-address" className="block text-sm font-medium text-gray-700">
                        Address <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          required
                          id="billing-address"
                          name="billing-address"
                          type="text"
                          value={billingAddress}
                          onChange={(e) => setBillingAddress(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    {/* Apartment field */}
                    <div className="sm:col-span-2">
                      <label htmlFor="billing-apartment" className="block text-sm font-medium text-gray-700">
                        Apartment, suite, etc.
                      </label>
                      <div className="mt-1">
                        <input
                          id="billing-apartment"
                          name="billing-apartment"
                          type="text"
                          value={billingApartment}
                          onChange={(e) => setBillingApartment(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    {/* City field */}
                    <div>
                      <label htmlFor="billing-city" className="block text-sm font-medium text-gray-700">
                        City <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          required
                          id="billing-city"
                          name="billing-city"
                          type="text"
                          value={billingCity}
                          onChange={(e) => setBillingCity(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    {/* State field */}
                    <div>
                      <label htmlFor="billing-state" className="block text-sm font-medium text-gray-700">
                        State <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          required
                          id="billing-state"
                          name="billing-state"
                          type="text"
                          value={billingState}
                          onChange={(e) => setBillingState(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    {/* Postal Code field */}
                    <div>
                      <label htmlFor="billing-postal-code" className="block text-sm font-medium text-gray-700">
                        Postal code <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          required
                          id="billing-postal-code"
                          name="billing-postal-code"
                          type="text"
                          value={billingPostalCode}
                          onChange={(e) => setBillingPostalCode(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    {/* Country field */}
                    <div>
                      <label htmlFor="billing-country" className="block text-sm font-medium text-gray-700">
                        Country
                      </label>
                      <div className="mt-1">
                        <select
                          id="billing-country"
                          name="billing-country"
                          value={billingCountry}
                          onChange={(e) => setBillingCountry(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        >
                          <option value="Australia">Australia</option>
                        </select>
                      </div>
                    </div>

                    {/* Phone field */}
                    <div className="sm:col-span-2">
                      <label htmlFor="billing-phone" className="block text-sm font-medium text-gray-700">
                        Phone <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          required
                          id="billing-phone"
                          name="billing-phone"
                          type="tel"
                          value={billingPhone}
                          onChange={(e) => setBillingPhone(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Payment Section */}
              <div className="border-gray-200 pb-8">
                <h3 className="text-lg font-medium text-gray-900 mb-6">Payment details</h3>
                
                {/* Add Notes Section */}
                <div className="mb-6">
                  <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-2">
                    Order Notes (Optional)
                  </label>
                  <textarea
                    id="notes"
                    name="notes"
                    rows={4}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Add any special instructions or notes for your order"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                    disabled={orderPlaced && !isEditing}
                  />
                </div>
                
                {isPending ? (
                  <Loader />
                ) : (
                  <div className="mt-6 space-y-4">
                    {orderPlaced && userInfo.commercialPaymentPlan === 'pay-now' ? (
                      <div className="mt-4 space-y-4">
                        {isEditing ? (
                          <button
                            type="button"
                            onClick={handleUpdate}
                            className="w-full rounded-md border border-transparent bg-amber-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                          >
                            Update Order Details
                          </button>
                        ) : (
                          <>
                            <button
                              type="button"
                              onClick={handleEdit}
                              className="w-full rounded-md border border-gray-300 bg-white px-4 py-3 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 focus:ring-offset-gray-50 mb-4"
                            >
                              Edit Order Details
                            </button>
                            {renderPaymentSection()}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="space-y-4">
                        <Link
                          to="/cart"
                          className="w-full inline-flex justify-center items-center rounded-md border border-gray-300 bg-white px-4 py-3 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                        >
                          <ShoppingCartIcon className="w-5 h-5 mr-2" />
                          Back to Cart
                        </Link>
                        <div className="relative">
                          <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300"></div>
                          </div>
                          <div className="relative flex justify-center text-sm">
                            <span className="bg-white px-4 text-gray-500">or</span>
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={submitHandler}
                          className="w-full rounded-md border border-transparent bg-amber-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                          disabled={isLoading}
                        >
                          {isLoading ? 'Processing...' : userInfo.commercialPaymentPlan === 'pay-now' ? 'Place Order & Pay Now' : 'Place Order'}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default CommercialCheckoutScreen 