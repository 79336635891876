import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetOrderByIdForAdminQuery, useUpdateOrderToCompletedMutation } from '../../slices/ordersApiSlice';
import Loader from '../../components/ToucanLoader';
import Message from '../../components/Message';
import { addDecimals } from '../../utils/cartUtils';
import { toast } from 'react-toastify';

const OrderAdminDetailsScreen = () => {
  const { id: orderId } = useParams();
  const navigate = useNavigate();
  const { 
    data: order, 
    isLoading, 
    error,
    refetch 
  } = useGetOrderByIdForAdminQuery(orderId);

  const [updateOrderToCompleted] = useUpdateOrderToCompletedMutation();

  const handleComplete = async () => {
    try {
      await updateOrderToCompleted(orderId).unwrap();
      refetch();
      toast.success('Order marked as completed');
    } catch (err) {
      toast.error('Unable to mark order as completed. Please try again.');
    }
  };

  if (isLoading) return <Loader />;
  if (error) return <Message variant='danger'>Unable to load order details. Please refresh the page or try again later.</Message>;

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
      <div className="space-y-8">
        {/* Header */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-0">
          <div>
            <h1 className="text-2xl font-bold">Order Details</h1>
            <p className="text-gray-500">Order #{order._id}</p>
          </div>
          <div className="flex flex-col sm:flex-row gap-2 sm:space-x-4 w-full sm:w-auto">
            <button
              onClick={() => navigate(-1)}
              className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 w-full sm:w-auto"
            >
              Back to Orders
            </button>
            {!order.isCompleted && (
              <button
                onClick={handleComplete}
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 w-full sm:w-auto"
              >
                Mark as Completed
              </button>
            )}
          </div>
        </div>

        {/* Order Status */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium mb-4">Order Status</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <p className="text-sm text-gray-500">Order Date</p>
              <p className="font-medium">{new Date(order.createdAt).toLocaleDateString()}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Payment Status</p>
              <p className={`font-medium ${order.isPaid ? 'text-green-600' : 'text-red-600'}`}>
                {order.isPaid ? `Paid on ${new Date(order.paidAt).toLocaleDateString()}` : 'Not Paid'}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Completion Status</p>
              <p className={`font-medium ${order.isCompleted ? 'text-green-600' : 'text-yellow-600'}`}>
                {order.isCompleted ? `Completed on ${new Date(order.completedAt).toLocaleDateString()}` : 'Not Completed'}
              </p>
            </div>
          </div>
        </div>

        {/* Customer Information */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium mb-4">Customer Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h3 className="font-medium text-gray-900">Customer Details</h3>
              <div className="mt-2">
                {order.user ? (
                  <>
                    <p>{order.user.name}</p>
                    <p className="text-gray-500">{order.user.email}</p>
                    <p className="text-sm text-gray-500 mt-1">Registered Customer</p>
                  </>
                ) : (
                  <>
                    <p className="font-medium">
                      {order.shippingAddress.firstName} {order.shippingAddress.lastName}
                    </p>
                    {order.shippingAddress.company && (
                      <p className="text-gray-600">{order.shippingAddress.company}</p>
                    )}
                    <p className="text-gray-500">{order.guestEmail}</p>
                    <p className="text-gray-500">{order.shippingAddress.phone}</p>
                    <p className="text-sm text-gray-500 mt-1">Guest Customer</p>
                  </>
                )}
              </div>
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Shipping Address</h3>
              <address className="mt-2 not-italic">
                <p>{order.shippingAddress.address}</p>
                {order.shippingAddress.apartment && (
                  <p>{order.shippingAddress.apartment}</p>
                )}
                <p>
                  {order.shippingAddress.city}, {order.shippingAddress.state} {order.shippingAddress.postalCode}
                </p>
                <p>{order.shippingAddress.country}</p>
              </address>
            </div>
          </div>

          {/* Billing Address (if different from shipping) */}
          {order.billingAddress && !order.sameAsShipping && (
            <div className="mt-6">
              <h3 className="font-medium text-gray-900">Billing Address</h3>
              <address className="mt-2 not-italic">
                <p>
                  {order.billingAddress.firstName} {order.billingAddress.lastName}
                </p>
                {order.billingAddress.company && (
                  <p>{order.billingAddress.company}</p>
                )}
                <p>{order.billingAddress.address}</p>
                {order.billingAddress.apartment && (
                  <p>{order.billingAddress.apartment}</p>
                )}
                <p>
                  {order.billingAddress.city}, {order.billingAddress.state} {order.billingAddress.postalCode}
                </p>
                <p>{order.billingAddress.country}</p>
                <p>{order.billingAddress.phone}</p>
              </address>
            </div>
          )}
        </div>

        {/* Order Items */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium mb-4">Order Items</h2>
          <ul className="divide-y divide-gray-200">
            {order.orderItems.map((item) => (
              <li key={item._id} className="py-4 flex">
                <img
                  src={item.image}
                  alt={item.name}
                  className="h-20 w-20 rounded-md object-cover"
                />
                <div className="ml-6 flex-1">
                  <div className="flex items-center justify-between">
                    <div>
                      <h4 className="font-medium">{item.name}</h4>
                      <p className="mt-1 text-sm text-gray-500">Quantity: {item.qty}</p>
                      {item.grind && (
                        <p className="mt-1 text-sm text-gray-500">Grind Type: {item.grind}</p>
                      )}
                    </div>
                    <p className="font-medium">${addDecimals(item.price * item.qty)}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Order Summary */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium mb-4">Order Summary</h2>
          <dl className="space-y-4">
            <div className="flex justify-between">
              <dt className="text-gray-600">Subtotal</dt>
              <dd className="font-medium">${addDecimals(order.itemsPrice)}</dd>
            </div>
            <div className="flex justify-between">
              <dt className="text-gray-600">Shipping</dt>
              <dd className="font-medium">${addDecimals(order.shippingPrice)}</dd>
            </div>
            <div className="flex justify-between">
              <dt className="text-gray-600">Tax</dt>
              <dd className="font-medium">${addDecimals(order.taxPrice)}</dd>
            </div>
            <div className="flex justify-between border-t pt-4">
              <dt className="font-medium">Total</dt>
              <dd className="font-medium">${addDecimals(order.totalPrice)}</dd>
            </div>
          </dl>
        </div>

        {/* Payment Information */}
        {order.isPaid && order.paymentResult && (
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-lg font-medium mb-4">Payment Information</h2>
            <dl className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <dt className="text-gray-600">Payment Method</dt>
                <dd className="font-medium">{order.paymentMethod}</dd>
              </div>
              <div>
                <dt className="text-gray-600">Transaction ID</dt>
                <dd className="font-medium">{order.paymentResult.id}</dd>
              </div>
              <div>
                <dt className="text-gray-600">Status</dt>
                <dd className="font-medium">{order.paymentResult.status}</dd>
              </div>
              {order.paymentResult.payer?.email_address && (
                <div>
                  <dt className="text-gray-600">Payer Email</dt>
                  <dd className="font-medium">{order.paymentResult.payer.email_address}</dd>
                </div>
              )}
            </dl>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderAdminDetailsScreen;