import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateLocationMutation } from '../../slices/locationsApiSlice';
import ToucanLoader from '../../components/ToucanLoader';

const NewLocationScreen = () => {
  const navigate = useNavigate();
  const [createLocation, { isLoading }] = useCreateLocationMutation();

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    latitude: '',
    longitude: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await createLocation({
        name: formData.name,
        address: formData.address,
        position: {
          lat: Number(formData.latitude),
          lng: Number(formData.longitude)
        }
      }).unwrap();
      
      toast.success('Location created successfully');
      navigate('/admin/locations/page/1');
    } catch (err) {
      toast.error('Unable to create location. Please check all fields and try again.');
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6">Create New Location</h1>

      <form onSubmit={handleSubmit} className="max-w-md space-y-6">
        <div className="relative">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Location Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            placeholder="Enter location name"
          />
        </div>

        <div className="relative">
          <label htmlFor="address" className="block text-sm font-medium text-gray-700">
            Address
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            required
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            placeholder="Enter full address"
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="relative">
            <label htmlFor="latitude" className="block text-sm font-medium text-gray-700">
              Latitude
            </label>
            <input
              type="number"
              id="latitude"
              name="latitude"
              value={formData.latitude}
              onChange={handleInputChange}
              required
              step="any"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
              placeholder="Enter latitude"
            />
          </div>

          <div className="relative">
            <label htmlFor="longitude" className="block text-sm font-medium text-gray-700">
              Longitude
            </label>
            <input
              type="number"
              id="longitude"
              name="longitude"
              value={formData.longitude}
              onChange={handleInputChange}
              required
              step="any"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
              placeholder="Enter longitude"
            />
          </div>
        </div>

        <div className="flex space-x-4">
          <button
            type="submit"
            disabled={isLoading}
            className="flex-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            {isLoading ? <ToucanLoader small /> : 'Create Location'}
          </button>
          <button
            type="button"
            onClick={() => navigate('/admin/locations/page/1')}
            className="flex-1 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            Cancel
          </button>
        </div>
      </form>

      {isLoading && <ToucanLoader />}
    </div>
  );
};

export default NewLocationScreen;