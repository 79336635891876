import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-gray-100 w-full">
      <div className="mx-auto px-6 py-12">
        <div className="flex flex-col items-center text-center space-y-1">
          <p className="text-sm/6 text-gray-600">
            &copy; {currentYear} Fella Australia Pty Ltd t/a Terry's Coffee. All rights reserved.
          </p>
          <div className="flex gap-6">
            <Link to="/terms" className="text-sm/6 text-gray-600 hover:text-gray-900">
              Terms of Use
            </Link>
            <Link to="/privacy" className="text-sm/6 text-gray-600 hover:text-gray-900">
              Privacy Statement
            </Link>
            <Link to="/refunds" className="text-sm/6 text-gray-600 hover:text-gray-900">
              Refunds Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer