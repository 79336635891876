import React, { useState, useRef } from 'react';
import { useCreateCommercialRequestMutation } from '../slices/commercialAccountRequestApiSlice';
import { useGetMeQuery } from '../slices/usersApiSlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../slices/authSlice';
import ReCAPTCHA from 'react-google-recaptcha';

const CommercialAccountRequestForm = ({ isPublicForm = false }) => {
  const [formData, setFormData] = useState({
    businessName: '',
    businessType: '',
    expectedOrderVolume: '',
    businessAddress: {
      street: '',
      city: '',
      state: '',
      postalCode: '',
      country: ''
    },
    phoneNumber: '',
    additionalNotes: '',
    // Additional fields for public form
    name: '',
    email: ''
  });

  const dispatch = useDispatch();
  const [createRequest, { isLoading }] = useCreateCommercialRequestMutation();
  const { refetch: refetchUser } = useGetMeQuery();

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate reCAPTCHA
    if (!recaptchaValue) {
      toast.error('Please complete the reCAPTCHA verification');
      return;
    }
    
    // Include reCAPTCHA token in your form data
    const requestData = {
      ...formData,
      publicInquiry: isPublicForm,
      recaptchaToken: recaptchaValue
    };

    // If it's a public form, add name and email to the main request data
    if (isPublicForm) {
      requestData.name = formData.name;
      requestData.email = formData.email;
    }

    try {
      await createRequest(requestData).unwrap();
      
      if (!isPublicForm) {
        const userData = await refetchUser();
        if (userData?.data?.data?.user) {
          dispatch(setCredentials(userData.data.data.user));
        }
        toast.success('Commercial account request submitted successfully!');
      } else {
        toast.success('Thank you for your interest! We will be in touch soon.');
      }
      
      // Clear form
      setFormData({
        businessName: '',
        businessType: '',
        expectedOrderVolume: '',
        businessAddress: {
          street: '',
          city: '',
          state: '',
          postalCode: '',
          country: ''
        },
        phoneNumber: '',
        additionalNotes: '',
        name: '',
        email: ''
      });

      // Reset reCAPTCHA after submission
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setRecaptchaValue(null);
    } catch (err) {
      toast.error('Failed to submit request');
    }
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        {isPublicForm && (
          <>
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Your Name</label>
              <input
                type="text"
                name="name"
                id="name"
                required
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-coffee-600 focus:ring-coffee-600 sm:text-sm"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
              <input
                type="email"
                name="email"
                id="email"
                required
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-coffee-600 focus:ring-coffee-600 sm:text-sm"
              />
            </div>
          </>
        )}

        <div>
          <label htmlFor="businessName" className="block text-sm font-medium text-gray-700">Business Name</label>
          <input
            type="text"
            name="businessName"
            id="businessName"
            required
            value={formData.businessName}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-coffee-600 focus:ring-coffee-600 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="businessType" className="block text-sm font-medium text-gray-700">Business Type</label>
          <select
            name="businessType"
            id="businessType"
            required
            value={formData.businessType}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-coffee-600 focus:ring-coffee-600 sm:text-sm"
          >
            <option value="">Select a business type</option>
            <option value="Cafe">Cafe</option>
            <option value="Restaurant">Restaurant</option>
            <option value="Hotel">Hotel</option>
            <option value="Office">Office</option>
            <option value="Retail">Retail Store</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div>
          <label htmlFor="expectedOrderVolume" className="block text-sm font-medium text-gray-700">Expected Monthly Order Volume</label>
          <select
            name="expectedOrderVolume"
            id="expectedOrderVolume"
            required
            value={formData.expectedOrderVolume}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-coffee-600 focus:ring-coffee-600 sm:text-sm"
          >
            <option value="">Select expected volume</option>
            <option value="0-100">0-10 kg</option>
            <option value="101-500">10-30 kg</option>
            <option value="501-1000">30-50 kg</option>
            <option value="1000+">50+ kg</option>
          </select>
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label htmlFor="businessAddress.street" className="block text-sm font-medium text-gray-700">Street Address</label>
            <input
              type="text"
              name="businessAddress.street"
              required
              value={formData.businessAddress.street}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-coffee-600 focus:ring-coffee-600 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="businessAddress.city" className="block text-sm font-medium text-gray-700">City</label>
            <input
              type="text"
              name="businessAddress.city"
              required
              value={formData.businessAddress.city}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-coffee-600 focus:ring-coffee-600 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="businessAddress.state" className="block text-sm font-medium text-gray-700">State</label>
            <input
              type="text"
              name="businessAddress.state"
              required
              value={formData.businessAddress.state}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-coffee-600 focus:ring-coffee-600 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="businessAddress.postalCode" className="block text-sm font-medium text-gray-700">Postal Code</label>
            <input
              type="text"
              name="businessAddress.postalCode"
              required
              value={formData.businessAddress.postalCode}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-coffee-600 focus:ring-coffee-600 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="businessAddress.country" className="block text-sm font-medium text-gray-700">Country</label>
            <input
              type="text"
              name="businessAddress.country"
              required
              value={formData.businessAddress.country}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-coffee-600 focus:ring-coffee-600 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Phone Number</label>
            <input
              type="tel"
              name="phoneNumber"
              required
              value={formData.phoneNumber}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-coffee-600 focus:ring-coffee-600 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <label htmlFor="additionalNotes" className="block text-sm font-medium text-gray-700">Additional Notes</label>
          <textarea
            name="additionalNotes"
            rows={4}
            value={formData.additionalNotes}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-coffee-600 focus:ring-coffee-600 sm:text-sm"
            placeholder="Tell us more about your business and how you plan to use our products..."
          />
        </div>

        {/* Add reCAPTCHA before the submit button */}
        <div className="flex justify-center my-4">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleRecaptchaChange}
          />
        </div>
        
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isLoading || !recaptchaValue}
            className="w-48 rounded-xl border border-transparent bg-amber-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 disabled:opacity-50"
          >
            {isLoading ? 'Submitting...' : 'Submit Request'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CommercialAccountRequestForm; 