import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

const CommercialAccountPendingMessage = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-16">
      <div className="rounded-lg bg-white p-6 shadow">
        <div className="text-center">
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-amber-100">
            <CheckCircleIcon className="h-6 w-6 text-amber-600" aria-hidden="true" />
          </div>
          <h2 className="mt-4 text-3xl font-bold text-gray-900">Thank You for Your Interest!</h2>
          <div className="mt-6 space-y-6 text-gray-600">
            <p className="text-lg">
              Your commercial account request has been received and is currently under review.
            </p>
            <div className="prose prose-amber mx-auto">
              <h3 className="text-lg font-medium text-gray-900">What happens next?</h3>
              <ul className="mt-4 list-none space-y-4 pl-0">
                <li className="flex items-start">
                  <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-amber-100 text-sm font-medium text-amber-600">
                    1
                  </span>
                  <span className="ml-3">
                    A Terry's Coffee representative will review your application within 2-3 business days.
                  </span>
                </li>
                <li className="flex items-start">
                  <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-amber-100 text-sm font-medium text-amber-600">
                    2
                  </span>
                  <span className="ml-3">
                    We'll reach out to discuss your business needs and how we can best serve you.
                  </span>
                </li>
                <li className="flex items-start">
                  <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-amber-100 text-sm font-medium text-amber-600">
                    3
                  </span>
                  <span className="ml-3">
                    Once approved, you'll receive immediate access to our commercial product catalog and pricing.
                  </span>
                </li>
              </ul>
            </div>
            <p className="text-sm text-gray-500">
              If you have any questions in the meantime, please don't hesitate to contact our support team.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommercialAccountPendingMessage; 