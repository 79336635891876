import { createSlice } from '@reduxjs/toolkit'
import {updateCart} from '../utils/cartUtils'

const initialState = {
  cartItems: localStorage.getItem('cart') 
    ? JSON.parse(localStorage.getItem('cart')) 
    : [],
  shippingAddress: {},
  paymentMethod: 'paypal'
}  


const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload
      const existItem = state.cartItems.find(x => x._id === item._id)
      if (existItem) {
        state.cartItems = state.cartItems.map(x => 
          x._id === existItem._id 
            ? { 
                ...x, 
                qty: item.qty,
                product: item._id, 
                _id: item._id
              }
            : x
        )
      } else {
        const newItem = {
          ...item,
          product: item._id, 
          _id: item._id
        }
        state.cartItems = [...state.cartItems, newItem]
      } 
      updateCart(state)
    },
    removeFromCart: (state, action) => {
      const id = action.payload
      state.cartItems = state.cartItems.filter(x => x._id !== id)
      updateCart(state)
    },
    saveShippingAddress: (state, action) => {
      state.shippingAddress = action.payload
      return updateCart(state);
    },
    savePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload
      return updateCart(state)
    },
    clearCartItems: (state, action) => {
      state.cartItems = []
      return updateCart(state)
    },
    resetCart: (state) => {
      state.cartItems = []
      localStorage.setItem('cart', JSON.stringify(state.cartItems))
    },
    addMultipleToCart: (state, action) => {
      const item = action.payload
      state.cartItems.push(item)
      return updateCart(state)
    },
  },
})

export const { addToCart, removeFromCart, saveShippingAddress, clearCartItems, savePaymentMethod, resetCart, addMultipleToCart } = cartSlice.actions

export default cartSlice.reducer
