import { AUTH_URL } from "../constants";
import { USERS_URL, UPLOAD_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // PUBLIC ENDPOINTS
    login: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/login`,
        method: 'POST',
        body: data,
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/register`,
        method: 'POST',
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${AUTH_URL}/logout`,  
        method: 'POST',
      }),
    }),
    verifyGoogleAuth: builder.query({
      query: (token) => ({
        url: `${USERS_URL}/me`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/forgotPassword`,
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({token, data}) => ({
        url: `${AUTH_URL}/resetPassword/${token}`,
        method: 'PATCH',
        body: data,
      }),
    }), 
    // PRIVATE ENDPOINTS
    getMe: builder.query({
      query: () => ({
        url: `${USERS_URL}/me`,
        method: 'GET',
      }),
    }),
    updateMe: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/updateMe`,
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteMe: builder.mutation({
      query: () => ({
        url: `${USERS_URL}/deleteMe`,
        method: 'DELETE',
      }),
    }),
    updateMyPassword: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/updateMyPassword`,
        method: 'PATCH',
        body: data,
        credentials: 'include'
      }),
    }),
    // ADMIN ENDPOINTS
    getUsers: builder.query({
      query: ({pageNumber, keyword} = {}) => ({
        url: `${USERS_URL}`,
        params: pageNumber ? { pageNumber, keyword } : undefined,
        method: 'GET',
      }),
      providesTags: ['Users'],
      keepUnusedDataFor: 5,
    }),
    getCommercialUsers: builder.query({
      query: () => ({
        url: `${USERS_URL}/commercial`,
        method: 'GET',
      }),
      providesTags: ['Users'],
      keepUnusedDataFor: 5,
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/${data.userId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Users'],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `${USERS_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    // Add this mutation
    uploadUserImage: builder.mutation({
      query: (data) => ({
        url: UPLOAD_URL,
        method: 'POST',
        body: data,
        prepareHeaders: (headers) => {
          headers.delete('Content-Type');
          headers.set('Accept', 'application/json');
          return headers;
        },
      }),
      invalidatesTags: ['Users'],
    }),
    removeUserPhoto: builder.mutation({
      query: () => ({
        url: `${USERS_URL}/removePhoto`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    // Add createUser mutation
    createUser: builder.mutation({
      query: (data) => ({
        url: USERS_URL,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const { 
  useLoginMutation, useRegisterMutation, useLogoutMutation, useVerifyGoogleAuthQuery, useForgotPasswordMutation, useResetPasswordMutation, useGetMeQuery, useUpdateMeMutation, useDeleteMeMutation, useUpdateMyPasswordMutation, useGetUsersQuery, useUpdateUserMutation, useDeleteUserMutation, useUploadUserImageMutation, useRemoveUserPhotoMutation, useGetCommercialUsersQuery, useCreateUserMutation } = usersApiSlice;