import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {RouterProvider, createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom';
import HomeScreen from './screens/nav/HomeScreen';
import ProductScreen from './screens/retail/ProductScreen';
import store from './store';
import { Provider } from 'react-redux';

import CartScreen from './screens/retail/CartScreen';
import LoginScreen from './screens/user/LoginScreen';
import RegisterScreen from './screens/user/RegisterScreen';
import CheckoutScreen from './screens/retail/CheckoutScreen';
import CommercialCheckoutScreen from './screens/commercial/CommercialCheckoutScreen';
import FindUs from './screens/nav/FindUs';
import TermsScreen from './screens/legal/TermsScreen';
import PrivacyScreen from './screens/legal/PrivacyScreen';

import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import CommercialAccountRequestForm from './components/CommercialAccountRequestForm';

import OrderSummary from './screens/retail/OrderSummary';
import ProfileScreen from './screens/user/ProfileScreen';
import OrderManagementScreen from './screens/admin/OrderManagementScreen';
import CommercialOrderManagementScreen from './screens/admin/CommercialOrderManagementScreen';
import ShopScreen from './screens/retail/ShopScreen';
import CommercialShopScreen from './screens/commercial/CommercialShopScreen';
import UserManagementScreen from './screens/admin/UserManagementScreen';
import ProductManagementScreen from './screens/admin/ProductManagementScreen';
import CommercialProductManagementScreen from './screens/admin/CommercialProductManagementScreen';
import CommercialOrderAdminDetailsScreen from './screens/admin/CommercialOrderAdminDetailsScreen';
import NewCommercialProductScreen from './screens/admin/NewCommercialProductScreen';
import EditCommercialProductScreen from './screens/admin/EditCommercialProductScreen';
import NewProductScreen from './screens/admin/NewProductScreen';
import EditProductScreen from './screens/admin/EditProductScreen';
import ContactScreen from './screens/nav/ContactScreen';
import LocationManagementScreen from './screens/admin/LocationManagementScreen';
import NewLocationScreen from './screens/admin/NewLocationScreen';
import EditLocationScreen from './screens/admin/EditLocationScreen';
import RefundsPolicyScreen from './screens/legal/RefundsPolicyScreen';

import ForgotPassword from './screens/user/ForgotPassword';
import OrderAdminDetailsScreen from './screens/admin/OrderAdminDetailsScreen';
import ResetPassword from './screens/user/ResetPassword';
import CommercialOrderSummary from './screens/commercial/CommercialOrderSummary';
import AuthSuccess from './components/AuthSuccess';
import QuickBooksScreen from './screens/admin/QuickBooksScreen';
import CommercialPricingManagementScreen from './screens/admin/CommercialPricingManagementScreen';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      {/* Admin Routes - Most specific, should come first */}
      <Route element={<AdminRoute />}>
        {/* QuickBooks Routes */}
        <Route path='/admin/quickbooks' element={<QuickBooksScreen />} />
      </Route>

      {/* Commercial Order Management */}
      <Route path='/admin/commercialOrders/page/:pageNumber' element={<CommercialOrderManagementScreen />} />
      <Route path='/admin/commercialOrders' element={<CommercialOrderManagementScreen />} />
      <Route path='/admin/commercialOrder/:id' element={<CommercialOrderAdminDetailsScreen />} />

      {/* Order Management */}
      <Route path='/admin/orders/page/:pageNumber' element={<OrderManagementScreen />} />
      <Route path='/admin/orders' element={<OrderManagementScreen />} />
      <Route path='/admin/order/:id' element={<OrderAdminDetailsScreen />} />

      {/* User Management */}
      <Route path='/admin/users/page/:pageNumber' element={<UserManagementScreen />} />
      <Route path='/admin/users' element={<UserManagementScreen />} />

      {/* Product Management */}
      <Route path='/admin/products/page/:pageNumber' element={<ProductManagementScreen />} />
      <Route path='/admin/products' element={<ProductManagementScreen />} />
      <Route path='/admin/product/new' element={<NewProductScreen />} />
      <Route path='/admin/product/:id/edit' element={<EditProductScreen />} />

      {/* Commercial Product Management */}
      <Route path='/admin/commercialProducts/page/:pageNumber' element={<CommercialProductManagementScreen />} />
      <Route path='/admin/commercialProducts' element={<CommercialProductManagementScreen />} />
      <Route path='/admin/commercialProduct/new' element={<NewCommercialProductScreen />} />
      <Route path='/admin/commercialProduct/:id/edit' element={<EditCommercialProductScreen />} />

      {/* Location Management */}
      <Route path='/admin/locations/page/:pageNumber' element={<LocationManagementScreen />} />
      <Route path='/admin/locations' element={<LocationManagementScreen />} />
      <Route path='/admin/location/new' element={<NewLocationScreen />} />
      <Route path='/admin/location/:id/edit' element={<EditLocationScreen />} />

      {/* Commercial Pricing Management */}
      <Route path='/admin/commercial-pricing/page/:pageNumber' element={<CommercialPricingManagementScreen />} />
      <Route path="/admin/commercial-pricing" element={<CommercialPricingManagementScreen />} />

      {/* Protected Routes */}
      <Route element={<PrivateRoute />}>
        <Route path='/commercialCheckout' element={<CommercialCheckoutScreen />} />
        <Route path='/commercialOrder/:id' element={<CommercialOrderSummary />} />
        <Route path='/profile' element={<ProfileScreen />} />
        <Route path='/order/:id' element={<OrderSummary />} />
      </Route>

      {/* Public Routes */}
      <Route path='/' element={<HomeScreen />} />
      <Route path='/checkout' element={<CheckoutScreen />} />

      {/* Shop Routes */}
      <Route path='/shop/page/:pageNumber' element={<ShopScreen />} />
      <Route path='/shop' element={<ShopScreen />} />
      <Route path='/product/:id' element={<ProductScreen />} />

      {/* Commercial Shop Routes */}
      <Route path='/shop/commercial/page/:pageNumber' element={<CommercialShopScreen />} />
      <Route path='/shop/commercial' element={<CommercialShopScreen />} />
      <Route 
        path='/commercial/quote-request' 
        element={
          <div className="max-w-4xl mx-auto px-4 py-8">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-gray-900">Request a Commercial Quote</h2>
              <p className="mt-4 text-lg text-gray-600">
                Interested in Terry's Coffee for your cafe, restaurant or business? Get in touch with us to discuss your coffee needs. We'll help you set up a commercial account.
              </p>
            </div>
            <CommercialAccountRequestForm isPublicForm={true} />
          </div>
        } 
      />

      {/* Authentication Routes */}
      <Route path='/login' element={<LoginScreen />} />
      <Route path='/register' element={<RegisterScreen />} />
      <Route path='/forgotPassword' element={<ForgotPassword />} />
      <Route path='/resetPassword/:token' element={<ResetPassword />} />
      <Route path='/auth/success' element={<AuthSuccess />} />

      {/* Contact Us Routes */}
      <Route path='/contact' element={<ContactScreen />} />

      {/* Find Us Routes */}
      <Route path='/findus' element={<FindUs />} />

      {/* Cart Routes */}
      <Route path='/cart' element={<CartScreen />} />

      {/* Terms and Privacy Routes */}
      <Route path="/terms" element={<TermsScreen />} />
      <Route path="/privacy" element={<PrivacyScreen />} />
      <Route path="/refunds" element={<RefundsPolicyScreen />} />
    </Route>
  )
) 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

