import React from 'react';

const TermsScreen = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms of Use</h1>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Copyright</h2>
        <p className="mb-4">
          Unless otherwise indicated, copyright in this website and the information contained in it, including any designs, text, graphics, content, tables and images belong to Terrys Coffee Roasting Company.com.au (TERRY'S COFFEE) All rights reserved. Any use of the materials on this website, including reproduction, modification, distribution or republication, in whole or part, in any form or medium, without permission of TERRY'S COFFEE ROASTING is prohibited.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">No Warranties</h2>
        <p className="mb-4">
          While every care has been taken in preparing the information and materials contained in this site, such information and materials are provided to you "AS IS" without warranty of any kind either express or implied. In particular, no warranty regarding non-infringement, security, accuracy, fitness for a particular purpose or freedom from computer virus is given in conjunction with such information and materials.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Hyperlinks</h2>
        <div className="space-y-4">
          <p>
            There may be cases on our website when TERRY'S COFFEE ROASTING provides hyperlinks to other locations or websites on the Internet. These hyperlinks lead to websites published or operated by third parties who are not affiliated with or in any way related to TERRY'S COFFEE ROASTING. They have been included in our website to enhance your user experience and are presented for information purposes only. We endeavour to select reputable websites and sources of information for your convenience.
          </p>
          <p>
            However, by providing hyperlinks to an external website or webpage, TERRY'S COFFEE ROASTING shall not be deemed to endorse, recommend, approve, guarantee or introduce any third parties or the services/ products they provide on their websites, or to have any form of cooperation with such third parties and websites unless otherwise stated by TERRY'S COFFEE ROASTING.
          </p>
          <p>
            We are not in any way responsible for the content of any externally linked website or webpage. You use or follow these links at your own risk and TERRY'S COFFEE ROASTING is not responsible for any damages or losses incurred or suffered by you arising out of or in connection with your use of the link.
          </p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Hyperlinks from External Websites</h2>
        <p className="mb-4">
          TERRY'S COFFEE ROASTING is not responsible for the setup of any hyperlink from a third party website to TERRY'S COFFEE ROASTING website. Any links so set up shall not constitute any form of co-operation with, or endorsement by, TERRY'S COFFEE ROASTING of such third party website. Any link to our website shall always be an active and direct link to our website and must be done in such a way that the TERRY'S COFFEE ROASTING website appears in a full browser "window" or "tab".
        </p>
      </section>

      <div className="mt-8 p-4 bg-gray-100 rounded text-center">
        <p className="font-medium">
          NOTE: By accessing this website and any of its pages you are agreeing to the terms set out above.
        </p>
      </div>
    </div>
  );
};

export default TermsScreen; 