import { apiSlice } from './apiSlice';
import { QUICKBOOKS_URL } from '../constants';

export const quickBooksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Auth Endpoints
    getAuthUri: builder.mutation({
      query: () => ({
        url: `${QUICKBOOKS_URL}/oauth/uri`,
        method: 'GET',
      }),
    }),
    createToken: builder.mutation({
      query: (callbackUrl) => ({
        url: `${QUICKBOOKS_URL}/oauth/callback${callbackUrl}`,
        method: 'GET',
      }),
    }),
    validateToken: builder.query({
      query: () => ({
        url: `${QUICKBOOKS_URL}/oauth/validate`,
      }),
      keepUnusedDataFor: 5,
    }),
    revokeToken: builder.mutation({
      query: () => ({
        url: `${QUICKBOOKS_URL}/oauth/revoke`,
        method: 'POST',
      }),
    }),

    // Item Endpoints
    createItem: builder.mutation({
      query: (data) => ({
        url: `${QUICKBOOKS_URL}/items`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Item'],
    }),
    updateItem: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${QUICKBOOKS_URL}/items/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    getItemByName: builder.query({
      query: (name) => ({
        url: `${QUICKBOOKS_URL}/items/${name}`,
      }),
      keepUnusedDataFor: 5,
    }),

    // Customer Endpoints
    createCustomer: builder.mutation({
      query: (data) => ({
        url: `${QUICKBOOKS_URL}/customers`,
        method: 'POST',
        body: data,
      }),
    }),
    updateCustomer: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${QUICKBOOKS_URL}/customers/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),

    // Invoice Endpoints
    createInvoice: builder.mutation({
      query: (data) => ({
        url: `${QUICKBOOKS_URL}/invoices`,
        method: 'POST',
        body: data,
      }),
    }),
    createInvoiceFromOrder: builder.mutation({
      query: (data) => ({
        url: `${QUICKBOOKS_URL}/invoices/fromOrder`,
        method: 'POST',
        body: data,
      }),
    }),
    updateInvoice: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${QUICKBOOKS_URL}/invoices/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    getInvoice: builder.query({
      query: (id) => ({
        url: `${QUICKBOOKS_URL}/invoices/${id}`,
      }),
      keepUnusedDataFor: 5,
    }),
    deleteInvoice: builder.mutation({
      query: ({ id, syncToken }) => ({
        url: `${QUICKBOOKS_URL}/invoices/${id}/${syncToken}`,
        method: 'DELETE',
      }),
    }),

    // Account Endpoints
    getAccounts: builder.query({
      query: () => ({
        url: `${QUICKBOOKS_URL}/accounts`,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  // Auth hooks
  useGetAuthUriMutation,
  useCreateTokenMutation,
  useValidateTokenQuery,
  useRevokeTokenMutation,
  // Item hooks
  useCreateItemMutation,
  useUpdateItemMutation,
  useGetItemByNameQuery,
  // Customer hooks
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  // Invoice hooks
  useCreateInvoiceMutation,
  useCreateInvoiceFromOrderMutation,
  useUpdateInvoiceMutation,
  useGetInvoiceQuery,
  useDeleteInvoiceMutation,
  // Account hooks
  useGetAccountsQuery,
} = quickBooksApiSlice; 