import React from 'react'
import SideBar from './components/SideBar'
import Footer from './components/Footer'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const App = () => {
  // Define default Stripe appearance
  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#d97706', // amber-600
      colorBackground: '#ffffff',
      colorText: '#1f2937',
    },
  };

  return (
    <Elements stripe={stripePromise} options={{ appearance }}>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <SideBar />
        <div className="lg:pl-72 flex flex-col flex-grow">
          <main className="py-10 flex-grow">
            <div className="px-4 sm:px-6 lg:px-8">
              <Outlet />
            </div>
          </main>
          <Footer />
          <ToastContainer />
        </div>
      </div>
    </Elements>
  )
}

export default App