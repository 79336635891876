import React, { useState } from 'react'
import FormattedPrice from './FormattedPrice'
import { useDispatch } from 'react-redux'
import { addToCart } from '../slices/cartSlice'
import { toast } from 'react-toastify'

const CommercialProduct = ({ product }) => {
  const [qty, setQty] = useState(1)
  const dispatch = useDispatch()

  const handleQtyChange = (e) => {
    const value = parseInt(e.target.value)
    if (value > 0) {
      setQty(value)
    } else if (e.target.value === '') {
      setQty('')
    }
  }

  const handleAddToCart = () => {
    if (!qty || qty < 1) {
      toast.error('Please enter a valid quantity')
      return
    }
    dispatch(addToCart({ ...product, qty, isCommercial: true }))
    toast.success('Added to cart')
    setQty(1)
  }

  return (
    <div className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
      <img
        alt={product.name}
        src={product.image}
        className="w-full bg-gray-200 object-contain sm:object-cover group-hover:opacity-75 sm:aspect-auto sm:h-96 h-48"
      />
      <div className="flex flex-1 flex-col space-y-2 p-4">
        <h3 className="text-sm font-medium text-gray-900">
          {product.name}
        </h3>
          {/* Prices */}
        <div className="flex flex-1 flex-col justify-end space-y-3">
          <p className="text-base font-medium text-gray-900">
            <FormattedPrice price={product.customPrice || product.price} />
          </p>

          {/* Quantity Input */}
          <div className="flex flex-col space-y-2">
            <input
              type="number"
              min="1"
              value={qty}
              onChange={handleQtyChange}
              className="w-full rounded-md border border-gray-300 py-1.5 px-2 text-base font-medium text-gray-700 shadow-sm focus:border-amber-500 focus:outline-none focus:ring-1 focus:ring-amber-500 sm:text-sm"
              placeholder="Enter quantity"
            />
          </div>

          {/* Add to Cart Button */}
          <button
            type="button"
            onClick={handleAddToCart}
            className="w-full rounded-md border border-transparent bg-amber-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2"
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  )
}

export default CommercialProduct 