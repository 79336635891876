import { COMMERCIAL_PRODUCTS_URL, BASE_URL, COMMERCIAL_UPLOAD_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const commercialProductsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCommercialProducts: builder.query({
      query: ({pageNumber, keyword, tag, pageSize} = {}) => ({
        url: `${COMMERCIAL_PRODUCTS_URL}`,
        params: { pageNumber, keyword, tag, pageSize },
      }),
      transformResponse: (response) => {
        return {
          ...response,
          data: {
            commercialProducts: response.data.commercialProducts.map(product => ({
              ...product,
              image: `${BASE_URL}${product.image}`
            }))
          }
        }
      },
      keepUnusedDataFor: 5,
      providesTags: ['CommercialProduct'],
    }),
    getCommercialProductDetails: builder.query({
      query: (commercialProductId) => ({
        url: `${COMMERCIAL_PRODUCTS_URL}/${commercialProductId}`,
      }),
      transformResponse: (response) => {
        return {
          ...response,
          image: `${BASE_URL}${response.image}`
        }
      },
      keepUnusedDataFor: 5,
      providesTags: ['CommercialProduct'],
    }),
    // Admin Routes
    createCommercialProduct: builder.mutation({
      query: (product) => ({
        url: `${COMMERCIAL_PRODUCTS_URL}/new`,
        method: 'POST',
        body: product,
      }),
      invalidatesTags: ['CommercialProduct'],
    }),
    updateCommercialProduct: builder.mutation({
      query: (product) => ({
        url: `${COMMERCIAL_PRODUCTS_URL}/${product._id}`,
        method: 'PATCH',
        body: product,
      }),
      invalidatesTags: ['CommercialProduct'],
    }),
    uploadCommercialProductImage: builder.mutation({
      query: (data) => ({
        url: COMMERCIAL_UPLOAD_URL,
        method: 'POST',
        body: data,
        prepareHeaders: (headers) => {
          headers.delete('Content-Type');
          headers.set('Accept', 'application/json');
          return headers;
        },
      }),
      responseHandler: async (response) => {
        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || 'Upload failed');
        }
        return response.json();
      },
      invalidatesTags: ['CommercialProduct'],
    }), 
    deleteCommercialProduct: builder.mutation({
      query: (commercialProductId) => ({
        url: `${COMMERCIAL_PRODUCTS_URL}/${commercialProductId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CommercialProduct'],
    }),
  }),
});



export const {
  useGetCommercialProductsQuery,
  useGetCommercialProductDetailsQuery,
  useCreateCommercialProductMutation,
  useUpdateCommercialProductMutation,
  useUploadCommercialProductImageMutation,
  useDeleteCommercialProductMutation,
} = commercialProductsApiSlice;
