import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';

const ROUTE_CONFIGS = {
  // Admin routes
  '/admin/commercialOrders': {
    base: (path) => path.replace(/\?.*$/, ''),
    search: (keyword) => `/admin/commercialOrders/page/1?keyword=${keyword}`
  },
  '/admin/orders': {
    base: (path) => path.replace(/\?.*$/, ''),
    search: (keyword) => `/admin/orders/page/1?keyword=${keyword}`
  },
  '/admin/users': {
    base: (path) => path.replace(/\?.*$/, ''),
    search: (keyword) => `/admin/users/page/1?keyword=${keyword}`
  },
  '/admin/commercial-pricing': {
    base: (path) => path.replace(/\?.*$/, ''),
    search: (keyword) => `/admin/commercial-pricing/page/1?keyword=${keyword}`
  },
  '/admin/commercialProducts': {
    base: (path) => path.replace(/\?.*$/, ''),
    search: (keyword) => `/admin/commercialProducts/page/1?keyword=${keyword}`
  },
  '/admin/products': {
    base: (path) => path.replace(/\?.*$/, ''),
    search: (keyword) => `/admin/products/page/1?keyword=${keyword}`
  },
  '/admin/locations': {
    base: (path) => path.replace(/\?.*$/, ''),
    search: (keyword) => `/admin/locations/page/1?keyword=${keyword}`
  },
  // Shop routes
  '/shop/commercial': {
    base: (path) => path.replace(/\?.*$/, ''),
    search: (keyword) => `/shop/commercial/page/1?keyword=${keyword}`
  },
  '/shop': {
    base: (path) => path.replace(/\?.*$/, ''),
    search: (keyword) => `/shop/page/1?keyword=${keyword}`
  }
};

const SearchBox = () => {
  const navigate = useNavigate();
  const { keyword } = useParams();
  const location = useLocation();
  const [searchKeyword, setSearchKeyword] = useState(keyword || '');

  // Update search term when URL changes
  useEffect(() => {
    setSearchKeyword(keyword || '');
  }, [keyword]);

  // Find the matching route configuration
  const getRouteConfig = useCallback((path) => {
    // Sort routes by length (descending) to match most specific routes first
    const routes = Object.keys(ROUTE_CONFIGS).sort((a, b) => b.length - a.length);
    return routes.find(route => path.includes(route));
  }, []);

  // Handle navigation
  const handleNavigation = useCallback((trimmedKeyword) => {
    const path = location.pathname;
    const matchedRoute = getRouteConfig(path);
    
    if (matchedRoute) {
      const config = ROUTE_CONFIGS[matchedRoute];
      // When searching, always go to page 1
      // When clearing search, stay on current page
      const targetPath = trimmedKeyword ? config.search(trimmedKeyword) : config.base(path);
      navigate(targetPath);
    }
  }, [location.pathname, navigate, getRouteConfig]);

  // Add debounced search
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchKeyword !== keyword) {
        const trimmedKeyword = searchKeyword.trim();
        handleNavigation(trimmedKeyword);
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [searchKeyword, keyword, handleNavigation]);

  const submitHandler = (e) => {
    e.preventDefault();
    handleNavigation(searchKeyword.trim());
  };

  const clearSearch = () => {
    setSearchKeyword('');
    handleNavigation('');
  };

  return (
    <form onSubmit={submitHandler} method="GET" className="grid w-full grid-cols-1 px-4 lg:px-4 sm:px-6">
      <div className="relative col-start-1 row-start-1">
        <input
          type="search"
          name="q"
          placeholder="Search"
          aria-label="Search"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          className="block h-10 w-full rounded-md border-0 py-1.5 pl-8 pr-8 text-gray-900 ring-0 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 [&::-webkit-search-cancel-button]:appearance-none"
        />
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
          <MagnifyingGlassIcon
            aria-hidden="true"
            className="size-5 text-gray-400"
          />
        </div>
        {searchKeyword && (
          <button
            type="button"
            onClick={clearSearch}
            className="absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <XMarkIcon className="size-5 text-gray-400 hover:text-gray-600" />
          </button>
        )}
      </div>
    </form>
  )
}

export default SearchBox