import React from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom' 

const AdminRoute = () => {
  const { userInfo } = useSelector((state) => state.auth)

  if (!userInfo) {
    return <Navigate to='/login' replace={true} />;
  }

  if (userInfo.role !== 'admin') {
    return <Navigate to='/login' replace={true} />;
  }

  return <Outlet />;
}

export default AdminRoute