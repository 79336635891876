import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useCreateInvoiceFromOrderMutation } from '../slices/quickBooksApiSlice';

const CreateInvoiceModal = ({ order, onClose, show, refetch }) => {
  const [createInvoiceFromOrder, { isLoading }] = useCreateInvoiceFromOrderMutation();
  const [invoiceItems, setInvoiceItems] = useState(() => {
    return order?.orderItems.map(item => ({
      ...item,
      manualPrice: item.customPrice || item.price || 0,
    })) || [];
  });
  const [totalTax, setTotalTax] = useState(order?.taxPrice || 0);
  const [isManualSubtotal, setIsManualSubtotal] = useState(false);
  const [manualSubtotal, setManualSubtotal] = useState(order?.itemsPrice || 0);

  const calculateSubtotal = () => {
    if (isManualSubtotal) {
      return Number(manualSubtotal);
    }
    return invoiceItems.reduce((sum, item) => sum + (item.manualPrice * item.qty), 0);
  };

  const calculateTotal = () => {
    return calculateSubtotal() + Number(totalTax);
  };

  const handlePriceChange = (index, price) => {
    const newItems = [...invoiceItems];
    newItems[index].manualPrice = Number(price);
    setInvoiceItems(newItems);
  };

  const handleTaxChange = (tax) => {
    setTotalTax(Number(tax));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createInvoiceFromOrder({
        orderId: order._id,
        manualPrices: isManualSubtotal 
          ? invoiceItems.map(item => ({
              name: item.name,
              price: Number(manualSubtotal) / invoiceItems.reduce((sum, item) => sum + item.qty, 0)
            }))
          : invoiceItems.map(item => ({
              name: item.name,
              price: item.manualPrice
            })),
        taxAmount: totalTax
      }).unwrap();
      
      toast.success('Invoice created successfully');
      refetch();
      onClose();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">Create Invoice</h2>
        
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Customer Information</h3>
            <div className="grid grid-cols-2 gap-4 bg-gray-50 p-4 rounded">
              <div>
                <p><span className="font-semibold">Company:</span> {order.shippingAddress.company}</p>
                <p><span className="font-semibold">Contact:</span> {order.shippingAddress.firstName} {order.shippingAddress.lastName}</p>
                <p><span className="font-semibold">Phone:</span> {order.shippingAddress.phone}</p>
              </div>
              <div>
                <p><span className="font-semibold">Address:</span> {order.shippingAddress.address}</p>
                <p>{order.shippingAddress.city}, {order.shippingAddress.state} {order.shippingAddress.postalCode}</p>
                <p>{order.shippingAddress.country}</p>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isManualSubtotal}
                onChange={(e) => setIsManualSubtotal(e.target.checked)}
                className="rounded border-gray-300"
              />
              <span>Use single subtotal for all items</span>
            </label>
          </div>

          {!isManualSubtotal && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-2">Items</h3>
              <div className="overflow-x-auto">
                <table className="w-full mb-4">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="p-2 text-left">Item</th>
                      <th className="p-2 text-left">Quantity</th>
                      <th className="p-2 text-left">Unit Price ($)</th>
                      <th className="p-2 text-left">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceItems.map((item, index) => (
                      <tr key={index} className="border-b">
                        <td className="p-2">
                          {item.name}
                          {item.grind && <span className="text-sm text-gray-500 block">Grind: {item.grind}</span>}
                        </td>
                        <td className="p-2">{item.qty}</td>
                        <td className="p-2">
                          <input
                            type="number"
                            step="0.01"
                            min="0"
                            value={item.manualPrice}
                            onChange={(e) => handlePriceChange(index, e.target.value)}
                            className="border rounded p-1 w-24"
                          />
                        </td>
                        <td className="p-2">${(item.manualPrice * item.qty).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className="mb-6">
            <div className="flex justify-end space-x-4 text-right">
              <div>
                {isManualSubtotal ? (
                  <div className="mb-2 flex items-center justify-end space-x-2">
                    <span className="font-semibold">Subtotal:</span>
                    <input
                      type="number"
                      step="0.01"
                      min="0"
                      value={manualSubtotal}
                      onChange={(e) => setManualSubtotal(Number(e.target.value))}
                      className="border rounded p-1 w-24"
                    />
                  </div>
                ) : (
                  <p className="mb-2">
                    <span className="font-semibold">Subtotal:</span> ${calculateSubtotal().toFixed(2)}
                  </p>
                )}
                <div className="mb-2 flex items-center justify-end space-x-2">
                  <span className="font-semibold">Tax:</span>
                  <input
                    type="number"
                    step="0.01"
                    min="0"
                    value={totalTax}
                    onChange={(e) => handleTaxChange(e.target.value)}
                    className="border rounded p-1 w-24"
                  />
                </div>
                <p className="text-lg font-bold">
                  <span>Total:</span> ${calculateTotal().toFixed(2)}
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border rounded hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:bg-blue-300"
            >
              {isLoading ? 'Creating...' : 'Create Invoice'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateInvoiceModal; 