import React, { useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useGetUsersQuery, useDeleteUserMutation, useUpdateUserMutation, useCreateUserMutation } from '../../slices/usersApiSlice';
import { useUpdateCommercialRequestStatusMutation } from '../../slices/commercialAccountRequestApiSlice';
import ToucanLoader from '../../components/ToucanLoader';
import { toast } from 'react-toastify';
import Message from '../../components/Message';
import Modal from '../../components/Modal';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/solid';
import SearchBox from '../../components/SearchBox';
import ConfirmationModal from '../../components/ConfirmationModal';

const UserManagementScreen = () => {
  const { pageNumber } = useParams();
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword') || '';
  
  const { data: { data: { users } = {}, pages } = {}, isLoading, error, refetch } = useGetUsersQuery({ 
    pageNumber: pageNumber || 1,
    keyword
  });
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteUser] = useDeleteUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [updateCommercialStatus] = useUpdateCommercialRequestStatusMutation();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    businessName: '',
    role: 'user',
    isCommercialAccount: true,
    commercialPaymentPlan: 'pay-now',
    password: '',
    passwordConfirm: ''
  });
  const [createUser] = useCreateUserMutation();

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      await deleteUser(selectedUser._id);
      refetch();
      toast.success('User deleted successfully');
      setShowDeleteModal(false);
    } catch (err) {
      toast.error('Unable to delete user. Please try again later.');
    }
  };

  const handleApproveCommercialRequest = async (user) => {
    try {
      // First, update the commercial request status
      await updateCommercialStatus({
        id: user.commercialRequest._id,
        status: 'approved',
        adminNotes: 'Commercial account request approved'
      }).unwrap();

      // Then update the user's commercial status
      await updateUser({
        userId: user._id,
        isCommercialAccount: true,
        commercialAccountRequestPending: false,
        commercialPaymentPlan: user.commercialRequest.requestedPaymentPlan || 'pay-now'
      }).unwrap();

      refetch();
      toast.success('Commercial account request approved successfully');
    } catch (err) {
      toast.error(err?.data?.message || 'Failed to approve commercial account request');
    }
  };

  const handleRejectCommercialRequest = async (user) => {
    try {
      // Update the commercial request status to rejected
      await updateCommercialStatus({
        id: user.commercialRequest._id,
        status: 'rejected',
        adminNotes: 'Commercial account request rejected'
      }).unwrap();

      // Update the user's commercial request pending status
      await updateUser({
        userId: user._id,
        commercialAccountRequestPending: false
      }).unwrap();

      refetch();
      toast.success('Commercial account request rejected successfully');
      setShowRejectModal(false);
    } catch (err) {
      toast.error(err?.data?.message || 'Failed to reject commercial account request');
    }
  };

  const handleUpdateClick = (user) => {
    if (!user || !user._id) {
      toast.error('Unable to load user information. Please try again.');
      return;
    }
    
    const userData = {
      _id: user._id,
      name: user.name || '',
      email: user.email || '',
      role: user.role || 'user',
      businessName: user.businessName || '',
      commercialPaymentPlan: user.commercialPaymentPlan || 'pay-now'
    };
    
    setSelectedUser(userData);
    setIsUpdateModalOpen(true);
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    if (!selectedUser?._id) {
      toast.error('Unable to update user. Please try again.');
      return;
    }

    try {
      await updateUser({
        userId: selectedUser._id,
        role: selectedUser.role,
        businessName: selectedUser.businessName || '',
        email: selectedUser.email,
        name: selectedUser.name,
        commercialPaymentPlan: selectedUser.commercialPaymentPlan || 'pay-now'
      }).unwrap();

      setIsUpdateModalOpen(false);
      await refetch();
      toast.success('User updated successfully');
    } catch (err) {
      toast.error('Unable to update user. Please check the information and try again.');
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      await createUser(newUser).unwrap();
      setIsCreateModalOpen(false);
      refetch();
      toast.success('User created successfully');
    } catch (err) {
      toast.error(err?.data?.message || 'Failed to create user');
    }
  };

  return (
    <div className="mt-8">
      <div className="fixed right-0 lg:left-72 left-0 z-40 flex h-16 items-center border-b border-gray-200 bg-white shadow-sm ring-1 ring-gray-300 lg:top-0 top-[3.5rem]">
        <div className="flex flex-1 justify-between items-center px-4">
          <SearchBox />
        </div>
      </div>

      <div className="pt-[7.5rem] lg:pt-16">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">All Users</h2>
          <button
            onClick={() => setIsCreateModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            Create User
          </button>
        </div>
        {isLoading ? (
          <div className="flex justify-center">
            <ToucanLoader />
          </div>
        ) : error ? (
          <Message variant='danger'>
            Unable to load users. Please try again later.
          </Message>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                    <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                    <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                    <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                    <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Business Name</th>
                    <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Update</th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Delete</th>
                    <th className="px-6 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Account Status</th>
                    <th className="px-6 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Plan</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {users && users.map((user) => (
                    <tr key={user._id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user._id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.email}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          user.role === 'admin' 
                            ? 'bg-purple-100 text-purple-800'
                            : 'bg-green-100 text-green-800'
                        }`}>
                          {user.role}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.businessName || 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(user.createdAt).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <button
                          onClick={() => handleUpdateClick(user)}
                          className="text-white bg-blue-600 hover:bg-blue-700 px-3 py-1 rounded-md text-sm mr-2"
                        >
                          Edit
                        </button>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {user.role !== 'admin' && (
                          <button
                            onClick={() => handleDeleteClick(user)}
                            className="text-white bg-red-600 hover:bg-red-700 px-3 py-1 rounded-md text-sm"
                          >
                            Delete
                          </button>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-center">
                        {user.isCommercialAccount ? (
                          <div className="flex flex-col items-center gap-2">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              Commercial Account
                            </span>
                          </div>
                        ) : user.commercialAccountRequestPending ? (
                          <div className="flex flex-col items-center gap-2">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                              Request Pending
                            </span>
                            <div className="flex gap-2">
                              <button
                                onClick={() => handleApproveCommercialRequest(user)}
                                className="text-white bg-amber-600 hover:bg-amber-700 px-3 py-1 rounded-md text-xs"
                              >
                                Approve
                              </button>
                              <button
                                onClick={() => {
                                  setSelectedUser(user);
                                  setShowRejectModal(true);
                                }}
                                className="text-white bg-red-600 hover:bg-red-700 px-3 py-1 rounded-md text-xs"
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                        ) : (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                            Regular Account
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-center">
                        <select
                          value={user.commercialPaymentPlan || 'pay-now'}
                          onChange={async (e) => {
                            try {
                              await updateUser({
                                userId: user._id,
                                commercialPaymentPlan: e.target.value
                              }).unwrap();
                              refetch();
                              toast.success('Payment plan updated successfully');
                            } catch (err) {
                              toast.error('Failed to update payment plan');
                            }
                          }}
                          disabled={!user.isCommercialAccount}
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${
                            !user.isCommercialAccount ? 'bg-gray-100 cursor-not-allowed' : ''
                          }`}
                        >
                          <option value="pay-now">Pay Now</option>
                          <option value="pay-later">Pay Later</option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            {pages > 1 && (
              <nav className="flex items-center justify-between px-4 sm:px-0 mt-12 mb-12">
                <div className="flex w-0 flex-1">
                  <Link
                    to={`/admin/users/page/${Number(pageNumber) > 1 ? Number(pageNumber) - 1 : 1}${keyword ? `?keyword=${keyword}` : ''}`}
                    className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-amber-300 hover:text-amber-700"
                  >
                    <ArrowLongLeftIcon className="mr-3 size-5 text-gray-400" aria-hidden="true" />
                    Previous
                  </Link>
                </div>
                
                <div className="hidden md:flex">
                  {[...Array(pages).keys()].map((x) => (
                    <Link
                      key={x + 1}
                      to={`/admin/users/page/${x + 1}${keyword ? `?keyword=${keyword}` : ''}`}
                      aria-current={Number(pageNumber) === x + 1 ? 'page' : undefined}
                      className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                        Number(pageNumber) === x + 1
                          ? 'border-[#fe940c] text-[#cc7100]'
                          : 'border-transparent text-gray-500 hover:border-[#ffa53d] hover:text-[#e07d00]'
                      }`}
                    >
                      {x + 1}
                    </Link>
                  ))}
                </div>
                
                <div className="flex w-0 flex-1 justify-end">
                  <Link
                    to={`/admin/users/page/${Number(pageNumber) < pages ? Number(pageNumber) + 1 : pages}${keyword ? `?keyword=${keyword}` : ''}`}
                    className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-amber-300 hover:text-amber-700"
                  >
                    Next
                    <ArrowLongRightIcon className="ml-3 size-5 text-gray-400" aria-hidden="true" />
                  </Link>
                </div>
              </nav>
            )}
          </>
        )}
      </div>

      <ConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        title="Delete User"
        message={`Are you sure you want to delete the user ${selectedUser?.name}? This action cannot be undone.`}
        confirmText="Delete"
        confirmButtonClass="bg-red-600 hover:bg-red-700"
      />

      <ConfirmationModal
        isOpen={showRejectModal}
        onClose={() => setShowRejectModal(false)}
        onConfirm={() => handleRejectCommercialRequest(selectedUser)}
        title="Reject Commercial Request"
        message={`Are you sure you want to reject the commercial account request for ${selectedUser?.name}? This action cannot be undone.`}
        confirmText="Reject"
        confirmButtonClass="bg-red-600 hover:bg-red-700"
      />

      <Modal
        isOpen={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        title="Update User"
      >
        <form onSubmit={handleUpdateUser}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={selectedUser?.name || ''}
              onChange={(e) => setSelectedUser({...selectedUser, name: e.target.value})}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={selectedUser?.email || ''}
              onChange={(e) => setSelectedUser({...selectedUser, email: e.target.value})}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Business Name</label>
            <input
              type="text"
              value={selectedUser?.businessName || ''}
              onChange={(e) => setSelectedUser({...selectedUser, businessName: e.target.value})}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Role</label>
            <select
              value={selectedUser?.role || ''}
              onChange={(e) => setSelectedUser({...selectedUser, role: e.target.value})}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={() => setIsUpdateModalOpen(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
            >
              Update
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        title="Create New Commercial User"
      >
        <form onSubmit={handleCreateUser} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              required
              value={newUser.name}
              onChange={(e) => setNewUser({...newUser, name: e.target.value})}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Business Name</label>
            <input
              type="text"
              required
              value={newUser.businessName}
              onChange={(e) => setNewUser({...newUser, businessName: e.target.value})}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              required
              value={newUser.email}
              onChange={(e) => setNewUser({...newUser, email: e.target.value})}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              required
              value={newUser.password}
              onChange={(e) => setNewUser({...newUser, password: e.target.value})}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
            <input
              type="password"
              required
              value={newUser.passwordConfirm}
              onChange={(e) => setNewUser({...newUser, passwordConfirm: e.target.value})}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Payment Plan</label>
            <select
              value={newUser.commercialPaymentPlan}
              onChange={(e) => setNewUser({...newUser, commercialPaymentPlan: e.target.value})}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="pay-now">Pay Now</option>
              <option value="pay-later">Pay Later</option>
            </select>
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={() => setIsCreateModalOpen(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-amber-600 text-white px-4 py-2 rounded-md hover:bg-amber-700"
            >
              Create
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default UserManagementScreen;