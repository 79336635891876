import React from 'react'
import { Link } from 'react-router-dom'
import FormattedPrice from './FormattedPrice'

const Product = ({ product }) => {
  return (
    <div className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
      <img
        alt={product.name}
        src={product.image}
        className="w-full bg-gray-200 object-contain sm:object-cover group-hover:opacity-75 sm:aspect-auto sm:h-96 h-48"
      />
      <div className="flex flex-1 flex-col space-y-1 p-2 sm:space-y-2 sm:p-4">
        <h3 className="text-sm font-medium text-gray-900">
          <Link to={`/product/${product._id}`}>
            <span aria-hidden="true" className="absolute inset-0" />
            {product.name}
          </Link>
        </h3>
        <div className="flex flex-1 flex-col justify-end">
          <p className="text-base font-medium text-gray-900">
            <FormattedPrice price={product.price} />
          </p>
        </div>
      </div>
    </div>
  )
}

export default Product