import { useParams, Link, useNavigate } from 'react-router-dom'
import { useGetProductDetailsQuery } from '../../slices/productsApiSlice'
import ToucanLoader from '../../components/ToucanLoader'
import Message from '../../components/Message'
import {useState} from 'react'
import {addToCart} from '../../slices/cartSlice'
import {useDispatch} from 'react-redux'
import FormattedPrice from '../../components/FormattedPrice'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'

const ProductScreen = () => {
  const { id: productId } = useParams()
  const { data: product, isLoading, error } = useGetProductDetailsQuery(productId)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [qty, setQty] = useState(1)
  const [isCustomQty, setIsCustomQty] = useState(false)
  const [selectedGrind, setSelectedGrind] = useState('Beans')

  const handleCustomQtyChange = (e) => {
    const value = parseInt(e.target.value)
    if (value > 0) {
      setQty(value)
    } else if (e.target.value === '') {
      setQty('')  // Allow empty value in custom mode
    }
  }

  const addToCartHandler = () => {
    dispatch(addToCart({
      ...product, 
      qty,
      grind: product.tags?.includes('Coffee') ? selectedGrind : undefined
    }))
    navigate('/cart')
  };

  return (
    <>
      {isLoading ? (
        <ToucanLoader />
      ) : error ? (
        <Message variant='danger'>Unable to load product information. Please refresh the page or try again later.</Message>
      ) : (
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            {/* Product details */}
            <div className="lg:max-w-lg lg:self-end">
              <nav aria-label="Breadcrumb">
                <ol className="flex items-center space-x-2">
                  <li>
                    <Link
                      to="/shop"
                      className="inline-flex items-center rounded-md bg-gray-100 px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-200"
                    >
                      <ArrowLeftIcon className="h-5 w-5 mr-1" />
                      Back to Shop
                    </Link>
                  </li>
                </ol>
              </nav>

              <div className="mt-4">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{product.name}</h1>
              </div>

              <section aria-labelledby="information-heading" className="mt-4">
                <h2 id="information-heading" className="sr-only">
                  Product information
                </h2>

                <div className="flex items-center">
                  <p className="text-lg text-gray-900 sm:text-xl">
                    <FormattedPrice price={product.price} />
                  </p>
                </div>

                <div className="mt-4 space-y-6">
                  <p className="text-base text-gray-500">{product.description}</p>
                </div>
              </section>
            </div>

            {/* Product image */}
            <div className="mt-10 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
              <img
                src={product.image}
                alt={product.name}
                className="w-full rounded-lg object-contain h-[600px]"
              />
            </div>

            {/* Product form */}
            <div className="mt-10 lg:col-start-1 lg:row-start-2 lg:max-w-lg lg:self-start">
              <section aria-labelledby="options-heading">
                <h2 id="options-heading" className="sr-only">
                  Product options
                </h2>

                <form>
                  <div className="grid grid-cols-2 gap-4">
                    {/* Quantity selector */}
                    <div className="space-y-2">
                      <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">
                        Quantity
                      </label>
                      {!isCustomQty ? (
                        <select
                          id="quantity"
                          name="quantity"
                          value={qty}
                          onChange={(e) => {
                            if (e.target.value === 'custom') {
                              setIsCustomQty(true)
                              setQty('')  // Clear the quantity when switching to custom
                            } else {
                              setQty(Number(e.target.value))
                            }
                          }}
                          className="w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                        >
                          {[...Array(10).keys()].map((x) => (
                            <option key={x + 1} value={x + 1}>
                              {x + 1}
                            </option>
                          ))}
                          <option value="custom">Custom quantity</option>
                        </select>
                      ) : (
                        <div className="flex gap-2">
                          <input
                            type="number"
                            min="1"
                            value={qty}
                            onChange={handleCustomQtyChange}
                            className="w-full rounded-md border border-gray-300 py-1.5 px-2 text-base font-medium text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setIsCustomQty(false)
                              setQty(1)
                            }}
                            className="text-sm text-gray-500 hover:text-gray-700"
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>

                    {/* Grind type selector - will only render if product is coffee */}
                    {product.tags?.includes('Coffee') && (
                      <div className="space-y-2">
                        <label htmlFor="grind" className="block text-sm font-medium text-gray-700">
                          Grind Type
                        </label>
                        <select
                          id="grind"
                          name="grind"
                          value={selectedGrind}
                          onChange={(e) => setSelectedGrind(e.target.value)}
                          className="w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                        >
                          <option value="Beans">Whole Beans</option>
                          <option value="Espresso Grind">Espresso Grind</option>
                          <option value="French Press Grind">French Press Grind</option>
                          <option value="Cafetetia Grind">Cafeteria Grind</option>
                        </select>
                      </div>
                    )}
                  </div>

                  <div className="mt-10">
                    <button
                      type="button"
                      className={`flex w-full items-center justify-center rounded-md border border-transparent px-8 py-3 text-base font-medium text-gray-900 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 focus:ring-offset-gray-50 ${
                        !qty ? 'bg-gray-300 cursor-not-allowed' : 'bg-amber-500 hover:bg-amber-400'
                      }`}
                      onClick={addToCartHandler}
                      disabled={!qty}
                    >
                      Add to cart
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProductScreen