import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveShippingAddress } from '../../slices/cartSlice'
import { Link } from 'react-router-dom'
import { useCreateOrderMutation, useUpdateOrderMutation, useGetOrderDetailsQuery } from '../../slices/ordersApiSlice'
import { useGetMeQuery, useUpdateMeMutation } from '../../slices/usersApiSlice'
import { useCreateCheckoutSessionMutation } from '../../slices/stripeApiSlice'
import { toast } from 'react-toastify'
import { addDecimals } from '../../utils/cartUtils'
import Message from '../../components/Message'
import FormattedPrice from '../../components/FormattedPrice'
import ToucanLoader from '../../components/ToucanLoader'
import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import StripeCheckoutForm from '../../components/StripeCheckoutForm'


const CheckoutScreen = () => {
  const { cartItems, shippingAddress } = useSelector((state) => state.cart)
  const { userInfo } = useSelector((state) => state.auth)
  
  // Add query to get latest user data
  const { data: userData } = useGetMeQuery()
  
  // Form state initialization with user data
  const [address, setAddress] = useState(shippingAddress?.address || '')
  const [city, setCity] = useState(shippingAddress?.city || '')
  const [postalCode, setPostalCode] = useState(shippingAddress?.postalCode || '')
  const [country, setCountry] = useState(shippingAddress?.country || 'Australia')
  const [firstName, setFirstName] = useState(shippingAddress?.firstName || userInfo?.name?.split(' ')[0] || '')
  const [lastName, setLastName] = useState(shippingAddress?.lastName || userInfo?.name?.split(' ')[1] || '')
  const [company, setCompany] = useState(shippingAddress?.company || userInfo?.businessName || '')
  const [apartment, setApartment] = useState(shippingAddress?.apartment || '')
  const [state, setState] = useState(shippingAddress?.state || '')
  const [phone, setPhone] = useState(shippingAddress?.phone || '')
  const [sameAsShipping, setSameAsShipping] = useState(true)
  const [billingFirstName, setBillingFirstName] = useState(shippingAddress?.billingFirstName || '')
  const [billingLastName, setBillingLastName] = useState(shippingAddress?.billingLastName || '')
  const [billingCompany, setBillingCompany] = useState(shippingAddress?.billingCompany || '')
  const [billingAddress, setBillingAddress] = useState(shippingAddress?.billingAddress || '')
  const [billingApartment, setBillingApartment] = useState(shippingAddress?.billingApartment || '')
  const [billingCity, setBillingCity] = useState(shippingAddress?.billingCity || '')
  const [billingCountry, setBillingCountry] = useState(shippingAddress?.billingCountry || 'Australia')
  const [billingState, setBillingState] = useState(shippingAddress?.billingState || '')
  const [billingPostalCode, setBillingPostalCode] = useState(shippingAddress?.billingPostalCode || '')
  const [billingPhone, setBillingPhone] = useState(shippingAddress?.billingPhone || '')
  const [email, setEmail] = useState(userInfo?.email || '')
  const [orderId, setOrderId] = useState(null)
  const [orderPlaced, setOrderPlaced] = useState(false)

  const dispatch = useDispatch()

  const [createOrder, { isLoading, error }] = useCreateOrderMutation()
  const [updateOrder] = useUpdateOrderMutation()
  
  const { data: order } = useGetOrderDetailsQuery(orderId)

  const [createCheckoutSession] = useCreateCheckoutSessionMutation()
  const [clientSecret, setClientSecret] = useState('')

  const [updateMe] = useUpdateMeMutation()

  // Calculate prices
  const itemsPrice = addDecimals(cartItems.reduce((acc, item) => acc + item.price * item.qty, 0))
  const shippingPrice = addDecimals(0)
  const taxPrice = addDecimals(
    cartItems.reduce((acc, item) => {
      // Only add GST if the item is explicitly marked as GST applicable
      if (item.gstApplicable === true) {
        return acc + (item.price * item.qty * 0.10)
      }
      return acc
    }, 0)
  )
  const totalPrice = addDecimals(Number(itemsPrice) + Number(shippingPrice) + Number(taxPrice))

  // Save shipping address to user profile
  const saveShippingToProfile = async (shippingData) => {
    try {
      if (userInfo) {  // Only save for logged in users
        await updateMe({
          lastUsedShippingAddress: {
            ...shippingData,
            billingAddress: !sameAsShipping ? {
              firstName: billingFirstName,
              lastName: billingLastName,
              company: billingCompany,
              address: billingAddress,
              apartment: billingApartment,
              city: billingCity,
              postalCode: billingPostalCode,
              state: billingState,
              country: billingCountry,
              phone: billingPhone,
            } : undefined
          }
        }).unwrap()
      }
    } catch (err) {
    }
  }

  // Update form when user data is loaded/refreshed
  useEffect(() => {
    if (userData?.data?.user) {
      const user = userData.data.user
      const nameParts = user.name?.split(' ') || []
      
      // Only update if no shipping address is saved in cart
      if (!shippingAddress?.firstName) {
        // First try to use last used shipping address
        if (user.lastUsedShippingAddress) {
          const lastAddress = user.lastUsedShippingAddress
          setFirstName(lastAddress.firstName || nameParts[0] || '')
          setLastName(lastAddress.lastName || nameParts.slice(1).join(' ') || '')
          setCompany(lastAddress.company || user.businessName || '')
          setAddress(lastAddress.address || '')
          setApartment(lastAddress.apartment || '')
          setCity(lastAddress.city || '')
          setState(lastAddress.state || '')
          setPostalCode(lastAddress.postalCode || '')
          setCountry(lastAddress.country || 'Australia')
          setPhone(lastAddress.phone || '')

          // Set billing address if it exists
          if (lastAddress.billingAddress) {
            setSameAsShipping(false)
            const billing = lastAddress.billingAddress
            setBillingFirstName(billing.firstName || '')
            setBillingLastName(billing.lastName || '')
            setBillingCompany(billing.company || '')
            setBillingAddress(billing.address || '')
            setBillingApartment(billing.apartment || '')
            setBillingCity(billing.city || '')
            setBillingState(billing.state || '')
            setBillingPostalCode(billing.postalCode || '')
            setBillingCountry(billing.country || 'Australia')
            setBillingPhone(billing.phone || '')
          }
        } else {
          // Fall back to basic user info
          setFirstName(nameParts[0] || '')
          setLastName(nameParts.slice(1).join(' ') || '')
          setCompany(user.businessName || '')
        }
        setEmail(user.email || '')
      }
    }
  }, [userData, shippingAddress])

  const submitHandler = async (e) => {
    e.preventDefault()
    
    try {
      // Validate cart is not empty
      if (cartItems.length === 0) {
        toast.error('Your cart is empty')
        return
      }

      // Validate email for guest users
      if (!userInfo && !email) {
        toast.error('Please enter your email address')
        return
      }

      const shippingAddressData = {
        firstName,
        lastName,
        company,
        address,
        apartment,
        city,
        postalCode,
        state,
        country,
        phone,
      }

      const billingAddressData = sameAsShipping ? shippingAddressData : {
        firstName: billingFirstName,
        lastName: billingLastName,
        company: billingCompany,
        address: billingAddress,
        apartment: billingApartment,
        city: billingCity,
        postalCode: billingPostalCode,
        state: billingState,
        country: billingCountry,
        phone: billingPhone,
      }

      // Create order first
      const res = await createOrder({
        orderItems: cartItems,
        shippingAddress: shippingAddressData,
        billingAddress: billingAddressData,
        paymentMethod: 'Stripe',
        itemsPrice,
        shippingPrice,
        taxPrice,
        totalPrice,
        guestEmail: !userInfo ? email : undefined,
      }).unwrap()

      // Save shipping address to user profile after successful order
      await saveShippingToProfile(shippingAddressData)

      // Create Stripe checkout session
      const stripeSession = await createCheckoutSession({
        orderId: res._id,
        orderType: 'retail'
      }).unwrap()

      setOrderId(res._id)
      setClientSecret(stripeSession.clientSecret)
      setOrderPlaced(true)
      
    } catch (err) {
      if (err?.data?.message?.includes('Order validation failed')) {
        toast.error('Please complete all required fields marked with *')
      } else {
        toast.error('Something went wrong.  Please ensure you have entered all marked fields correctly and try again.')
      }
    }
  }

  // Save shipping address when form fields change
  useEffect(() => {
    if (cartItems.length > 0) {
      dispatch(saveShippingAddress({
        firstName,
        lastName,
        company,
        address,
        apartment,
        city,
        postalCode,
        state,
        country,
        phone,
        billingFirstName: sameAsShipping ? firstName : billingFirstName,
        billingLastName: sameAsShipping ? lastName : billingLastName,
        billingCompany: sameAsShipping ? company : billingCompany,
        billingAddress: sameAsShipping ? address : billingAddress,
        billingApartment: sameAsShipping ? apartment : billingApartment,
        billingCity: sameAsShipping ? city : billingCity,
        billingPostalCode: sameAsShipping ? postalCode : billingPostalCode,
        billingState: sameAsShipping ? state : billingState,
        billingCountry: sameAsShipping ? country : billingCountry,
        billingPhone: sameAsShipping ? phone : billingPhone,
      }))
    }
  }, [
    dispatch,
    firstName,
    lastName,
    company,
    address,
    apartment,
    city,
    postalCode,
    state,
    country,
    phone,
    sameAsShipping,
    billingFirstName,
    billingLastName,
    billingCompany,
    billingAddress,
    billingApartment,
    billingCity,
    billingPostalCode,
    billingState,
    billingCountry,
    billingPhone,
    cartItems.length
  ])

  // Add edit mode state
  const [isEditing, setIsEditing] = useState(false)

  // Function to handle going back to edit
  const handleEdit = () => {
    setIsEditing(true)
  }

  // Function to handle updating the order
  const handleUpdate = async (e) => {
    e.preventDefault()
    
    try {
      // Validate email for guest users
      if (!userInfo && !email) {
        toast.error('Please enter your email address')
        return
      }

      const shippingAddressData = {
        firstName,
        lastName,
        company,
        address,
        apartment,
        city,
        postalCode,
        state,
        country,
        phone,
      }

      const billingAddressData = sameAsShipping ? shippingAddressData : {
        firstName: billingFirstName,
        lastName: billingLastName,
        company: billingCompany,
        address: billingAddress,
        apartment: billingApartment,
        city: billingCity,
        postalCode: billingPostalCode,
        state: billingState,
        country: billingCountry,
        phone: billingPhone,
      }

      const orderData = {
        shippingAddress: shippingAddressData,
        billingAddress: billingAddressData,
        guestEmail: order.guestEmail || email, // Always send the guest email if it exists
      }

      await updateOrder({
        orderId,
        orderData
      }).unwrap()

      setOrderPlaced(true)
      toast.success('Order details updated successfully')
      
    } catch (err) {
      if (err?.data?.message?.includes('Order validation failed')) {
        toast.error('Please complete all required fields marked with *')
      } else {
        toast.error('Something went wrong.  Please ensure you have entered all marked fields correctly and try again.')
      }
    } finally {
      setIsEditing(false)
    }
  }

  // Replace PayPal section with Stripe
  const renderPaymentSection = () => {
    if (orderPlaced && !isEditing && clientSecret) {
      return (
        <StripeCheckoutForm 
          orderId={orderId} 
          orderType="retail"
        />
      )
    }
    return null
  }

  const displayPrice = order ? addDecimals(order.totalPrice) : addDecimals(totalPrice)
  const displayItemsPrice = order ? addDecimals(order.itemsPrice) : addDecimals(itemsPrice)
  const displayShippingPrice = order ? addDecimals(order.shippingPrice) : addDecimals(shippingPrice)
  const displayTaxPrice = order ? addDecimals(order.taxPrice) : addDecimals(taxPrice)
  const displayItems = order ? order.orderItems : cartItems

  // Add isPending state
  const [isPending] = useState(false)

  return (
    <div className="bg-gray-100">
      {isLoading && <ToucanLoader />}
      {error && (
        <Message variant="danger" className="mx-auto max-w-2xl px-4 py-2 mb-8">
          There was a problem processing your order. Please ensure you have entered your email correctly and try again. Please contact us if the issue persists.
        </Message>
      )}
      
      <div className="relative mx-auto grid max-w-full grid-cols-1 lg:grid-cols-2 gap-x-8">
        <h1 className="sr-only">Checkout</h1>

        {/* Form Section - First on desktop */}
        <section
          aria-labelledby="payment-and-shipping-heading"
          className="order-last bg-white rounded-3xl shadow-lg mx-4 sm:mx-6 lg:mx-0 lg:shadow-none lg:rounded-none px-4 pt-8 pb-12 sm:px-6 lg:order-first lg:col-start-1 lg:row-start-1 lg:w-full lg:max-w-md xl:max-w-lg lg:pb-24 lg:pt-12 lg:px-8"
        >
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <h2 className="text-2xl font-medium text-gray-900 mb-8">
              {isEditing ? 'Edit Order Details' : 'Checkout details'}
            </h2>

            {/* Contact Information - Only show for guest users */}
            {!userInfo && (
              <div className="border-b border-gray-200 pb-8">
                <h3 className="text-lg font-medium text-gray-900 mb-6">Contact Information</h3>
                <div className="mt-6">
                  <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                    Guest Email Address <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      id="email-address"
                      name="email-address"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      required={!userInfo}
                      disabled={orderPlaced && !isEditing}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Shipping Information */}
            <div className="border-b border-gray-200 pb-8">
              <h3 className="text-lg font-medium text-gray-900 mb-6">Shipping information</h3>

              <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                <div>
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    First name <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      id="first-name"
                      name="first-name"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      autoComplete="given-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      disabled={orderPlaced && !isEditing}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                    Last name <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      id="last-name"
                      name="last-name"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      autoComplete="family-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      disabled={orderPlaced && !isEditing}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                    Company
                  </label>
                  <div className="mt-1">
                    <input
                      id="company"
                      name="company"
                      type="text"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      disabled={orderPlaced && !isEditing}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                    Address <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      id="address"
                      name="address"
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      autoComplete="street-address"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      disabled={orderPlaced && !isEditing}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="apartment" className="block text-sm font-medium text-gray-700">
                    Apartment, suite, etc.
                  </label>
                  <div className="mt-1">
                    <input
                      id="apartment"
                      name="apartment"
                      type="text"
                      value={apartment}
                      onChange={(e) => setApartment(e.target.value)}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      disabled={orderPlaced && !isEditing}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    City <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      id="city"
                      name="city"
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      autoComplete="address-level2"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      disabled={orderPlaced && !isEditing}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                    Country
                  </label>
                  <div className="mt-1">
                    <select
                      id="country"
                      name="country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      autoComplete="country-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      required
                      disabled={orderPlaced && !isEditing}
                    >
                      <option value="">Select a country</option>
                      <option value="Australia">Australia</option>
                    </select>
                  </div>
                </div>

                <div>
                  <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                    State <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      id="state"
                      name="state"
                      type="text"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      autoComplete="address-level1"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      disabled={orderPlaced && !isEditing}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                    Postal code <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      id="postal-code"
                      name="postal-code"
                      type="text"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      autoComplete="postal-code"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      disabled={orderPlaced && !isEditing}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                    Phone <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      id="phone"
                      name="phone"
                      type="text"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      autoComplete="tel"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      disabled={orderPlaced && !isEditing}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Billing Information */}
            <div className="border-b border-gray-200 pb-8">
              <h3 className="text-lg font-medium text-gray-900 mb-6">Billing information</h3>

              <div className="mt-6 flex items-center">
                <input
                  id="same-as-shipping"
                  name="same-as-shipping"
                  type="checkbox"
                  checked={sameAsShipping}
                  onChange={(e) => setSameAsShipping(e.target.checked)}
                  className="h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-500"
                  disabled={orderPlaced && !isEditing}
                />
                <div className="ml-2">
                  <label htmlFor="same-as-shipping" className="text-sm font-medium text-gray-900">
                    Same as shipping information
                  </label>
                </div>
              </div>

              {!sameAsShipping && (
                <div className="mt-6">
                  <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                    <div>
                      <label htmlFor="billing-first-name" className="block text-sm font-medium text-gray-700">
                        First name <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          id="billing-first-name"
                          name="billing-first-name"
                          type="text"
                          value={billingFirstName}
                          onChange={(e) => setBillingFirstName(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="billing-last-name" className="block text-sm font-medium text-gray-700">
                        Last name <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          id="billing-last-name"
                          name="billing-last-name"
                          type="text"
                          value={billingLastName}
                          onChange={(e) => setBillingLastName(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label htmlFor="billing-company" className="block text-sm font-medium text-gray-700">
                        Company
                      </label>
                      <div className="mt-1">
                        <input
                          id="billing-company"
                          name="billing-company"
                          type="text"
                          value={billingCompany}
                          onChange={(e) => setBillingCompany(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label htmlFor="billing-address" className="block text-sm font-medium text-gray-700">
                        Address <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          id="billing-address"
                          name="billing-address"
                          type="text"
                          value={billingAddress}
                          onChange={(e) => setBillingAddress(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label htmlFor="billing-apartment" className="block text-sm font-medium text-gray-700">
                        Apartment, suite, etc.
                      </label>
                      <div className="mt-1">
                        <input
                          id="billing-apartment"
                          name="billing-apartment"
                          type="text"
                          value={billingApartment}
                          onChange={(e) => setBillingApartment(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="billing-city" className="block text-sm font-medium text-gray-700">
                        City <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          id="billing-city"
                          name="billing-city"
                          type="text"
                          value={billingCity}
                          onChange={(e) => setBillingCity(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="billing-country" className="block text-sm font-medium text-gray-700">
                        Country
                      </label>
                      <div className="mt-1">
                        <select
                          id="billing-country"
                          name="billing-country"
                          value={billingCountry}
                          onChange={(e) => setBillingCountry(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          required
                          disabled={orderPlaced && !isEditing}
                        >
                          <option value="">Select a country</option>
                          <option value="Australia">Australia</option>
                        </select>
                      </div>
                    </div>

                    <div>
                      <label htmlFor="billing-state" className="block text-sm font-medium text-gray-700">
                        State <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          id="billing-state"
                          name="billing-state"
                          type="text"
                          value={billingState}
                          onChange={(e) => setBillingState(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="billing-postal-code" className="block text-sm font-medium text-gray-700">
                        Postal code <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          id="billing-postal-code"
                          name="billing-postal-code"
                          type="text"
                          value={billingPostalCode}
                          onChange={(e) => setBillingPostalCode(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label htmlFor="billing-phone" className="block text-sm font-medium text-gray-700">
                        Phone <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          id="billing-phone"
                          name="billing-phone"
                          type="text"
                          value={billingPhone}
                          onChange={(e) => setBillingPhone(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                          disabled={orderPlaced && !isEditing}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Payment Section */}
            <div className="border-gray-200 pb-8">
              <h3 className="text-lg font-medium text-gray-900 mb-6">Payment details</h3>
                
              {isPending ? (
                <ToucanLoader />
              ) : (
                <div className="mt-6 space-y-4">
                  {orderPlaced ? (
                    <div className="mt-4 space-y-4">
                      {isEditing ? (
                        <button
                          type="button"
                          onClick={handleUpdate}
                          className="w-full rounded-md border border-transparent bg-amber-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                        >
                          Update Order Details
                        </button>
                      ) : (
                        <>
                          <button
                            type="button"
                            onClick={handleEdit}
                            className="w-full rounded-md border border-gray-300 bg-white px-4 py-3 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 focus:ring-offset-gray-50 mb-4"
                          >
                            Edit Order Details
                          </button>
                          {renderPaymentSection()}
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="space-y-4">
                      <Link
                        to="/cart"
                        className="w-full inline-flex justify-center items-center rounded-md border border-gray-300 bg-white px-4 py-3 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                      >
                        <ShoppingCartIcon className="w-5 h-5 mr-2" />
                        Back to Cart
                      </Link>
                      <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                          <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div className="relative flex justify-center text-sm">
                          <span className="bg-white px-4 text-gray-500">or</span>
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={submitHandler}
                        className="w-full rounded-md border border-transparent bg-amber-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                        disabled={isLoading}
                      >
                        {isLoading ? 'Processing...' : 'Place Order & Pay Now'}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Summary Section - Now second on desktop */}
        <section
          aria-labelledby="summary-heading"
          className="order-first bg-gray-100 py-12 text-black md:px-10 lg:order-last lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-md xl:max-w-lg lg:bg-transparent lg:px-8 lg:pb-24 lg:pt-12 rounded-3xl shadow-lg mb-8 lg:rounded-none lg:shadow-none lg:mb-0 mx-4 sm:mx-6"
        >
          <div className="mx-auto max-w-2xl px-4 lg:px-0">
            <h2 id="summary-heading" className="text-lg font-medium text-black mb-6">
              Order summary
            </h2>

            <div className="max-w-md space-y-8">
              <dl>
                <dt className="text-sm font-medium">Amount due</dt>
                <dd className="mt-1 text-3xl font-bold tracking-tight text-black">${displayPrice}</dd>
              </dl>

              {/* Cart Items */}
              <ul className="divide-y divide-gray-200/10 text-sm font-medium">
                {displayItems.map((item) => (
                  <li key={item._id} className="flex items-start space-x-4 py-6">
                    <img
                      alt={item.name}
                      src={item.image}
                      className="h-24 w-24 flex-none rounded-md object-cover"
                    />
                    <div className="flex-auto space-y-2">
                      <h3 className="text-black text-base">{item.name}</h3>
                      <p>Quantity: {item.qty}</p>
                      {item.grind && (
                        <p className="text-sm text-gray-600">Grind: {item.grind}</p>
                      )}
                    </div>
                    <p className="flex-none text-base font-medium text-black">
                      <FormattedPrice price={addDecimals(item.price)} />
                    </p>
                  </li>
                ))}
              </ul>

              {/* Order Summary */}
              <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium">
                <div className="flex items-center justify-between">
                  <dt>Subtotal</dt>
                  <dd>${displayItemsPrice}</dd>
                </div>

                <div className="flex items-center justify-between">
                  <dt>Shipping</dt>
                  <dd>${displayShippingPrice}</dd>
                </div>

                <div className="flex items-center justify-between">
                  <dt>Tax (GST)</dt>
                  <dd>${displayTaxPrice}</dd>
                </div>

                <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-black">
                  <dt className="text-base">Total</dt>
                  <dd className="text-base">${displayPrice}</dd>
                </div>
              </dl>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default CheckoutScreen