import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useValidateTokenQuery, useRevokeTokenMutation, useGetAuthUriMutation } from '../../slices/quickBooksApiSlice';
import Loader from '../../components/ToucanLoader';
import Message from '../../components/Message';
import { toast } from 'react-toastify';

const QuickBooksScreen = () => {
  const location = useLocation();
  
  // Get current connection status
  const { data: tokenStatus, isLoading, error, refetch } = useValidateTokenQuery();
  
  // Mutations
  const [revokeToken, { isLoading: isRevoking }] = useRevokeTokenMutation();
  const [getAuthUri] = useGetAuthUriMutation();

  // Check for error parameter in URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const errorMsg = params.get('error');
    if (errorMsg) {
      toast.error(decodeURIComponent(errorMsg));
    }
  }, [location]);

  // Handle disconnect button click
  const handleDisconnect = async () => {
    try {
      const result = await revokeToken().unwrap();
      if (result.success) {
        toast.success(result.message || 'Successfully disconnected from QuickBooks');
      } else {
        toast.error(result.message || 'Failed to disconnect from QuickBooks');
      }
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  // Handle connect button click
  const handleConnect = async () => {
    try {
      const response = await getAuthUri().unwrap();
      // Redirect to QuickBooks authorization page
      window.location.href = response.authUri;
    } catch (err) {
      toast.error(err?.data?.message || err.error || 'Failed to start QuickBooks connection');
    }
  };

  // Helper function to get connection status message
  const getStatusMessage = () => {
    if (isLoading) return 'Checking connection status...';
    if (error) return 'Unable to determine connection status';
    if (!tokenStatus) return 'Not connected to QuickBooks';
    
    return tokenStatus.valid 
      ? 'Connected to QuickBooks'
      : tokenStatus.message || 'Not connected to QuickBooks';
  };

  // Helper function to determine if we're connected
  const isConnected = tokenStatus?.valid === true;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-8">QuickBooks Integration</h1>

        {isLoading && <Loader />}
        {error && <Message variant="danger">{error?.data?.message || error.error}</Message>}

        <div className="bg-white shadow rounded-lg p-6">
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Connection Status</h2>
            <p className={`text-lg ${isConnected ? 'text-green-600' : 'text-gray-600'}`}>
              {getStatusMessage()}
            </p>
          </div>

          <div className="flex gap-4">
            {isConnected ? (
              <button
                onClick={handleDisconnect}
                disabled={isRevoking}
                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
              >
                {isRevoking ? 'Disconnecting...' : 'Disconnect from QuickBooks'}
              </button>
            ) : (
              <button
                onClick={handleConnect}
                className="bg-[#ffa53d] hover:bg-[#ff9f2f] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Connect to QuickBooks
              </button>
            )}
          </div>
        </div>

        {/* Instructions */}
        <div className="mt-8 bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Instructions</h2>
          <div className="prose">
            <p className="mb-4">
              This integration allows you to sync your orders with QuickBooks for accounting purposes.
              When connected, the following features will be available:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Automatic creation of customers in QuickBooks</li>
              <li>Automatic generation of invoices for orders</li>
              <li>Sync of product inventory with QuickBooks</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickBooksScreen; 