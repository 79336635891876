import React from 'react';

const RefundsPolicyScreen = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Refunds Policy</h1>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Overview</h2>
        <div className="space-y-4">
          <p>
            Our refund and returns policy lasts 30 days. If 30 days have passed since your purchase, we can't offer you a full refund or exchange.
          </p>
          <p>
            To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
          </p>
          <p>
            Several types of goods are exempt from being returned. Perishable goods such as food (beans) after 30 days cannot be returned.
          </p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Return Process</h2>
        <div className="space-y-4">
          <p>To complete your return, we require a receipt or proof of purchase. Please do not send your purchase back to the manufacturer.</p>
          <p>There are certain situations where only partial refunds are granted:</p>
          <ul className="list-disc pl-6">
            <li>Any item not in its original condition, is damaged or missing parts for reasons not due to our error.</li>
            <li>Any item that is returned more than 30 days after delivery</li>
          </ul>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Refund Process</h2>
        <div className="space-y-4">
          <p>
            Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.
          </p>
          <p>
            If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.
          </p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Late or Missing Refunds</h2>
        <div className="space-y-4">
          <p>If you haven't received your refund, please follow these steps:</p>
          <ul className="list-disc pl-6">
            <li>First check your bank account again</li>
            <li>Contact your credit card company, it may take some time before your refund is officially posted</li>
            <li>Contact your bank. There is often some processing time before a refund is posted</li>
            <li>If you've done all of this and you still have not received your refund yet, please contact us at admin@terryscoffee.com.au</li>
          </ul>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Special Cases</h2>
        <div className="space-y-4">
          <h3 className="text-lg font-medium mb-2">Sale Items</h3>
          <p>Only regular priced items may be refunded. Sale items cannot be refunded.</p>

          <h3 className="text-lg font-medium mb-2">Gifts</h3>
          <p>If the item was marked as a gift when purchased and incorrectly shipped directly to you, you'll receive a gift credit for the value of your return. Once the returned item is received, a gift certificate will be mailed to you.</p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Shipping Returns</h2>
        <div className="space-y-4">
          <p>
            To return your product, you should email us at: admin@terryscoffee.com.au; stating the date of purchase and a return phone number with mention of your concerns.
          </p>
          <p>
            Depending on where you live, the time it may take for your exchanged product to reach you may vary.
          </p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Need Help?</h2>
        <p>
          Contact us at: admin@terryscoffee.com.au for questions related to refunds and returns.
        </p>
      </section>
    </div>
  );
};

export default RefundsPolicyScreen;