import React, { useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useGetProductsQuery } from '../../slices/productsApiSlice';
import ToucanLoader from '../../components/ToucanLoader';
import Message from '../../components/Message';
import { useNavigate } from 'react-router-dom';
import { useDeleteProductMutation } from '../../slices/productsApiSlice';
import { toast } from 'react-toastify';
import { PencilSquareIcon, ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/solid';
import SearchBox from '../../components/SearchBox';
import ConfirmationModal from '../../components/ConfirmationModal';

const ProductManagementScreen = () => {
  const { pageNumber } = useParams();
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword') || '';

  const { data: { data: { products } = {}, pages } = {}, isLoading, error, refetch } = useGetProductsQuery({ 
    pageNumber: pageNumber || 1,
    keyword
  });

  const navigate = useNavigate();
  const [deleteProduct] = useDeleteProductMutation();

  const [selectedProductId, setSelectedProductId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteClick = (productId) => {
    setSelectedProductId(productId);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      await deleteProduct(selectedProductId);
      refetch();
      toast.success('Product deleted successfully');
      setShowDeleteModal(false);
    } catch (err) {
      toast.error('Unable to delete product. Please try again later.');
    }
  };

  return (
    <div className="mt-8">
      <div className="fixed right-0 lg:left-72 left-0 z-40 flex h-16 items-center border-b border-gray-200 bg-white shadow-sm ring-1 ring-gray-300 lg:top-0 top-[3.5rem]">
        <div className="flex flex-1">
          <SearchBox />
        </div>
      </div>

      <div className="pt-[7.5rem] lg:pt-16">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">All Products</h2>
          <button 
            onClick={() => navigate('/admin/product/new')}
            className="text-white bg-green-600 hover:bg-green-700 px-3 py-1 rounded-md text-sm mr-8">
            Add Product
          </button>
        </div>
        {isLoading ? (
          <div className="flex justify-center">
            <ToucanLoader />
          </div>
        ) : error ? (
          <Message variant='danger'>
            Unable to load products. Please refresh the page or try again later.
          </Message>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                    <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                    <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Size</th>
                    <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tags</th>
                    <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                    <th className="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Image</th>
                    <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {products && products.map((product) => (
                    <tr key={product._id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {product.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        ${product.price}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {product.size || 'N/A'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {product.tags && product.tags.length > 0 ? (
                          <div className="flex flex-wrap gap-1">
                            {product.tags.map((tag, index) => (
                              <span key={index} className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-amber-100 text-amber-800">
                                {tag}
                              </span>
                            ))}
                          </div>
                        ) : (
                          'No tags'
                        )}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 max-w-xs truncate">
                        {product.description}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <img 
                          src={product.image} 
                          alt={product.name} 
                          className="h-10 w-10 object-cover rounded-md"
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <Link
                          to={`/admin/product/${product._id}/edit`}
                          className="text-amber-600 hover:text-amber-900 mr-3"
                        >
                          <PencilSquareIcon className="h-5 w-5 inline-block" />
                        </Link>
                        <button
                          onClick={() => handleDeleteClick(product._id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <TrashIcon className="h-5 w-5 inline-block" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            {pages > 1 && (
              <nav className="flex items-center justify-between px-4 sm:px-0 mt-12 mb-12">
                <div className="flex w-0 flex-1">
                  <Link
                    to={`/admin/products/page/${Number(pageNumber) > 1 ? Number(pageNumber) - 1 : 1}${keyword ? `?keyword=${keyword}` : ''}`}
                    className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-amber-300 hover:text-amber-700"
                  >
                    <ArrowLongLeftIcon className="mr-3 size-5 text-gray-400" aria-hidden="true" />
                    Previous
                  </Link>
                </div>
                
                <div className="hidden md:flex">
                  {[...Array(pages).keys()].map((x) => (
                    <Link
                      key={x + 1}
                      to={`/admin/products/page/${x + 1}${keyword ? `?keyword=${keyword}` : ''}`}
                      aria-current={Number(pageNumber) === x + 1 ? 'page' : undefined}
                      className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                        Number(pageNumber) === x + 1
                          ? 'border-amber-500 text-amber-600'
                          : 'border-transparent text-gray-500 hover:border-amber-300 hover:text-amber-700'
                      }`}
                    >
                      {x + 1}
                    </Link>
                  ))}
                </div>
                
                <div className="flex w-0 flex-1 justify-end">
                  <Link
                    to={`/admin/products/page/${Number(pageNumber) < pages ? Number(pageNumber) + 1 : pages}${keyword ? `?keyword=${keyword}` : ''}`}
                    className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-amber-300 hover:text-amber-700"
                  >
                    Next
                    <ArrowLongRightIcon className="ml-3 size-5 text-gray-400" aria-hidden="true" />
                  </Link>
                </div>
              </nav>
            )}
          </>
        )}
      </div>

      <ConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        title="Delete Product"
        message="Are you sure you want to delete this product? This action cannot be undone."
        confirmText="Delete"
        confirmButtonClass="bg-red-600 hover:bg-red-700"
      />
    </div>
  );
};

export default ProductManagementScreen;