import { apiSlice } from './apiSlice'
import { ORDERS_URL } from '../constants'

export const ordersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createOrder: builder.mutation({
      query: (order) => ({
        url: ORDERS_URL,
        method: 'POST',
        body: order,
      }),
    }),
    updateOrder: builder.mutation({
      query: ({ orderId, orderData }) => ({
        url: `${ORDERS_URL}/${orderId}/update`,
        method: 'PATCH',
        body: orderData,
      }),
      invalidatesTags: (result, error, { orderId }) => 
        [{ type: 'Order', id: orderId }],
    }),
    getOrderDetails: builder.query({
      query: (orderId) => ({
        url: `${ORDERS_URL}/${orderId}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: (result, error, orderId) => 
        [{ type: 'Order', id: orderId }],
    }),
    getMyOrders: builder.query({
      query: () => ({
        url: `${ORDERS_URL}/myorders`,
      }),
      keepUnusedDataFor: 5,
    }),
    hideMyOrder: builder.mutation({
      query: (orderId) => ({
        url: `${ORDERS_URL}/${orderId}/hide`,
        method: 'PATCH',
      }),
    }),
    getOrdersAdmin: builder.query({
      query: ({ pageNumber, keyword } = {}) => ({
        url: `${ORDERS_URL}/admin/orders`,
        params: { pageNumber, keyword }
      }),
      keepUnusedDataFor: 5,
      providesTags: (result) => 
        result?.data?.orders
          ? [
              ...result.data.orders.map(({ _id }) => ({ type: 'Order', id: _id })),
              { type: 'Orders', id: 'LIST' }
            ]
          : [{ type: 'Orders', id: 'LIST' }]
    }),
    updateOrderToPaid: builder.mutation({
      query: ({ orderId, details }) => ({
        url: `${ORDERS_URL}/${orderId}/pay`,
        method: 'PUT',
        body: details,
      }),
    }),
    updateOrderToCompleted: builder.mutation({
      query: (orderId) => ({
        url: `${ORDERS_URL}/admin/order/${orderId}/complete`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, orderId) => [
        { type: 'Order', id: orderId },
        { type: 'Orders', id: 'LIST' }
      ],
    }),
    getOrderByIdForAdmin: builder.query({
      query: (id) => ({
        url: `${ORDERS_URL}/admin/order/${id}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: (result, error, id) => [
        { type: 'Order', id },
        { type: 'Orders', id: 'LIST' }
      ]
    }),
  }),
})

export const {
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useGetOrderDetailsQuery,
  useGetMyOrdersQuery,
  useGetOrdersAdminQuery,
  useUpdateOrderToCompletedMutation,
  useHideMyOrderMutation,
  useGetOrderByIdForAdminQuery,
  useUpdateOrderToPaidMutation,
} = ordersApiSlice
  