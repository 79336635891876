import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForgotPasswordMutation } from '../../slices/usersApiSlice'
import { toast } from 'react-toastify'
import Loader from '../../components/ToucanLoader'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation()

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Frontend validation
    if (!email.trim()) {
      toast.error('Email is required')
      return
    }

    // Use same email validation as backend
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email')
      return
    }

    try {
      await forgotPassword({ email }).unwrap()
      toast.success('Reset password link sent to your email!')
    } catch (err) {
      toast.error('Unable to send reset link. Please check your email address and try again.')
    }
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-cover bg-center"
      style={{ backgroundImage: 'url("/images/beans.png")' }}>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-40 w-auto"
          src="/images/terrys-logo.png"
          alt="Terry's Coffee"
        />
        <h2 className="mt-6 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
          Forgot Password
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Enter your email address and we'll send you a link to reset your password.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white/90 backdrop-blur-sm px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fe940c] sm:text-sm/6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="flex w-full justify-center rounded-md bg-[#fe940c] px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-[#e07d00] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#fe940c]"
              >
                Send Reset Link
              </button>
              {isLoading && <Loader />}
            </div>
          </form>
        </div>

        <p className="mt-10 text-center text-sm/6 text-black font-medium">
          Remember your password?{' '}
          <Link to="/login" className="font-semibold text-[#fe940c] hover:text-[#e07d00]">
            Sign in here
          </Link>
        </p>
      </div>
    </div>
  )
}

export default ForgotPassword