import React from 'react';
import { FcGoogle } from 'react-icons/fc';

const GoogleButton = () => {
  const handleGoogleAuth = () => {
    // Use the appropriate API URL based on environment
    const apiUrl = process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL_PRODUCTION
      : process.env.REACT_APP_API_URL;
    
    window.location.href = `${apiUrl}/api/auth/google`;
  };

  return (
    <button
      onClick={handleGoogleAuth}
      type="button"
      className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
    >
      <FcGoogle className="h-5 w-5" />
      <span className="text-sm font-semibold leading-6">Continue with Google</span>
    </button>
  );
};

export default GoogleButton; 