import { apiSlice } from './apiSlice'
import { COMMERCIAL_ORDERS_URL } from '../constants'

export const commercialOrdersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCommercialOrder: builder.mutation({
      query: (order) => ({
        url: COMMERCIAL_ORDERS_URL,
        method: 'POST',
        body: order,
      }),
    }),
    updateCommercialOrder: builder.mutation({
      query: ({ orderId, orderData }) => ({
        url: `${COMMERCIAL_ORDERS_URL}/${orderId}`,
        method: 'PUT',
        body: orderData,
      }),
    }),
    getCommercialOrderDetails: builder.query({
      query: (orderId) => ({
        url: `${COMMERCIAL_ORDERS_URL}/${orderId}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: (result, error, orderId) => [
        { type: 'CommercialOrder', id: orderId }
      ],
    }),
    getMyCommercialOrders: builder.query({
      query: () => ({
        url: `${COMMERCIAL_ORDERS_URL}/myorders`,
      }),
      keepUnusedDataFor: 5,
    }),
    updateCommercialOrderToPaid: builder.mutation({
      query: (orderId) => ({
        url: `${COMMERCIAL_ORDERS_URL}/admin/order/${orderId}/pay`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, orderId) => [
        { type: 'CommercialOrder', id: orderId },
        'CommercialOrder'
      ],
    }),
    updateCommercialOrderToManuallyPaid: builder.mutation({
      query: (orderId) => ({
        url: `${COMMERCIAL_ORDERS_URL}/admin/order/${orderId}/manual-pay`,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, orderId) => [
        { type: 'CommercialOrder', id: orderId },
        { type: 'CommercialOrders', id: 'LIST' }
      ],
    }),
    updateCommercialOrderToCompleted: builder.mutation({
      query: (orderId) => ({
        url: `${COMMERCIAL_ORDERS_URL}/admin/order/${orderId}/complete`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, orderId) => [
        { type: 'CommercialOrder', id: orderId },
        'CommercialOrder'
      ],
    }),
    getCommercialOrdersAdmin: builder.query({
      query: ({ pageNumber, keyword } = {}) => ({
        url: `${COMMERCIAL_ORDERS_URL}/admin/orders`,
        params: { pageNumber, keyword }
      }),
      keepUnusedDataFor: 5,
      providesTags: (result) => 
        result?.data?.orders
          ? [
              ...result.data.orders.map(({ _id }) => ({ type: 'CommercialOrder', id: _id })),
              { type: 'CommercialOrders', id: 'LIST' }
            ]
          : [{ type: 'CommercialOrders', id: 'LIST' }]
    }),
    getCommercialOrderByIdForAdmin: builder.query({
      query: (id) => ({
        url: `${COMMERCIAL_ORDERS_URL}/admin/order/${id}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: (result, error, id) => [
        { type: 'CommercialOrder', id },
        { type: 'CommercialOrders', id: 'LIST' }
      ]
    }),
    completeCommercialOrder: builder.mutation({
      query: (orderId) => ({
        url: `${COMMERCIAL_ORDERS_URL}/${orderId}/complete`,
        method: 'PUT',
      }),
    }),
    hideMyCommercialOrder: builder.mutation({
      query: (orderId) => ({
        url: `${COMMERCIAL_ORDERS_URL}/${orderId}/hide`,
        method: 'PUT',
      }),
    }),
  }),
})

export const {
  useCreateCommercialOrderMutation,
  useGetCommercialOrderDetailsQuery,
  useGetMyCommercialOrdersQuery,
  useUpdateCommercialOrderMutation,
  useUpdateCommercialOrderToPaidMutation,
  useUpdateCommercialOrderToManuallyPaidMutation,
  useUpdateCommercialOrderToCompletedMutation,
  useGetCommercialOrdersAdminQuery,
  useGetCommercialOrderByIdForAdminQuery,
  useCompleteCommercialOrderMutation,
  useHideMyCommercialOrderMutation,
} = commercialOrdersApiSlice
