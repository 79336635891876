import { BASE_URL, PRODUCTS_URL, UPLOAD_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: ({pageNumber, keyword, tag} = {}) => ({
        url: `${PRODUCTS_URL}`,
        params: { pageNumber, keyword, tag },
      }),
      transformResponse: (response) => {
        const transformed = {
          ...response,
          data: {
            products: response.data.products.map(product => {
              return {
                ...product,
                image: `${BASE_URL}${product.image}`
              };
            })
          }
        };
        return transformed;
      },
      keepUnusedDataFor: 5,
      providesTags: ['Product'],
    }),
    getFeaturedProducts: builder.query({
      query: () => ({
        url: `${PRODUCTS_URL}/featured`,
      }),
      transformResponse: (response) => {
        return response.map(product => ({
          ...product,
          image: `${BASE_URL}${product.image}`
        }));
      },
      keepUnusedDataFor: 5,
      providesTags: ['Product'],
    }),
    getProductDetails: builder.query({
      query: (productId) => ({
        url: `${PRODUCTS_URL}/${productId}`,
      }),
      transformResponse: (response) => ({
        ...response,
        image: `${BASE_URL}${response.image}`
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Product'],
    }),
    // Admin Routes
    createProduct: builder.mutation({
      query: (product) => ({
        url: `${PRODUCTS_URL}/new`,
        method: 'POST',
        body: product,
      }),
      invalidatesTags: ['Product'],
    }),
    updateProduct: builder.mutation({
      query: (product) => ({
        url: `${PRODUCTS_URL}/${product._id}`,
        method: 'PATCH',
        body: product,
      }),
      invalidatesTags: ['Product'],
    }),
    uploadProductImage: builder.mutation({
      query: (data) => ({
        url: `${UPLOAD_URL}`,
        method: 'POST',
        body: data,
        prepareHeaders: (headers) => {
          headers.delete('Content-Type');
          headers.set('Accept', 'application/json');
          return headers;
        },
      }),
      responseHandler: async (response) => {
        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || 'Upload failed');
        }
        return response.json();
      },
      invalidatesTags: ['Product'],
    }),
    deleteProduct: builder.mutation({
      query: (productId) => ({
        url: `${PRODUCTS_URL}/${productId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Product'],
    }),
  }),
});

export const { 
  useGetProductsQuery, 
  useGetProductDetailsQuery, 
  useCreateProductMutation,
  useUpdateProductMutation,
  useUploadProductImageMutation,
  useDeleteProductMutation,
  useGetFeaturedProductsQuery
} = productsApiSlice;
