import { apiSlice } from './apiSlice'
import { LOCATIONS_URL } from '../constants'

export const locationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Public endpoint for map
    getLocations: builder.query({
      query: () => ({
        url: `${LOCATIONS_URL}`,
      }),
      keepUnusedDataFor: 5,
    }),
    // Admin endpoint with pagination
    getLocationsAdmin: builder.query({
      query: ({ pageNumber, keyword } = {}) => ({
        url: `${LOCATIONS_URL}/admin`,
        params: pageNumber ? { pageNumber, keyword } : undefined,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Location'],
    }),
    getLocationById: builder.query({
      query: (locationId) => ({
        url: `${LOCATIONS_URL}/admin/${locationId}`,
      }),
    }),
    createLocation: builder.mutation({
      query: (location) => ({
        url: `${LOCATIONS_URL}/admin/new`,
        method: 'POST',
        body: location,
      }), 
      invalidatesTags: ['Location'],
    }),
    updateLocation: builder.mutation({
      query: (location) => ({
        url: `${LOCATIONS_URL}/admin/${location._id}`,
        method: 'PATCH',
        body: location,
      }),
      invalidatesTags: ['Location'],
    }),
    deleteLocation: builder.mutation({
      query: (locationId) => ({
        url: `${LOCATIONS_URL}/admin/${locationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Location'],
    }),
  }),
});

export const { 
  useGetLocationsQuery, 
  useGetLocationsAdminQuery,
  useGetLocationByIdQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation
} = locationsApiSlice;