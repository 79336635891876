import React from 'react';
import GoogleMap from '../../components/GoogleMap';
import { useGetLocationsQuery } from '../../slices/locationsApiSlice';

const FindUs = () => {
  const { data: locationData, isLoading, error } = useGetLocationsQuery();
  
  // Transform the locations data to match the expected format
  const locations = locationData?.locations?.map(location => ({
    id: location._id,
    position: {
      lat: parseFloat(location.position.lat),
      lng: parseFloat(location.position.lng)
    },
    name: location.name,
    address: location.address,
    phone: location.phone || '',
    email: location.email || '',
    hours: location.hours || '',
    city: location.city || ''
  })) || [];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold tracking-tight text-gray-900 mb-6">
        Find Us
      </h1>
      <h2 className="text-xl text-gray-600 mb-6">Can't get enough of our coffee?  We've marked all cafes serving our coffee on the map below.  Just follow the Toucan!</h2>
      
      <div className="grid grid-cols-1 gap-8">
        <GoogleMap 
          locations={locations} 
          isLoading={isLoading} 
          error={error}
        />
      </div>
    </div>
  );
};

export default FindUs;