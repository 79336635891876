import React, { useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useGetCommercialOrdersAdminQuery, useUpdateCommercialOrderToCompletedMutation, useUpdateCommercialOrderToManuallyPaidMutation } from '../../slices/commercialOrdersApiSlice';
import ToucanLoader from '../../components/ToucanLoader';
import { toast } from 'react-toastify';
import Message from '../../components/Message';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/solid';
import SearchBox from '../../components/SearchBox';
import ConfirmationModal from '../../components/ConfirmationModal';
import { addDecimals } from '../../utils/cartUtils';

const CommercialOrderManagementScreen = () => {
  const { pageNumber } = useParams();
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword') || '';

  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showPaidModal, setShowPaidModal] = useState(false);

  const { data: { data: { orders } = {}, pages } = {}, isLoading, error, refetch } = useGetCommercialOrdersAdminQuery({ 
    pageNumber: pageNumber || 1,
    keyword
  });

  const [updateCommercialOrderToCompleted] = useUpdateCommercialOrderToCompletedMutation();
  const [updateCommercialOrderToManuallyPaid] = useUpdateCommercialOrderToManuallyPaidMutation();

  const handleCompleteClick = (orderId) => {
    setSelectedOrderId(orderId);
    setShowCompleteModal(true);
  };

  const handlePaidClick = (orderId) => {
    setSelectedOrderId(orderId);
    setShowPaidModal(true);
  };

  const handleComplete = async () => {
    try {
      await updateCommercialOrderToCompleted(selectedOrderId).unwrap();
      refetch();
      toast.success('Order marked as completed');
      setShowCompleteModal(false);
    } catch (err) {
      toast.error('Unable to mark commercial order as completed. Please try again.');
    }
  };

  const handlePaid = async (orderId) => {
    try {
      await updateCommercialOrderToManuallyPaid(orderId).unwrap();
      refetch();
      toast.success('Order marked as paid');
      setShowPaidModal(false);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const sortedOrders = orders ? [...orders].sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  }) : [];

  return (
    <div className="mt-8">
      <div className="fixed right-0 lg:left-72 left-0 z-40 flex h-16 items-center border-b border-gray-200 bg-white shadow-sm ring-1 ring-gray-300 lg:top-0 top-[3.5rem]">
        <div className="flex flex-1">
          <SearchBox />
        </div>
      </div>

      <ConfirmationModal
        isOpen={showCompleteModal}
        onClose={() => setShowCompleteModal(false)}
        onConfirm={handleComplete}
        title="Mark Commercial Order as Complete"
        message="Are you sure you want to mark this commercial order as complete? This action cannot be undone."
        confirmText="Mark Complete"
        confirmButtonClass="bg-green-600 hover:bg-green-700"
      />

      <ConfirmationModal
        isOpen={showPaidModal}
        onClose={() => setShowPaidModal(false)}
        onConfirm={() => handlePaid(selectedOrderId)}
        title="Mark Commercial Order as Paid"
        message="Are you sure you want to mark this commercial order as paid? This will indicate that payment has been received."
        confirmText="Mark as Paid"
        confirmButtonClass="bg-blue-600 hover:bg-blue-700"
      />

      <div className="pt-[7.5rem] lg:pt-16">
        <h2 className="text-xl font-bold mb-4">Commercial Orders</h2>
        {isLoading ? (
          <div className="flex justify-center">
            <ToucanLoader />
          </div>
        ) : error ? (
          <Message variant='danger'>
            Unable to load commercial orders. Please try again later.
          </Message>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Order ID
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Business
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Contact
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Total
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Payment Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Order Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {sortedOrders.map((order) => (
                    <tr key={order._id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {order._id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div>
                          <div>{order.user?.businessName || 'N/A'}</div>
                          <div className="text-xs text-gray-400">{order.shippingAddress?.company || 'N/A'}</div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div>
                          <div>{order.user?.name || 'N/A'}</div>
                          <div className="text-xs text-gray-400">{order.user?.email || 'N/A'}</div>
                          <div className="text-xs text-gray-400">{order.shippingAddress?.phone || 'N/A'}</div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {order.createdAt?.substring(0, 10)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        ${addDecimals(order.totalPrice)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.isPaid ? (
                          <div className="flex items-center">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              Paid {order.paidAt?.substring(0, 10)}
                            </span>
                          </div>
                        ) : (
                          <button
                            onClick={() => handlePaidClick(order._id)}
                            className="text-white bg-blue-600 hover:bg-blue-700 px-3 py-1 rounded-md text-sm"
                          >
                            Mark as Paid
                          </button>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            checked={order.isCompleted}
                            onChange={() => handleCompleteClick(order._id)}
                            className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded cursor-pointer"
                          />
                          <span className="ml-2 text-sm text-gray-500">
                            {order.isCompleted ? 'Completed' : 'Pending'}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <Link
                          to={`/admin/commercialOrder/${order._id}`}
                          className="text-amber-600 hover:text-amber-900"
                        >
                          Details
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            {pages > 1 && (
              <nav className="flex items-center justify-between px-4 sm:px-0 mt-12 mb-12">
                <div className="flex w-0 flex-1">
                  <Link
                    to={`/admin/commercialOrders/page/${Number(pageNumber) > 1 ? Number(pageNumber) - 1 : 1}${keyword ? `?keyword=${keyword}` : ''}`}
                    className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-amber-300 hover:text-amber-700"
                  >
                    <ArrowLongLeftIcon className="mr-3 size-5 text-gray-400" aria-hidden="true" />
                    Previous
                  </Link>
                </div>
                
                <div className="hidden md:flex">
                  {[...Array(pages).keys()].map((x) => (
                    <Link
                      key={x + 1}
                      to={`/admin/commercialOrders/page/${x + 1}${keyword ? `?keyword=${keyword}` : ''}`}
                      aria-current={Number(pageNumber) === x + 1 ? 'page' : undefined}
                      className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                        Number(pageNumber) === x + 1
                          ? 'border-amber-500 text-amber-600'
                          : 'border-transparent text-gray-500 hover:border-amber-300 hover:text-amber-700'
                      }`}
                    >
                      {x + 1}
                    </Link>
                  ))}
                </div>
                
                <div className="flex w-0 flex-1 justify-end">
                  <Link
                    to={`/admin/commercialOrders/page/${Number(pageNumber) < pages ? Number(pageNumber) + 1 : pages}${keyword ? `?keyword=${keyword}` : ''}`}
                    className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-amber-300 hover:text-amber-700"
                  >
                    Next
                    <ArrowLongRightIcon className="ml-3 size-5 text-gray-400" aria-hidden="true" />
                  </Link>
                </div>
              </nav>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CommercialOrderManagementScreen; 