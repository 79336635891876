import React, {useEffect, useState, useRef} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { useRegisterMutation } from '../../slices/usersApiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setCredentials } from '../../slices/authSlice'
import { toast } from 'react-toastify'
import ToucanLoader from '../../components/ToucanLoader'
import ReCAPTCHA from 'react-google-recaptcha'

const RegisterScreen = () => {
  const [name, setName] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [recaptchaValue, setRecaptchaValue] = useState(null)
  const recaptchaRef = useRef(null)
  const [register, {isLoading}] = useRegisterMutation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {userInfo} = useSelector((state) => state.auth)

  useEffect(() => {
    if (userInfo) {
      navigate('/')
    }
  }, [navigate, userInfo])

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value)
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    
    // Frontend validation
    if (!name.trim()) {
      toast.error('Name is required')
      return
    }

    if (!email.trim()) {
      toast.error('Email is required')
      return
    }

    // Use same email validation as backend (validator.isEmail)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email')
      return
    }

    if (password.length < 8) {
      toast.error('Password must be at least 8 characters long')
      return
    }

    if (password !== passwordConfirm) {
      toast.error('Passwords do not match')
      return
    }

    // Validate reCAPTCHA
    if (!recaptchaValue) {
      toast.error('Please complete the reCAPTCHA verification')
      return
    }

    try {
      const res = await register({
        name, 
        businessName,
        email, 
        password,
        passwordConfirm,
        recaptchaToken: recaptchaValue
      }).unwrap()
      dispatch(setCredentials({
        ...res.data.user,
        token: res.token
      }))
      navigate('/')
    } catch (err) {
      toast.error('Unable to create account. Please check your information and try again.');
      // Reset reCAPTCHA on error
      if (recaptchaRef.current) {
        recaptchaRef.current.reset()
      }
      setRecaptchaValue(null)
    }
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-cover bg-center" 
        style={{ backgroundImage: 'url("/images/beans.png")' }}>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-40 w-auto"
            src="/images/terrys-logo.png"
            alt="Terry's Coffee"
          />
          <h2 className="mt-6 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
            Create your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white/90 backdrop-blur-sm px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form onSubmit={submitHandler} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm/6 font-medium text-gray-900">
                  Full Name
                </label>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fe940c] sm:text-sm/6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="businessName" className="block text-sm/6 font-medium text-gray-900">
                  Business Name <span className="text-gray-500 text-xs">(Optional)</span>
                </label>
                <div className="mt-2">
                  <input
                    id="businessName"
                    name="businessName"
                    type="text"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    autoComplete="organization"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fe940c] sm:text-sm/6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fe940c] sm:text-sm/6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="new-password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fe940c] sm:text-sm/6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="passwordConfirm" className="block text-sm/6 font-medium text-gray-900">
                  Confirm Password
                </label>
                <div className="mt-2">
                  <input
                    id="passwordConfirm"
                    name="passwordConfirm"
                    type="password"
                    required
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    autoComplete="new-password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fe940c] sm:text-sm/6"
                  />
                </div>
              </div>

              {/* Add reCAPTCHA before the submit button */}
              <div className="flex justify-center my-4">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={handleRecaptchaChange}
                />
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isLoading || !recaptchaValue}
                  className="flex w-full justify-center rounded-md bg-[#fe940c] px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-[#e07d00] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#fe940c]"
                >
                  Sign up
                </button>
                {isLoading && <ToucanLoader />}
              </div>
            </form>
          </div>

          <p className="mt-10 text-center text-sm/6 text-black font-medium">
            Already have an account?{' '}
            <Link to="/login" className="font-semibold text-[#fe940c] hover:text-[#e07d00]">
              Sign in here!
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}

export default RegisterScreen
