import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import { useLoginMutation } from '../../slices/usersApiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setCredentials } from '../../slices/authSlice'
import { toast } from 'react-toastify'
import Loader from '../../components/ToucanLoader'
import GoogleButton from '../../components/GoogleButton'

const LoginScreen = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [login, {isLoading}] = useLoginMutation()
  const navigate = useNavigate()
  const { search } = useLocation()
  const sp = new URLSearchParams(search)
  const redirect = sp.get('redirect') || '/'
  const dispatch = useDispatch()

  const {userInfo} = useSelector((state) => state.auth)

  useEffect(() => {
    if (userInfo) {
      navigate(redirect)
    }
  }, [navigate, userInfo, redirect])

  const submitHandler = async (e) => {
    e.preventDefault()

    // Frontend validation
    if (!email.trim()) {
      toast.error('Email is required')
      return
    }

    // Use same email validation as backend
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email')
      return
    }

    if (!password) {
      toast.error('Password is required')
      return
    }

    try {
      const res = await login({email, password}).unwrap()
      dispatch(setCredentials({
        ...res.data.user,
        token: res.token
      }))
      navigate(redirect)
    } catch (err) {
      toast.error('Invalid email or password. Please try again.')
    }
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-cover bg-center"
        style={{ backgroundImage: 'url("/images/beans.png")' }}>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-40 w-auto"
            src="/images/terrys-logo.png"
            alt="Terry's Coffee"
          />
          <h2 className="mt-6 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white/90 backdrop-blur-sm px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form onSubmit={submitHandler} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fe940c] sm:text-sm/6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="current-password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fe940c] sm:text-sm/6"
                  />
                </div>
              </div>

              <div className="flex justify-end text-sm/6">
                <Link to="/forgotPassword" className="font-semibold text-[#fe940c] hover:text-[#e07d00]">
                  Forgot password?
                </Link>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="flex w-full justify-center rounded-md bg-[#fe940c] px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-[#e07d00] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#fe940c]"
                >
                  Sign in
                </button>
                {isLoading && <Loader />}
              </div>
            </form>

            <div>
              <div className="relative mt-10">
                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm/6 font-medium">
                  <span className="bg-white px-6 text-gray-900">Or continue with</span>
                </div>
              </div>

              <div className="mt-6">
                <GoogleButton />
              </div>
            </div>
          </div>

          <p className="mt-10 text-center text-sm/6 text-black font-medium">
            Don't have an account?{' '}
            <Link to="/register" className="font-semibold text-[#fe940c] hover:text-[#e07d00]">
              Sign up here!
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}
export default LoginScreen