import React, { useState } from 'react'
import { useGetCommercialProductsQuery } from '../../slices/commercialProductsApiSlice'
import ToucanLoader from '../../components/ToucanLoader'
import Message from '../../components/Message'
import CommercialProduct from '../../components/CommercialProduct'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { ArrowLongLeftIcon, ArrowLongRightIcon, ShoppingCartIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import SearchBox from '../../components/SearchBox'
import CommercialAccountPendingMessage from '../../components/CommercialAccountPendingMessage'
import { useSelector } from 'react-redux'

const CommercialShopScreen = () => {
  const { pageNumber } = useParams()
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword') || '';
  const urlTag = searchParams.get('tag') || '';
  const [selectedTag, setSelectedTag] = useState(urlTag)
  const navigate = useNavigate()
  
  const { userInfo } = useSelector((state) => state.auth);
  const { cartItems } = useSelector((state) => state.cart);

  // Calculate total quantity for cart icon
  const totalQuantity = cartItems.reduce((acc, item) => acc + item.qty, 0)
  
  // Fetch products without skip
  const { data: { data: { commercialProducts } = {}, pages } = {}, isLoading: productLoading, error: productError } = useGetCommercialProductsQuery({ 
    pageNumber: Number(pageNumber || 1), 
    keyword,
    tag: selectedTag
  });

  const tags = [
    'Coffee',
    'Syrup',
    'Chocolate',
    'Chai',
    'Tea-bags',
    'Cups',
    'Lids',
    'Sugar',
    'Cleaning',
    'Loose-Leaf-Tea',
    'Stevia',
    'Other'
  ]

  const handleTagSelect = (tag) => {
    setSelectedTag(tag === selectedTag ? '' : tag)
  }

  // If user is not logged in, show login prompt
  if (!userInfo) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900">Commercial Ordering</h2>
          <p className="mt-4 text-lg text-gray-600">
            Please log in to view our commercial products. If you don't have a commercial account yet, you can request one by visiting our "Request a Quote" page.
          </p>
        </div>

        <div className="mt-8 flex flex-col items-center">
          <Link
            to="/login"
            className="bg-amber-600 hover:bg-amber-700 text-white px-6 py-3 rounded-lg font-medium transition-colors duration-200"
          >
            Log In
          </Link>
          <p className="mt-4 text-gray-600">
            Need a commercial account?{' '}
            <Link to="/commercial/quote-request" className="text-amber-600 hover:text-amber-700 font-medium">
              Request a Quote
            </Link>
          </p>
        </div>
      </div>
    );
  }

  // If user has a pending commercial request, show pending message
  if (userInfo.commercialAccountRequestPending) {
    return <CommercialAccountPendingMessage />;
  }

  // If user is not a commercial account and not an admin, redirect to quote request
  if (!userInfo.isCommercialAccount && userInfo.role !== 'admin') {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900">Commercial Access Required</h2>
          <p className="mt-4 text-lg text-gray-600">
            You need an approved commercial account to view our commercial products.
          </p>
        </div>

        <div className="mt-8 flex flex-col items-center">
          <Link
            to="/commercial/quote-request"
            className="bg-amber-600 hover:bg-amber-700 text-white px-6 py-3 rounded-lg font-medium transition-colors duration-200"
          >
            Request Commercial Access
          </Link>
        </div>
      </div>
    );
  }

  // Show loading state while fetching products
  if (productLoading) {
    return <ToucanLoader />;
  }

  // Show error if product fetch failed
  if (productError) {
    return <Message variant='danger'>{'Error loading products'}</Message>;
  }

  // Show commercial products
  return (
    <>
      <div className="fixed right-0 lg:left-72 left-0 z-40 flex flex-col h-auto border-b border-gray-200 bg-white shadow-sm ring-1 ring-gray-300 lg:top-0 top-[3.5rem]">
        {/* Search Box Section */}
        <div className="flex flex-1 px-4">
          <SearchBox isCommercial={true} />
        </div>
        
        {/* Tag Filter Section */}
        <div className="px-4 py-3 overflow-x-auto">
          <div className="flex gap-2 flex-nowrap">
            {tags.map((tag) => (
              <button
                key={tag}
                onClick={() => handleTagSelect(tag)}
                className={`px-3 py-1 text-sm rounded-full whitespace-nowrap ${
                  selectedTag === tag
                    ? 'bg-amber-500 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {tag.replace(/-/g, ' ')}
              </button>
            ))}
          </div>
        </div>

        {/* Cart Button */}
        <div className="px-4 py-2 bg-gray-50 border-t border-gray-200">
          <button
            onClick={() => navigate('/cart')}
            className="w-full flex items-center justify-center gap-2 bg-amber-600 hover:bg-amber-700 text-white px-4 py-2 rounded-lg font-medium transition-colors duration-200"
          >
            <ShoppingCartIcon className="h-5 w-5" />
            Go to Cart
            {totalQuantity > 0 && (
              <span className="bg-white text-amber-600 px-2 py-0.5 rounded-full text-sm font-semibold">
                {totalQuantity}
              </span>
            )}
          </button>
        </div>
      </div>

      <div className="pt-[12rem] lg:pt-36">
        <main>
          {productLoading ? (
            <ToucanLoader />
          ) : productError ? (
            <Message variant="error">
              {'Error loading products'}
            </Message>
          ) : (
            <div className="bg-gray-100">
              <div className="mx-auto max-w-2xl px-2 sm:px-4 lg:max-w-7xl lg:px-8">
                <div className="grid grid-cols-2 gap-x-2 gap-y-4 sm:gap-x-4 sm:gap-y-8 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-6">
                  {commercialProducts && commercialProducts.map((product) => (
                    <CommercialProduct key={product._id} product={product} />
                  ))}
                </div>

                {/* Pagination Controls */}
                {pages > 1 && (
                  <nav className="flex items-center justify-between px-4 sm:px-0 mt-12 mb-12">
                    <div className="flex w-0 flex-1">
                      <Link
                        to={`/shop/commercial/page/${Number(pageNumber) > 1 ? Number(pageNumber) - 1 : 1}${keyword ? `?keyword=${keyword}` : ''}`}
                        className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-amber-300 hover:text-amber-700"
                      >
                        <ArrowLongLeftIcon className="mr-3 size-5 text-gray-400" aria-hidden="true" />
                        Previous
                      </Link>
                    </div>
                    
                    <div className="hidden md:flex">
                      {[...Array(pages).keys()].map((x) => (
                        <Link
                          key={x + 1}
                          to={`/shop/commercial/page/${x + 1}${keyword ? `?keyword=${keyword}` : ''}`}
                          aria-current={Number(pageNumber) === x + 1 ? 'page' : undefined}
                          className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                            Number(pageNumber) === x + 1
                              ? 'border-amber-500 text-amber-600'
                              : 'border-transparent text-gray-500 hover:border-amber-300 hover:text-amber-700'
                          }`}
                        >
                          {x + 1}
                        </Link>
                      ))}
                    </div>
                    
                    <div className="flex w-0 flex-1 justify-end">
                      <Link
                        to={`/shop/commercial/page/${Number(pageNumber) < pages ? Number(pageNumber) + 1 : pages}${keyword ? `?keyword=${keyword}` : ''}`}
                        className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-amber-300 hover:text-amber-700"
                      >
                        Next
                        <ArrowLongRightIcon className="ml-3 size-5 text-gray-400" aria-hidden="true" />
                      </Link>
                    </div>
                  </nav>
                )}
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
};

export default CommercialShopScreen;