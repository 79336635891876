import { useState } from 'react'
import { 
  Cog6ToothIcon, 
  UsersIcon, 
  ArchiveBoxIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  MapPinIcon,
  ShoppingBagIcon,
  BuildingOfficeIcon,
  CurrencyDollarIcon
} from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

const adminNavigation = [
  { name: 'User Management', href: '/admin/users/page/1', icon: UsersIcon },
  { name: 'Retail Product Management', href: '/admin/products/page/1', icon: ArchiveBoxIcon },
  { name: 'Commercial Product Management', href: '/admin/commercialProducts/page/1', icon: ArchiveBoxIcon },
  { name: 'Commercial Pricing Management', href: '/admin/commercial-pricing/page/1', icon: CurrencyDollarIcon },
  { name: 'Retail Orders', href: '/admin/orders/page/1', icon: ShoppingBagIcon },
  { name: 'Commercial Orders', href: '/admin/commercialOrders/page/1', icon: BuildingOfficeIcon },
  { name: 'Location Management', href: '/admin/locations/page/1', icon: MapPinIcon },
  { name: 'QuickBooks Integration', href: '/admin/quickbooks', icon: CurrencyDollarIcon },
]

const AdminMenu = ({ onLinkClick }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleLinkClick = () => {
    // Don't close the admin menu, just call the parent's callback
    if (onLinkClick && typeof onLinkClick === 'function') {
      onLinkClick();
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="group flex w-full gap-x-3 rounded-md p-2 text-base font-semibold text-gray-900 hover:bg-[#ffa53d]"
      >
        <Cog6ToothIcon className="size-6 shrink-0" aria-hidden="true" />
        <span className="flex-1 text-left">Admin Panel</span>
        {isOpen ? (
          <ChevronUpIcon className="ml-auto size-5 text-gray-800 stroke-2" aria-hidden="true" />
        ) : (
          <ChevronDownIcon className="ml-auto size-5 text-gray-800 stroke-2" aria-hidden="true" />
        )}
      </button>

      <div
        className={`w-full transition-all duration-300 ease-out ${
          isOpen 
            ? 'transform opacity-100 translate-y-0' 
            : 'transform opacity-0 -translate-y-2 hidden'
        }`}
      >
        {adminNavigation.map((item) => (
          <Link
            key={item.name}
            to={item.href}
            onClick={handleLinkClick}
            className="group flex items-center gap-x-3 rounded-md p-2 pl-11 text-base font-semibold text-gray-900 hover:bg-[#ffa53d]"
          >
            <item.icon className="size-6 shrink-0" aria-hidden="true" />
            {item.name}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default AdminMenu 