// frontend/src/slices/commercialAddressesApiSlice.js
import { apiSlice } from './apiSlice';
import { COMMERCIAL_ADDRESSES_URL } from '../constants';

export const commercialAddressesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAddress: builder.query({
      query: () => ({
        url: COMMERCIAL_ADDRESSES_URL,
      }),
      providesTags: ['CommercialAddress'],
    }),
    upsertAddress: builder.mutation({
      query: (data) => ({
        url: COMMERCIAL_ADDRESSES_URL,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['CommercialAddress'],
    }),
  }),
});

export const {
  useGetAddressQuery,
  useUpsertAddressMutation,
} = commercialAddressesApiSlice;