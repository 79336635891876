import { useState, Fragment } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, HomeIcon, ShoppingBagIcon, ShoppingCartIcon, UserIcon, ChevronUpIcon, ChevronDownIcon, EnvelopeIcon, MapPinIcon, BuildingStorefrontIcon, DocumentTextIcon } from '@heroicons/react/24/outline'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useLogoutMutation } from '../slices/usersApiSlice'
import { logout } from '../slices/authSlice'
import AdminMenu from './AdminMenu'
import { BASE_URL } from '../constants'

export default function SideBar() {
  const navigation = [
    { name: 'Home', href: '/', icon: HomeIcon, current: true },
    { name: 'Retail Products', href: '/shop/page/1', icon: ShoppingBagIcon, current: false },
    { name: 'Commercial Ordering', href: '/shop/commercial/page/1', icon: BuildingStorefrontIcon, current: false },
    { name: 'Request a Quote', href: '/commercial/quote-request', icon: DocumentTextIcon, current: false },
    { name: 'Shopping Cart', href: '/cart', icon: ShoppingCartIcon, current: false },
    { name: 'Contact Us', href: '/contact', icon: EnvelopeIcon, current: false },
    { name: 'Find Us', href: '/findus', icon: MapPinIcon, current: false },
  ]


  const [sidebarOpen, setSidebarOpen] = useState(false)
  const cart = useSelector(state => state.cart)
  const cartItems = cart.cartItems || []
  const { userInfo } = useSelector(state => state.auth)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [logoutApiCall] = useLogoutMutation();

  const totalQuantity = Array.isArray(cartItems) 
    ? cartItems.reduce((acc, item) => acc + item.qty, 0)
    : 0

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap()
      dispatch(logout())
      navigate('/')
    } catch (err) {
      // Error handling
    }
  }

  const UserInfoDisplay = () => (

    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button className="w-full group flex items-center gap-x-3 rounded-md p-2 text-base font-semibold text-gray-900 hover:bg-[#ffa53d]">
            <div className="h-10 w-10 rounded-full bg-[#e07d00] flex items-center justify-center flex-shrink-0 overflow-hidden">
              {userInfo?.photo ? (
                <img 
                  src={`${BASE_URL}${userInfo.photo}`}
                  alt={userInfo.name} 
                  className="h-full w-full object-cover"
                />
              ) : (
                <span className="text-lg font-bold text-white">
                  {userInfo?.name ? userInfo.name.charAt(0).toUpperCase() : '?'}
                </span>
              )}
            </div>
            <div className="flex-1 min-w-0 text-left">
              <p className="text-sm font-bold text-gray-900 truncate">
                {userInfo?.name}
              </p>
            </div>
            {open ? (
              <ChevronDownIcon className="ml-auto size-5 text-gray-800 stroke-2" aria-hidden="true" />
            ) : (
              <ChevronUpIcon className="ml-auto size-5 text-gray-800 stroke-2" aria-hidden="true" />
            )}
          </Menu.Button>
          <Transition
            enter="transition-opacity duration-150 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-100 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Menu.Items className="absolute bottom-full left-0 mb-2 w-48 rounded-md bg-[#fff3e6] py-1 shadow-lg ring-1 ring-[#ffa53d] focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/profile"
                    onClick={() => setSidebarOpen(false)}
                    className={`${
                      active ? 'bg-[#ffe0b3] text-[#cc7100]' : 'text-[#cc7100]'
                    } block px-4 py-2 text-sm font-medium hover:bg-[#ffe0b3]`}
                  >
                    Profile & Past Orders
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      logoutHandler();
                      setSidebarOpen(false);
                    }}
                    className={`${
                      active ? 'bg-[#ffe0b3] text-[#cc7100]' : 'text-[#cc7100]'
                    } block w-full text-left px-4 py-2 text-sm font-medium hover:bg-[#ffe0b3]`}
                  >
                    Sign Out
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )

  const DesktopUserInfoDisplay = UserInfoDisplay

  // Move navigation inside the component to access userInfo

  const handleNavigation = (e, href) => {
    // Close the sidebar on navigation
    setSidebarOpen(false);
  };

  return (
    <>
      <Transition appear show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1 bg-[#fe940c]">
                <div className="absolute right-0 top-0 flex w-16 justify-center pt-5">
                  <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="size-6 text-white" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center mt-6 mb-2 -mx-2">
                    <img
                      src="/images/terrys-full-logo.png"
                      alt="Terry's Premium Coffee"
                      className="h-auto w-56 object-contain"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                onClick={(e) => {
                                  handleNavigation(e, item.href);
                                  setSidebarOpen(false);
                                }}
                                className="group flex gap-x-3 rounded-md p-2 text-base/7 font-semibold text-gray-900 hover:bg-[#ffa53d]"
                              >
                                <div className="relative">
                                  <item.icon className="size-6 shrink-0" aria-hidden="true" />
                                  {item.name === 'Shopping Cart' && totalQuantity > 0 && (
                                    <span className="absolute -top-2 -right-2 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs font-medium text-white">
                                      {totalQuantity}
                                    </span>
                                  )}
                                </div>
                                <span>{item.name}</span>
                              </Link>
                            </li>
                          ))}
                          {userInfo?.role === 'admin' && (
                            <li>
                              <AdminMenu onLinkClick={() => setSidebarOpen(false)} />
                            </li>
                          )}
                        </ul>
                      </li>
                      <li className="mt-auto mb-4">
                        {userInfo ? <UserInfoDisplay /> : (
                          <Link
                            to="/login"
                            className="group flex gap-x-3 rounded-md p-2 text-base/7 font-semibold text-gray-900 hover:bg-[#ffa53d]"
                            onClick={() => setSidebarOpen(false)}
                          >
                            <UserIcon className="size-6 shrink-0" aria-hidden="true" />
                            <span>Sign In</span>
                          </Link>
                        )}
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#fe940c] px-6">
          <div className="flex h-16 shrink-0 items-center mt-6 mb-2 -mx-2">
            <img
              src="/images/terrys-full-logo.png"
              alt="Terry's Premium Coffee"
              className="h-auto w-56 object-contain"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        onClick={(e) => handleNavigation(e, item.href)}
                        className="group flex gap-x-3 rounded-md p-2 text-base/7 font-semibold text-gray-900 hover:bg-[#ffa53d]"
                      >
                        <div className="relative">
                          <item.icon className="size-6 shrink-0" aria-hidden="true" />
                          {item.name === 'Shopping Cart' && totalQuantity > 0 && (
                            <span className="absolute -top-2 -right-2 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs font-medium text-white">
                              {totalQuantity}
                            </span>
                          )}
                        </div>
                        <span>{item.name}</span>
                      </Link>
                    </li>
                  ))}
                  {userInfo?.role === 'admin' && (
                    <li>
                      <AdminMenu />
                    </li>
                  )}
                </ul>
              </li>
              <li className="mt-auto mb-4">
                {userInfo ? <DesktopUserInfoDisplay /> : (
                  <Link
                    to="/login"
                    className="group flex gap-x-3 rounded-md p-2 text-base/7 font-semibold text-gray-900 hover:bg-[#ffa53d]"
                  >
                    <UserIcon className="size-6 shrink-0" aria-hidden="true" />
                    <span>Sign In</span>
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* Mobile top bar */}
      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-[#fe940c] px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="size-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm/6 font-semibold text-gray-900"></div>
      </div>
    </>
  )
}