import React from 'react'

const ToucanLoader = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-50 z-50">
      <div className="w-[250px] h-[250px] sm:w-[230px] sm:h-[230px] animate-pulse">
        <img 
          src="/images/bird.png" 
          alt="Loading..."
          className="w-full h-full object-contain"
        />
      </div>
    </div>
  );
};

export default ToucanLoader;