import React, { forwardRef } from 'react';
import { Disclosure } from '@headlessui/react'
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid'

const faqs = [
  {
    question: "What types of coffee beans do you offer and where are they sourced from?",
    answer: "Terry's Premium Coffee beans are grown in the Central American plantations of Nicaragua, Honduras, El Salvador, Guatemala, Costa Rica, Mexico and Colombia; are of the highest quality, and both ethically and sustainably sourced.  Terry's roasted coffee beans are an excellent choice for coffee lovers who appreciate a high-quality cup of coffee with a rich and complex flavor profile. Our bags of 250g, 500g and 1 kg can be bought as unground roasted beans, a French Press grind, also Cafetera and Expresso. We have also sourced some amazing West African Cocoa Chocolate powder and a Chai Latte recipe from heaven (near-about to the Punjab region of India). See our Chai latte and Chocolate",
  },
  {
    question: "I am a cafe owner and like your products, how can I place an order?",
    answer: "Please visit the 'Request a Quote' page on our website and fill out the form to inquire about our commercial products.  We will get back to you as soon as possible."
  },
  {
    question: "Is coffee the only product you sell?",
    answer: "No!  While coffee is our primary product, we also sell coffee accessories, chocolate, tea, coffee machines, coffee grinders, and more."
  },
  {
    question: "I have just opened up or am thinking of opening up a cafe, how can you help me?",
    answer: "In addition to catering for all your coffee, chocolate and tea needs, we can provide, setup and install commercial grade coffee machines and coffee grinders for you.  We will also provide your team with the training and support needed to leverage and maintain this equipment."  
  },
  {
    question: "Do you supply coffee machines and other cafe equipment?",
    answer: "Yes, we supply a range of coffee machines and other cafe equipment."
  },
  {
    question: "What if something goes wrong with our coffee machine?",
    answer: "Rest assured, we offer ongoing service and maintenance for all coffee machines and equipment.  We will also provide you with the training and support needed to leverage and maintain this equipment."
  },
  {
    question: "Do you offer barista training?",
    answer: "Yes, we provide comprehensive barista training for commercial clients. Our training covers everything from coffee preparation to equipment maintenance.",
  },
]

const FAQSection = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
      <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          Frequently Asked Questions
        </h2>
        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
          {faqs.map((faq) => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                      <span className="text-base font-semibold leading-7">{faq.question}</span>
                      <span className="ml-6 flex h-7 items-center">
                        {open ? (
                          <MinusIcon className="h-6 w-6" aria-hidden="true" />
                        ) : (
                          <PlusIcon className="h-6 w-6" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  )
})

FAQSection.displayName = 'FAQSection';

export default FAQSection 