const Message = ({ variant = 'info', children, className = '', onClick }) => {
  const getVariantClasses = (variant) => {
    switch (variant) {
      case 'danger':
        return 'bg-red-100 hover:bg-red-200 text-red-700 border-red-400';
      case 'success':
        return 'bg-green-100 hover:bg-green-200 text-green-700 border-green-400';
      case 'warning':
        return 'bg-yellow-100 hover:bg-yellow-200 text-yellow-700 border-yellow-400';
      case 'info':
      default:
        return 'bg-blue-100 hover:bg-blue-200 text-blue-700 border-blue-400';
    }
  };

  return (
    <div 
      onClick={onClick}
      className={`px-4 py-3 rounded border ${getVariantClasses(variant)} ${className} ${onClick ? 'cursor-pointer transition-colors duration-200' : ''}`}
    >
      {children}
    </div>
  );
};

export default Message;