import React, { useState, useRef } from 'react'
import ToucanLoader from '../../components/ToucanLoader'
import { useSubmitContactFormMutation } from '../../slices/contactApiSlice'
import { toast } from 'react-toastify'
import { UserIcon, EnvelopeIcon } from '@heroicons/react/24/outline'
import ReCAPTCHA from 'react-google-recaptcha'


const ContactScreen = () => {
  const [submitContactForm, { isLoading }] = useSubmitContactFormMutation()

  const [formData, setFormData] = useState({
    name: '',
    businessName: '',
    email: '',
    phoneNumber: '',
    message: ''
  })

  const [recaptchaValue, setRecaptchaValue] = useState(null)
  const recaptchaRef = useRef(null)

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    // Validate reCAPTCHA
    if (!recaptchaValue) {
      toast.error('Please complete the reCAPTCHA verification')
      return
    }
    
    try {
      // Include reCAPTCHA token in your form data
      await submitContactForm({
        ...formData,
        recaptchaToken: recaptchaValue
      }).unwrap()
      toast.success('Message sent successfully!')
      // Clear form
      setFormData({
        name: '',
        businessName: '',
        email: '',
        phoneNumber: '',
        message: ''
      })
      
      // Reset reCAPTCHA after submission
      if (recaptchaRef.current) {
        recaptchaRef.current.reset()
      }
      setRecaptchaValue(null)
    } catch (err) {
      toast.error("Failed to send message")
    }
  } 

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-2">Contact Us</h1>
      <h2 className="text-lg text-gray-600 mb-4">You can contact us directly via the form below or by email.</h2>
      
      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
        <div className="lg:col-span-6">
          <div className="flex flex-col gap-8">
            {/* Business Card */}
            <div className="max-w-xs bg-white rounded-lg shadow-sm p-4 border border-gray-200">
              <div className="space-y-2">
                <div className="flex items-center">
                  <UserIcon className="w-4 h-4 text-gray-500 mr-2" />
                  <p className="text-black text-sm font-medium">Terry Papasavvas</p>
                </div>
                
                <div className="flex items-center">
                  <EnvelopeIcon className="w-4 h-4 text-gray-500 mr-2" />
                  <a 
                    href="mailto:terryscoffeeroasting@gmail.com" 
                    className="text-gray-600 text-sm hover:text-amber-600 hover:underline"
                  >
                    terryscoffeeroasting@gmail.com
                  </a>
                </div>
              </div>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="max-w-md space-y-6">
              <div className="relative">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                />
              </div>

              <div className="relative">
                <label htmlFor="businessName" className="block text-sm font-medium text-gray-700">
                  Business Name
                </label>
                <input
                  type="text"
                  id="businessName"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                />
              </div>

              <div className="relative">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                />
              </div>

              <div className="relative">
                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                />
              </div>

              <div className="relative">
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                  Message <span className="text-red-500">*</span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows={4}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                />
              </div>

              {/* Add reCAPTCHA before the submit button */}
              <div className="flex justify-center my-4">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={handleRecaptchaChange}
                />
              </div>

              <button
                type="submit"
                disabled={isLoading || !recaptchaValue}
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:bg-gray-400"
              >
                {isLoading ? <ToucanLoader small /> : 'Send Message'}
              </button>
            </form>
          </div>
        </div>

        {/* Empty column to maintain the original layout */}
        <div className="lg:col-span-6 mt-10 lg:mt-0">
          {/* This column is intentionally left empty */}
        </div>
      </div>
    </div>
  )
}

export default ContactScreen